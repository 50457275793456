import { useState, useEffect } from 'react'
import { Square3Stack3DIcon } from '@heroicons/react/24/outline'
import BaseService from '../../helpers/baseServices'
import { global_variables } from '../../helpers/globalVariables'

const AllTimeOrders = () => {

    const [isFetching, setIsFetching] = useState(false)
    const [totalCount, setTotalCount] = useState(0)

    //onload
    useEffect(() => {


        const getTotalCount = async () => {
            setIsFetching(true)
            try {
                const response = await BaseService.get_api(`${global_variables.get_all_trips}?limit=1`)
                // console.log(response)
                setTotalCount(response?.data?.total)
                setIsFetching(false)
            } catch (error) {
                console.log(error)
            }
        }

        getTotalCount()
    }, [])


    return (
        <>
            <div className="mb-3 lg:mb-0 p-[20px] rounded-[10px] bg-gradient-to-r from-orange-300 to-orange-500 text-white shadow-lg flex flex-col justify-center items-center">
                <Square3Stack3DIcon className="w-8 h-8" />
                <div className="mt-3 text-center">
                    <p>All Time Orders</p>
                    <p className="mt-2 font-semibold text-2xl">{isFetching ? "--" : totalCount}</p>
                </div>
            </div>
        </>
    )
}

export default AllTimeOrders
