import { Fragment, useState } from "react";
import {
  Drawer,
  IconButton,
} from "@material-tailwind/react";
import { XMarkIcon } from "@heroicons/react/24/outline"; import ShippingInfoTab from "../orderMgt/tabs/shippingInfo";
import { TripModel } from "../orderMgt/trips.model";
;
interface drawerProps {
  isOpened: boolean;
  closeDrawer: () => void;
  deliveryData: TripModel
}

const ScheduleDetailsDrawer = ({ isOpened, closeDrawer, deliveryData }: drawerProps) => {
  const [activeTab, setActiveTab] = useState(0);

  const tabNames = ["Shipping Info"];
  const tabComponents = [
    <ShippingInfoTab shippingData={deliveryData} />,
  ];

  return (
    <Fragment>
      <Drawer
        size={900}
        placement="right"
        open={isOpened}
        className="py-4"
      >
        <div className="mb-4 flex items-center justify-between px-7">
          <div className="flex items-center gap-3">
            <p className="font-semibold text-xl">Delivery to {deliveryData?.destination?.place}</p>
            <p className="font-semibold text-midGreen">{`(${deliveryData?.currentStatus?.name})`}</p>
          </div>
          <IconButton variant="text" color="blue-gray" onClick={closeDrawer}>
            <XMarkIcon strokeWidth={2} className="h-5 w-5" />
          </IconButton>
        </div>
        {/* tabs */}
        <div className="mt-2 flex items-center gap-5 px-7">
          {tabNames.map((tab, i) => (
            <div
              key={i}
              className={`${activeTab === i ? "border-b-[2px] border-b-midGreen" : ""
                } cursor-pointer pb-1`}
              onClick={() => setActiveTab(i)}
            >
              <p>{tab}</p>
            </div>
          ))}
        </div>
        <hr />
        <div className="mt-4 overflow-y-auto h-full pb-[100px] px-7">
          {tabComponents[activeTab]}
        </div>
      </Drawer>
    </Fragment>
  );
};

export default ScheduleDetailsDrawer;
