import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import BaseService from '../../helpers/baseServices';
import { global_variables } from '../../helpers/globalVariables';
import { EyeIcon, TrashIcon } from '@heroicons/react/24/outline';
import { TripModel } from '../orderMgt/trips.model';
import ShowAlert from '../../components/alerts/all_alerts';
import ShowToast from '../../components/alerts/all_toasts';
import ScheduleDetailsDrawer from '../schedules/scheduleDetailsDrawer';
import { useNavigate } from 'react-router-dom';

const SchedulesLimited = () => {

    const navigate = useNavigate()
    const today = new Date().toISOString()

    const [isFetching, setIsFetching] = useState(false)
    const [allScehdules, setAllScehdules] = useState([])
    const [total, setTotal] = useState(0)
    const [reFetch, setReFetch] = useState(false)
    const [selectedOrder, setSelectedOrder] = useState<TripModel | any>(null)


    //handleReFetch
    const handleReFetch = () => {
        setReFetch(!reFetch)
    }

    //open details
    const [openDrawer, setOpenDrawer] = useState(false);
    const handleOpen = () => {
        setOpenDrawer(!openDrawer);
    };

    //handleViewDetails
    const handleViewDetails = (data: any) => {
        setSelectedOrder(data)
        handleOpen();
    }

    //onload
    useEffect(() => {
        getAllSchedules()
    }, [reFetch])

    //getAllSchedules
    const getAllSchedules = async () => {
        setIsFetching(true)
        try {
            const response = await BaseService.get_api(`${global_variables.get_all_trips}?filter=[{"$and":[{ "scheduleData": { "$ne": null } }, {"completed": false}]}]&limit=5`)
            console.log(response?.data)
            setAllScehdules(response?.data?.payload)
            setTotal(response?.data?.total)
            setIsFetching(false)
        } catch (error) {
            console.log(error)
        }
    }

    //handleDeleteSchedule
    const handleDeleteSchedule = (data: TripModel) => {
        console.log(data?._id)
        ShowAlert.question_alert('Confirm', `Do you want to delete schedule from ${data?.pickup?.place} to ${data?.destination?.place}?`, 'Delete').then((result) => {
            if (result.isConfirmed) {
                BaseService.delete_with_id(`${global_variables.create_schedule}/`, data?._id)
                    .then((response) => {
                        console.log(response)
                        ShowToast.success_toast(response?.data?.message)
                        handleReFetch()
                    })
                    .catch((error) => {
                        console.log(error)
                        ShowToast.error_toast(error?.response?.data?.error)
                    })
            }
        })
    }


    // Define the columns for the table
    const columns: any = [
        {
            name: "Pickup",
            cell: (row: any) => row?.pickup?.place,
        },
        {
            name: "Destination",
            cell: (row: any) => row?.destination?.place,
        },
        {
            name: "Item Description",
            cell: (row: any) => row?.itemDescription || "No description",
            width: "20%"
        },
        {
            name: "Qty",
            selector: (row: any) => row?.quantity,
            width: "10%",
        },
        {
            name: "Weight",
            selector: (row: any) => `${row?.weight} ${row?.weightUnit}`,
            width: "10%",
        },
        {
            name: "Date Created",
            cell: (row: any) => moment(row?.createdAt).format("Do MMM YYYY - hh:mm A"),
        },
        {
            name: "Expiry Status",
            selector: (row: any) => new Date(row?.scheduleData?.expiryDate).toISOString() >= today ?
                <div className='w-fit px-3 py-1 rounded-[5px] bg-midGreen bg-opacity-[10%] text-midGreen'>
                    Active
                </div>
                :
                <div className='w-fit px-3 py-1 rounded-[5px] bg-red-500 bg-opacity-[10%] text-red-500'>
                    Expired
                </div>
            ,
        },
        {
            name: "Action",
            selector: (row: any) => <div className='flex gap-4'>
                <EyeIcon className='h-5 w-5 cursor-pointer' title='View Order Details' onClick={() => handleViewDetails(row)} />
                <TrashIcon className='h-5 w-5 cursor-pointer text-red-500' title='Delete Order' onClick={() => handleDeleteSchedule(row)} />
            </div>
        }
    ];

    return (
        <>
            <div className='mt-5 bg-white shadow-lg rounded-[20px] p-5'>
                <div className="flex justify-between items-center">
                    <p className="font-semibold text-md">Schedules</p>
                    <button className="px-4 py-2 rounded-[5px] w-fit bg-gradient-to-r from-lightGreen to-midGreen text-white" onClick={() => navigate('/schedules')}>
                        View All
                    </button>
                </div>
                <DataTable
                    columns={columns}
                    data={allScehdules}
                    paginationTotalRows={total}
                    progressPending={isFetching}
                    responsive
                />
            </div>


            {openDrawer ? (
                <ScheduleDetailsDrawer
                    isOpened={openDrawer}
                    closeDrawer={() => setOpenDrawer(false)}
                    deliveryData={selectedOrder}
                />
            ) : (
                <></>
            )}
        </>
    )
}

export default SchedulesLimited
