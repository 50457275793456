import { useState, useEffect } from 'react'
import { MapPinIcon, Square3Stack3DIcon } from '@heroicons/react/24/outline'
import BaseService from '../../helpers/baseServices'
import { global_variables } from '../../helpers/globalVariables'
import { getTodaysDateRange } from '../../utils/functions'

const LiveOrders = () => {

    const [isFetching, setIsFetching] = useState(false)
    const [totalCount, setTotalCount] = useState(0)

    //onload
    useEffect(() => {

        const todayDate = getTodaysDateRange()

        const getTotalCount = async () => {
            setIsFetching(true)
            try {
                const response = await BaseService.get_api(`${global_variables.get_all_trips}?filter=[{ "$and": [{ "currentStatus.name": "Accept" }, {"completed": false}] }]&limit=1`)
                // console.log(response)
                setTotalCount(response?.data?.total)
                setIsFetching(false)
            } catch (error) {
                console.log(error)
            }
        }

        getTotalCount()
    }, [])


    return (
        <>
            <div className="mb-3 lg:mb-0 p-[20px] rounded-[10px] bg-gradient-to-r from-lightGreen to-midGreen text-white shadow-lg flex flex-col justify-center items-center">
                <MapPinIcon className="w-8 h-8" />
                <div className="mt-3 text-center">
                    <p>On Going</p>
                    <p className="mt-2 font-semibold text-2xl">{isFetching ? "--" : totalCount}</p>
                </div>
            </div>
        </>
    )
}

export default LiveOrders
