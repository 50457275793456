import { CloudArrowUpIcon, PhotoIcon, PowerIcon, TrashIcon } from '@heroicons/react/24/outline'
import { Input, Spinner, Typography } from '@material-tailwind/react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import GooglePlacesAutocomplete, {
    geocodeByPlaceId,
    getLatLng,
} from "react-google-places-autocomplete";
import { GOOGLE_KEY } from '../../../helpers/constants';
import BaseService from '../../../helpers/baseServices';
import { global_variables } from '../../../helpers/globalVariables';
import ShowToast from '../../../components/alerts/all_toasts';
import { StorageBox } from '../../../helpers/stroage';
import PhoneInput, { Value } from "react-phone-number-input";


const CompanyDetails = () => {
    const navigate = useNavigate()
    const selectedCountry = StorageBox.getSavedCountry()

    const [isBusy, setIsBusy] = useState(false)
    const [selectedImage, setSelectedImage] = useState<any>(null);
    const [theFile, setTheFile] = useState<any>(null)

    const [placeName, setPlaceName] = useState("");
    const [long, setLong] = useState<any>(0);
    const [lat, setLat] = useState<any>(0);
    const [compName, setCompName] = useState("")
    const [compID, setCompID] = useState("")
    const [compEmail, setCompEmail] = useState("")
    const [compPhone, setCompPhone] = useState<Value>("")

    //handle image slected
    const handleImageSelected = (e: any) => {
        const file = e.target.files[0];
        setTheFile(file)
        const imageUrl = URL.createObjectURL(file);
        setSelectedImage(imageUrl);
    };

    // handle place slected
    const handlePlaceSelected = (selected: any) => {
        if (selected) {
            console.log(selected.value);
            geocodeByPlaceId(selected.value.place_id)
                .then((results) => {
                    console.log(results);
                    getLatLng(results[0]).then(({ lat, lng }) => {
                        console.log(lat, lng);
                        setLat(lat);
                        setLong(lng);
                    });
                })
                .catch((error) => console.error(error));
            setPlaceName(selected.value.description);
        } else {
            console.log("No option selected");
            setPlaceName("");
        }
    };

    //handleUploadDetails
    const handleUploadDetails = async () => {
        if (selectedImage === null) {
            ShowToast.warning_toast("Please upload your company logo!")
        }
        else if (compName === "" || compEmail === "" || compID === "" || compPhone === "" || placeName === "") {
            ShowToast.warning_toast("Please fill out all fields!")
        }
        else {
            try {
                setIsBusy(true)
                const response: any = await BaseService.file_upload(`${global_variables.file_upload}?imagename=${compName}&storage=testfolder`, theFile)
                console.log(response)
                if (response?.status === 200) {
                    let data = JSON.stringify({
                        "name": compName,
                        "registeration": compID,
                        "businessLogo": response?.data?.url,
                        "email": compEmail,
                        "phone": compPhone,
                        "locationAddress": {
                            "place": placeName,
                            "latitude": lat,
                            "longitude": long
                        }
                    });

                    try {
                        const postResponse: any = await BaseService.post_api(`${global_variables.add_company_details}`, data)
                        console.log(postResponse)
                        if (postResponse?.status === 200) {
                            ShowToast.success_toast("Company details have been added successfully!")
                            setIsBusy(false)
                            navigate("/dashboard")
                        }
                    } catch (error: any) {
                        console.log(error);
                        setIsBusy(false)
                        ShowToast.error_toast(error?.response?.data?.error)
                    }
                }
            } catch (error) {
                console.log(error)
                ShowToast.error_toast("Failed to upload company details! Check your company logo and retry.")
                setIsBusy(false)
            }
        }
    }

    //handleLogout
    const handleLogout = () => {
        StorageBox.clearStorage()
        navigate("/")
    }

    return (
        <>
            <div className="h-screen overflow-hidden lg:grid lg:grid-cols-2 w-full">
                {/* left */}
                <div className="col-span-1 w-full h-full hidden lg:block">
                    <img
                        className="object-cover h-full w-full rounded-r-[100px]"
                        src="https://img.freepik.com/free-photo/black-businessman-using-computer-laptop_53876-14802.jpg"
                        alt="sigin"
                    />
                </div>

                {/* right */}
                <div className="col-span-1 w-full h-full overflow-y-auto lg:px-20 px-10 pt-5">
                    <div className="flex justify-center items-center w-full h-full">
                        {/* form */}
                        <div className='w-full h-full'>
                            <div className='flex justify-center items-center mb-5'>
                                <Typography className="font-semibold" variant="h4">Upload Company Information</Typography>
                            </div>

                            {/* image */}
                            <div className='flex justify-center'>
                                <div>
                                    <label htmlFor="chooseLogo">
                                        <div className='h-[250px] w-[250px] bg-midGreen bg-opacity-[7%] border-[1px] border-dashed border-midGreen flex flex-col justify-center items-center cursor-pointer rounded-[5px]'>
                                            {
                                                selectedImage ? <img src={selectedImage} alt="selected" />
                                                    :
                                                    <>
                                                        <PhotoIcon className='h-10 w-10 text-midGreen' />
                                                        <p className='text-midGreen font-medium'>Click to choose logo</p>
                                                    </>
                                            }
                                        </div>
                                    </label>
                                    <input type="file" id="chooseLogo" className='hidden' onChange={handleImageSelected} />

                                    <div className='mt-5 flex justify-end'>
                                        {
                                            selectedImage && <button className='px-4 py-2 bg-gradient-to-r from-red-300 to-red-500 text-white rounded-[5px] flex gap-2 items-center' onClick={() => setSelectedImage(null)}>
                                                <TrashIcon className='h-5 w-5' />
                                                <p>Delete</p>
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className='mt-4'>
                                <Input label=" Company Name" value={compName} onChange={(e) => setCompName(e.target.value)} disabled={isBusy} />
                            </div>

                            <div className='mt-4'>
                                <Input label="Company's Business ID" value={compID} onChange={(e) => setCompID(e.target.value)} disabled={isBusy} />
                            </div>

                            <div className='mt-4'>
                                <Input label="Company's Email" value={compEmail} onChange={(e) => setCompEmail(e.target.value)} disabled={isBusy} />
                            </div>

                            <div className='mt-4'>
                                <PhoneInput
                                    className="rounded-[5px] w-full border-[1px] border-gray-500 h-[40px] px-2 bg-white"
                                    defaultCountry={selectedCountry?.country === "Ghana" ? "GH" : "NG"}
                                    inputStyle={{ width: "100%" }}
                                    placeholder="Company's Phone"
                                    value={compPhone}
                                    onChange={(val: any) => setCompPhone(val)}
                                    disabled={isBusy}
                                    numberInputProps={{
                                        className: "focus:outline-none"
                                    }}
                                />
                            </div>

                            <div className="mt-4">
                                <small>Company's Location</small>
                                <GooglePlacesAutocomplete
                                    apiKey={`${GOOGLE_KEY}`}
                                    autocompletionRequest={{
                                        componentRestrictions: {
                                            country: selectedCountry?.country === "Ghana" ? ["gh"] : ["ng"],
                                        },
                                    }}
                                    selectProps={{
                                        placeholder: "Search...",
                                        isClearable: true,
                                        isSearchable: true,
                                        onChange: (selected) => handlePlaceSelected(selected),
                                        isDisabled: isBusy
                                    }}
                                />
                            </div>

                            <div className='mt-4 pb-10 flex gap-3'>
                                <button className='w-full h-[40px] bg-gradient-to-r from-red-300 to-red-500 text-white rounded-[5px] flex gap-2 items-center justify-center' onClick={() => handleLogout()} disabled={isBusy}>
                                    <PowerIcon className='h-5 w-5' />
                                    <p>Logout</p>
                                </button>
                                <button className='w-full h-[40px] bg-gradient-to-r from-lightGreen to-midGreen text-white rounded-[5px] flex gap-2 items-center justify-center' onClick={() => handleUploadDetails()} disabled={isBusy}>
                                    {
                                        isBusy ? <Spinner />
                                            :
                                            <>
                                                <CloudArrowUpIcon className='h-5 w-5' />
                                                <p>Upload Details</p>
                                            </>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CompanyDetails
