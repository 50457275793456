
import { useState } from "react";
import verifyImg from "../../../images/verifyImg.svg";
import { Input, Spinner } from "@material-tailwind/react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ShowToast from "../../../components/alerts/all_toasts";
import { BASE_URL } from "../../../helpers/constants";
import { global_variables } from "../../../helpers/globalVariables";
import { StorageBox } from "../../../helpers/stroage";
import ShowAlert from "../../../components/alerts/all_alerts";

const NewPwd = () => {
  const [thePwd, setThePwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [isSetting, setIsSetting] = useState(false);
  const navigate = useNavigate();

  const handleResetPwd = () => {
    if (thePwd === "" || confirmPwd === "") {
      ShowToast.warning_toast("Please enter your password!");
    } else if (thePwd !== confirmPwd) {
      ShowToast.warning_toast("Passwords do not match!");
    } else {
      setIsSetting(true);
      let data = JSON.stringify({
        password: thePwd,
      });

      let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `${BASE_URL}${global_variables.resetPwdRoute}`,
        headers: {
          "Content-Type": "application/json",
          "x-access-token": StorageBox.getAccessToken(),
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(response.data);
          StorageBox.saveUserData(response?.data?.payload?.user_data);
          StorageBox.saveAccessToken(response?.data?.token);
          navigate("/dashboard");
        })
        .catch((error) => {
          console.log(error);
          ShowAlert.error_alert(
            "Error",
            error?.response?.data?.error,
            "Retry"
          ).then((result) => {
            if (result.isConfirmed) {
              navigate("/");
            }
          });
          setIsSetting(false);
        });
    }
  };

  return (
    <>
      <div className="h-screen ">
        <div className="bg-[#1e7e7108] h-full flex justify-center items-center lg:px-[200px] px-[30px]">
          <div className="lg:grid lg:grid-cols-2"></div>
          <div className="hidden lg:block">
            <img className="w-[300px]" src={verifyImg} alt="verify" />
          </div>
          <div className="w-[400px] mx-5">
            <p>Enter your new password</p>
            <div className="mt-3">
              <Input
                label="Enter password"
                type="password"
                value={thePwd}
                onChange={(e) => setThePwd(e.target.value)}
              />
            </div>
            <div className="mt-3">
              <Input
                label="Confirm password"
                type="password"
                value={confirmPwd}
                onChange={(e) => setConfirmPwd(e.target.value)}
              />
            </div>

            <div className="mt-5">
              <button
                className="h-[40px] w-full bg-midGreen text-white rounded-[5px] flex justify-center items-center disabled:cursor-not-allowed"
                onClick={() => handleResetPwd()}
                disabled={isSetting}
              >
                {isSetting ? <Spinner /> : <span>Submit</span>}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewPwd;
