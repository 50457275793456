import { ChevronLeftIcon, CloudArrowUpIcon, EyeSlashIcon, PencilSquareIcon, PhotoIcon, TrashIcon } from '@heroicons/react/24/outline'
import { Input, Spinner, Typography } from '@material-tailwind/react'
import { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import GooglePlacesAutocomplete, {
    geocodeByPlaceId,
    getLatLng,
} from "react-google-places-autocomplete";
import { GOOGLE_KEY } from '../../helpers/constants';
import BaseService from '../../helpers/baseServices';
import { global_variables } from '../../helpers/globalVariables';
import ShowToast from '../../components/alerts/all_toasts';
import { formatPhoneNumber } from '../../utils/functions';
import { StorageBox } from '../../helpers/stroage';

const EditCompanyDetails = () => {
    const navigate = useNavigate()
    const selectedCountry = StorageBox.getSavedCountry()

    const [isBusy, setIsBusy] = useState(false)
    const [isFetching, setIsFetching] = useState(false)
    const [selectedImage, setSelectedImage] = useState<any>(null);
    const [theFile, setTheFile] = useState<any>(null)

    const [placeName, setPlaceName] = useState("");
    const [long, setLong] = useState<any>(0);
    const [lat, setLat] = useState<any>(0);
    const [compName, setCompName] = useState("")
    const [compID, setCompID] = useState("")
    const [compEmail, setCompEmail] = useState("")
    const [reFetch, setReFetch] = useState(false)
    const [compPhone, setCompPhone] = useState("")
    const [uniqueId, setUniqueId] = useState("")
    const [companyLogo, setCompanyLogo] = useState("")
    const [editLoc, setEditLoc] = useState(false)

    //onload
    useEffect(() => {
        fetchUserDeatils()
    }, [reFetch])

    //fetch user details
    const fetchUserDeatils = async () => {
        setIsFetching(true)
        try {
            const response = await BaseService.get_api(`${global_variables.get_user_details}`)
            console.log(response?.data?.payload?.company_data)
            setUniqueId(response?.data?.payload?.company_data?._id)
            setCompName(response?.data?.payload?.company_data?.name)
            setCompID(response?.data?.payload?.company_data?.registeration)
            setCompEmail(response?.data?.payload?.company_data?.email)
            setCompPhone(response?.data?.payload?.company_data?.phone)
            setCompanyLogo(response?.data?.payload?.company_data?.businessLogo)
            setPlaceName(response?.data?.payload?.company_data?.locationAddress?.place)
            setIsFetching(false)
        } catch (error) {
            console.log(error)
            setIsFetching(false)
        }
    }

    //handle image slected
    const handleImageSelected = (e: any) => {
        const file = e.target.files[0];
        setTheFile(file)
        const imageUrl = URL.createObjectURL(file);
        setSelectedImage(imageUrl);
    };

    // handle place slected
    const handlePlaceSelected = (selected: any) => {
        if (selected) {
            console.log(selected.value);
            geocodeByPlaceId(selected.value.place_id)
                .then((results) => {
                    console.log(results);
                    getLatLng(results[0]).then(({ lat, lng }) => {
                        console.log(lat, lng);
                        setLat(lat);
                        setLong(lng);
                    });
                })
                .catch((error) => console.error(error));
            setPlaceName(selected.value.description);
        } else {
            console.log("No option selected");
            setPlaceName("");
        }
    };

    //handleUploadDetails
    const handleUploadDetails = async () => {
        if (selectedImage === null) {
            ShowToast.warning_toast("Please upload your company logo!")
        }
        else if (compName === "" || compEmail === "" || compID === "" || compPhone === "" || placeName === "") {
            ShowToast.warning_toast("Please fill out all fields!")
        }
        else {
            try {
                setIsBusy(true)
                const response: any = await BaseService.file_upload(`${global_variables.file_upload}?imagename=${compName}&storage=testfolder`, theFile)
                console.log(response)
                if (response?.status === 200) {
                    let data = JSON.stringify({
                        "_id": uniqueId,
                        "name": compName,
                        "registeration": compID,
                        "businessLogo": response?.data?.url,
                        "email": compEmail,
                        "phone": formatPhoneNumber(compPhone),
                        "locationAddress": {
                            "place": placeName,
                            "latitude": lat,
                            "longitude": long
                        }
                    });

                    try {
                        const postResponse: any = await BaseService.put_api(`${global_variables.add_company_details}`, data)
                        console.log(postResponse)
                        if (postResponse?.status === 200) {
                            ShowToast.success_toast("Company details have been updated successfully!")
                            setIsBusy(false)
                            setSelectedImage(null)
                            setReFetch(!reFetch)
                        }
                    } catch (error: any) {
                        console.log(error);
                        setIsBusy(false)
                        ShowToast.error_toast(error?.response?.data?.error)
                    }
                }
            } catch (error) {
                console.log(error)
                ShowToast.error_toast("Failed to upload company details! Check your company logo and retry.")
                setIsBusy(false)
            }
        }
    }

    return (
        <Fragment>
            <div className="h-screen bg-[#1e7e7114] overflow-hidden">
                <div className="h-full overflow-y-auto">
                    <div className='flex gap-5 m-5 items-center'>
                        <button className='flex gap-2 items-center bg-gradient-to-r from-lightGreen to-midGreen text-white px-4 py-2 rounded-[5px]' onClick={() => navigate("/dashboard")}>
                            <ChevronLeftIcon className='h-5 w-5' />
                            <p>Back</p>
                        </button>
                        <Typography className="font-semibold text-2xl">Company Information</Typography>
                    </div>

                    {/* main */}
                    <div className='flex justify-center items-center gap-10 mb-10 mt-10'>
                        {/* image */}
                        <div className='flex justify-center'>
                            <div>
                                {/* <Typography className="font-semibold" variant="h4">Company Information</Typography> */}
                                <label htmlFor="chooseLogo">
                                    <div className='h-[350px] w-[350px] bg-midGreen bg-opacity-[7%] border-[1px] border-dashed border-midGreen flex flex-col justify-center items-center cursor-pointer rounded-[5px]'>
                                        {
                                            selectedImage ? <img src={selectedImage} alt="selected" />
                                                :
                                                <>
                                                    <PhotoIcon className='h-10 w-10 text-midGreen' />
                                                    <p className='text-midGreen font-medium'>Click to change logo</p>
                                                </>
                                        }
                                    </div>
                                </label>
                                <input type="file" id="chooseLogo" className='hidden' onChange={handleImageSelected} />

                                <div className='mt-5 flex justify-end'>
                                    <button className='px-4 py-2 bg-gradient-to-r from-red-300 to-red-500 text-white rounded-[5px] flex gap-2 items-center' onClick={() => setSelectedImage(null)}>
                                        <TrashIcon className='h-5 w-5' />
                                        <p>Delete</p>
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* form */}
                        <div className='w-[500px]'>
                            <div className='flex justify-center items-center'>
                                <img className='w-[150px] h-[150px] rounded-full border-[1px] border-midGreen border-dashed' src={companyLogo || 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png'} alt="cLogo" />
                            </div>

                            <div className='mt-4'>
                                <Input label="Company Name" value={compName} onChange={(e) => setCompName(e.target.value)} disabled={isBusy || isFetching} />
                            </div>

                            <div className='mt-4'>
                                <Input label="Company Registration ID" value={compID} onChange={(e) => setCompID(e.target.value)} disabled={isBusy || isFetching} />
                            </div>

                            <div className='mt-4'>
                                <Input label="Company's Email" value={compEmail} onChange={(e) => setCompEmail(e.target.value)} disabled={isBusy || isFetching} />
                            </div>

                            <div className='mt-4'>
                                <Input label="Company's Phone" value={compPhone} onChange={(e) => setCompPhone(e.target.value)} disabled={isBusy || isFetching} />
                            </div>

                            <div className="mt-4">
                                <small>Company's Location</small>
                                {
                                    editLoc ?
                                        <div className="flex gap-3 items-center w-full">
                                            <GooglePlacesAutocomplete
                                                apiKey={`${GOOGLE_KEY}`}
                                                autocompletionRequest={{
                                                    componentRestrictions: {
                                                        country: selectedCountry?.country === "Ghana" ? ["gh"] : ["ng"],
                                                    },
                                                }}
                                                selectProps={{
                                                    className: "w-full",
                                                    placeholder: "Search...",
                                                    isClearable: true,
                                                    isSearchable: true,
                                                    onChange: (selected) => handlePlaceSelected(selected),
                                                    isDisabled: isBusy || isFetching
                                                }}
                                            />
                                            <EyeSlashIcon className='h-5 w-5 cursor-pointer' onClick={() => setEditLoc(false)} />
                                        </div>
                                        :
                                        <div className="flex gap-3 items-center w-full">
                                            <div className='w-full bg-white rounded-[5px] h-[40px] border-[1px] border-gray-300 px-2 flex items-center'>
                                                <p>{placeName}</p>
                                            </div>
                                            <PencilSquareIcon className='h-5 w-5 cursor-pointer' onClick={() => setEditLoc(true)} />
                                        </div>
                                }
                            </div>

                            <div className='mt-4'>
                                <button className='w-full h-[40px] bg-gradient-to-r from-lightGreen to-midGreen text-white rounded-[5px] flex gap-2 items-center justify-center' onClick={() => handleUploadDetails()} disabled={isBusy}>
                                    {
                                        isBusy ? <Spinner />
                                            :
                                            <>
                                                <CloudArrowUpIcon className='h-5 w-5' />
                                                <p>Upload Details</p>
                                            </>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </Fragment>
    )
}

export default EditCompanyDetails
