import { Fragment, useEffect, useState } from "react";
import { TripModel } from "../trips.model";
import moment from "moment";
import { ScheduleModel } from "../../schedules/schedule.model";
import { Avatar } from "@material-tailwind/react";
import { Empty } from "antd";

interface shippingProps {
    shippingData: TripModel
}

const ShippingInfoTab = ({ shippingData }: shippingProps) => {

    const [selectedOrder, setSelectedOrder] = useState<ScheduleModel | any>()

    useEffect(() => {
        if (shippingData) {
            setSelectedOrder(shippingData)
            console.log(shippingData)

        }
    }, [shippingData])

    return (
        <Fragment>
            <div className="mb-10">

                {/* rider & sender details */}
                <div className="lg:grid lg:grid-cols-2 gap-10">
                    <div>
                        <p className="text-lg font-medium">Sender Details</p>
                        <div className="mt-2 flex gap-2 items-center">
                            <p className="font-medium text-sm">Name:</p>
                            <p className="text-sm">{selectedOrder?.UserData?.[0]?.fullName}</p>
                        </div>
                        <div className="mt-2 flex gap-2 items-center">
                            <p className="font-medium text-sm">Phone:</p>
                            <p className="text-sm">{selectedOrder?.UserData?.[0]?.phone}</p>
                        </div>
                        <div className="mt-2 flex gap-2 items-center">
                            <p className="font-medium text-sm">Pick Up:</p>
                            <p className="text-sm">{selectedOrder?.pickup?.place}</p>
                        </div>
                        <div className="mt-2 flex gap-2 items-center">
                            <p className="font-medium text-sm">Destination:</p>
                            <p className="text-sm">{selectedOrder?.destination?.place}</p>
                        </div>
                        {
                            selectedOrder?.scheduleData ?
                                <>
                                    <hr className="my-2" />
                                    <div className="mt-2 flex gap-2 items-center">
                                        <p className="font-medium text-sm">Scheduled Date:</p>
                                        <p className="text-sm">{moment(selectedOrder?.scheduleData?.scheduleTime).format("Do MMM YYYY - hh:mm A")}</p>
                                    </div>
                                    <div className="mt-2 flex gap-2 items-center">
                                        <p className="font-medium text-sm">Schedule Expiry:</p>
                                        <p className="text-sm">{moment(selectedOrder?.scheduleData?.expiryDate).format("Do MMM YYYY - hh:mm A")}</p>
                                    </div>
                                </>
                                :
                                <></>
                        }
                    </div>

                    <div className="mt-3 lg:mt-0">
                        <p className="text-lg font-medium">Package Description</p>
                        <p className="text-sm">
                            {selectedOrder?.itemDescription || "No description"}
                        </p>
                    </div>
                </div>

                <hr className="my-4" />


                {/* photos */}
                <div className="mt-6">
                    <p className="text-lg font-medium">Item Photos</p>
                    {selectedOrder?.images ?
                        <div className="flex items-center gap-4 flex-wrap mt-4">
                            {selectedOrder?.images.map((theImg: any, i: number) => (
                                <img
                                    key={i}
                                    className="w-[250px] h-[150px] object-cover rounded-[10px] shadow-sm border border-gray-300"
                                    src={theImg}
                                    alt={`img${i}`}
                                />
                            ))}
                        </div>
                        :
                        <div className="flex items-center mt-4">
                            <Empty description="No images for this schedule" />
                        </div>
                    }
                </div>

                <hr className="my-4" />

                <div>
                    <p className="text-lg font-medium">Other Information</p>
                    <div className="mt-2 flex gap-2 items-center">
                        <p className="font-medium text-sm">Trip Cost:</p>
                        <p className="text-sm">{selectedOrder?.countryData?.[0]?.currencyCode} {selectedOrder?.initialPriceMetrics?.initialAmount}</p>
                    </div>
                    <div className="mt-2 flex gap-2 items-center">
                        <p className="font-medium text-sm">Item Quantity:</p>
                        <p className="text-sm">{selectedOrder?.quantity}</p>
                    </div>
                    <div className="mt-2 flex gap-2 items-center">
                        <p className="font-medium text-sm">Item Weight:</p>
                        <p className="text-sm">{selectedOrder?.weight} {selectedOrder?.weightUnit}</p>
                    </div>
                    <div className="mt-2 flex gap-2 items-center">
                        <p className="font-medium text-sm">Notes:</p>
                        <p className="text-sm">{selectedOrder?.notes}</p>
                    </div>
                </div>

                <hr className="my-4" />

                <div>
                    <p className="text-lg font-medium">Rider Details</p>
                    {
                        selectedOrder?.PickUpDriverData.length === 0 && selectedOrder?.DriverData.length === 0 ?
                            <>
                                <p>No rider on this trip</p>
                            </>
                            :
                            <>
                                <div>
                                    <Avatar size="lg" src={selectedOrder?.PickUpDriverData?.[0]?.image || selectedOrder?.DriverData?.[0]?.image || "https://www.nicepng.com/png/detail/136-1366211_group-of-10-guys-login-user-icon-png.png"} />
                                </div>
                                <div className="mt-2 flex gap-2 items-center">
                                    <p className="font-medium text-sm">First Name:</p>
                                    <p className="text-sm">{selectedOrder?.PickUpDriverData?.[0]?.firstName || selectedOrder?.DriverData?.[0]?.firstName}</p>
                                </div>
                                <div className="mt-2 flex gap-2 items-center">
                                    <p className="font-medium text-sm">Last Name:</p>
                                    <p className="text-sm">{selectedOrder?.PickUpDriverData?.[0]?.lastName || selectedOrder?.DriverData?.[0]?.lastName}</p>
                                </div>
                                <div className="mt-2 flex gap-2 items-center">
                                    <p className="font-medium text-sm">Phone:</p>
                                    <p className="text-sm">{selectedOrder?.PickUpDriverData?.[0]?.phone || selectedOrder?.DriverData?.[0]?.phone}</p>
                                </div>
                            </>
                    }
                </div>

                <hr className="my-4" />

                <div>
                    <p className="text-lg font-medium">Vehicle Information</p>
                    {
                        selectedOrder?.pickUpVehicleData.length === 0 && selectedOrder?.vehicleData.length === 0 ?
                            <>
                                <p>No vehicle on this trip</p>
                            </>
                            :
                            <>
                                <div>
                                    {/* <!-- Vehicle Information --> */}
                                    <div className="lg:flex lg:gap-5">
                                        <div className="w-full">
                                            <div className="mb-6">
                                                <p className="text-gray-600 font-medium">Registration Number:</p>
                                                <p className="text-lg font-medium">{selectedOrder?.pickUpVehicleData?.[0]?.registeration || selectedOrder?.vehicleData?.[0]?.registeration}</p>
                                            </div>
                                            <div className="mb-6">
                                                <p className="text-gray-600 font-medium">Brand:</p>
                                                <p className="text-lg font-medium">{selectedOrder?.pickUpVehicleData?.[0]?.brandData?.[0]?.brand || selectedOrder?.vehicleData?.[0]?.brandData?.[0]?.brand}</p>
                                            </div>
                                            <div className="mb-6">
                                                <p className="text-gray-600 font-medium">Model:</p>
                                                <p className="text-lg font-medium">{selectedOrder?.pickUpVehicleData?.[0]?.vehicleModelData?.[0]?.model || selectedOrder?.vehicleData?.[0]?.vehicleModelData?.[0]?.model}</p>
                                            </div>
                                        </div>
                                        <div className="w-full">
                                            <div className="mb-6">
                                                <p className="text-gray-600 font-medium">Features:</p>
                                                <p className="text-lg font-medium">{selectedOrder?.pickUpVehicleData?.[0]?.featuresData.map((dd: any) => dd?.feature + ",") || selectedOrder?.vehicleData?.[0]?.featuresData.map((dd: any) => dd?.feature + ",")}</p>
                                            </div>
                                            <div className="mb-6">
                                                <p className="text-gray-600 font-medium">Color:</p>
                                                <p className="text-lg font-medium">{selectedOrder?.pickUpVehicleData?.[0]?.vehicleColor || selectedOrder?.vehicleData?.[0]?.vehicleColor}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <hr className="my-4" />

                                    {/* // <!-- images Details --> */}
                                    <p className="font-semibold text-xl mb-4">Images</p>
                                    <div className="lg:grid lg:grid-cols-2 lg:gap-5 mb-6">
                                        <div className="w-full">
                                            <p className="text-gray-600 font-medium">Vehicle Front:</p>
                                            <img className="w-[300px] h-[300px] object-cover border-[1px] border-midGreen border-dashed rounded-[5px] shadow-sm" src={selectedOrder?.pickUpVehicleData?.[0]?.vehicleFront || selectedOrder?.vehicleData?.[0]?.vehicleFront} alt="front" />
                                        </div>
                                        <div className="w-full">
                                            <p className="text-gray-600 font-medium">Vehicle Side:</p>
                                            <img className="w-[300px] h-[300px] object-cover border-[1px] border-midGreen border-dashed rounded-[5px] shadow-sm" src={selectedOrder?.pickUpVehicleData?.[0]?.vehicleSide || selectedOrder?.vehicleData?.[0]?.vehicleSide} alt="side" />
                                        </div>
                                        <div className="w-full">
                                            <p className="text-gray-600 font-medium">Vehicle Back:</p>
                                            <img className="w-[300px] h-[300px] object-cover border-[1px] border-midGreen border-dashed rounded-[5px] shadow-sm" src={selectedOrder?.pickUpVehicleData?.[0]?.vehicleBack || selectedOrder?.vehicleData?.[0]?.vehicleBack} alt="back" />
                                        </div>
                                    </div>


                                </div>
                            </>
                    }
                </div>

            </div>
        </Fragment>
    );
};

export default ShippingInfoTab;