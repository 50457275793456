import CustomSideBar from '../../components/layouts/sidebar'
import CustomNavbar from '../../components/layouts/navbar'
import { useEffect, useState } from 'react';
import { EyeIcon } from '@heroicons/react/24/outline';
import CreateRequestDrawer from '../request/createRequestDrawer';
import BaseService from '../../helpers/baseServices';
import { global_variables } from '../../helpers/globalVariables';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { TripModel } from '../orderMgt/trips.model';
import LiveDeliveryDrawer from '../orderMgt/liveDeliveryDrawer';
import { useNavigate } from 'react-router-dom';


const OrdersLimited = () => {

    const navigate = useNavigate()

    const [isFetching, setIsFetching] = useState(false)
    const [liveTrips, setLiveTrips] = useState<TripModel[]>([])
    const [total, setTotal] = useState(0)
    const [selectedOrder, setSelectedOrder] = useState<TripModel | any>(null)

    //open details
    const [openDrawer, setOpenDrawer] = useState(false);
    const handleOpen = () => {
        setOpenDrawer(!openDrawer);
    };

    //handleViewDetails
    const handleViewDetails = (data: any) => {
        setSelectedOrder(data)
        handleOpen();
    }


    //on page load
    useEffect(() => {
        getLiveOrders()
    }, [])

    //getLiveOrders
    const getLiveOrders = async () => {
        setIsFetching(true)
        try {
            const response = await BaseService.get_api(`${global_variables.get_all_trips}?filter=[{ "$and": [{ "currentStatus.name": "Accept" }, {"completed": false}] }]&limit=5`)
            // console.log(response?.data?.payload)
            setLiveTrips(response?.data?.payload)
            setTotal(response?.data?.total)
            setIsFetching(false)
        } catch (error) {
            console.log(error)
        }
    }

    // open create
    const [openAddDrawer, setOpenAddDrawer] = useState(false)
    const handleOpenDrawer = () => {
        setOpenAddDrawer(true);
    };
    // close create
    const handleCloseDrawer = () => {
        setOpenAddDrawer(false);
    };


    // Define the columns for the table
    const columns = [
        {
            name: "Pickup",
            cell: (row: any) => row?.pickup?.place,
        },
        {
            name: "Destination",
            cell: (row: any) => row?.destination?.place,
        },
        {
            name: "Item Description",
            cell: (row: any) => row?.itemDescription || "No description",
            width: "20%"
        },
        {
            name: "Qty",
            selector: (row: any) => row?.quantity,
        },
        {
            name: "Weight",
            selector: (row: any) => `${row?.weight} ${row?.weightUnit}`,
        },
        {
            name: "Date Created",
            cell: (row: any) => moment(row?.createdAt).format("Do MMM YYYY - hh:mm A"),
        },
        {
            name: "Action",
            selector: (row: any) => <div className='flex gap-4'>
                <EyeIcon className='h-5 w-5 cursor-pointer' title='View Order Details' onClick={() => handleViewDetails(row)} />
            </div>
        }
    ];



    return (
        <>
            <div className='mt-5 bg-white shadow-lg rounded-[20px] p-5'>
                <div className="flex justify-between items-center">
                    <p className="font-semibold text-md">Live Orders</p>
                    <button className="px-4 py-2 rounded-[5px] w-fit bg-gradient-to-r from-lightGreen to-midGreen text-white" onClick={() => navigate('/orders')}>
                        View All
                    </button>
                </div>
                <DataTable
                    columns={columns}
                    data={liveTrips}
                    progressPending={isFetching}
                    responsive
                />
            </div>

            {/* create request drawer modal */}
            {
                openAddDrawer ? <CreateRequestDrawer isOpened={openAddDrawer} handleClose={handleCloseDrawer} />
                    :
                    <></>
            }

            {openDrawer ? (
                <LiveDeliveryDrawer
                    isOpened={openDrawer}
                    closeDrawer={() => setOpenDrawer(false)}
                    deliveryData={selectedOrder}
                />
            ) : (
                <></>
            )}


        </>
    )
}

export default OrdersLimited
