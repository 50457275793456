import { useEffect, useState } from 'react'
import CustomSideBar from '../../components/layouts/sidebar'
import CustomNavbar from '../../components/layouts/navbar'
import CreateRouteModal from './address_routes/createRouteModal'
import DataTable from 'react-data-table-component'
import { PencilSquareIcon } from '@heroicons/react/24/outline'
import BaseService from '../../helpers/baseServices'
import { global_variables } from '../../helpers/globalVariables'

const Operations = () => {

    const [reFetch, setReFetch] = useState(false)
    const [isFetching, setIsFetching] = useState(false)
    const [theRoutes, setTheRoutes] = useState([])
    const [total, setTotal] = useState(0)
    const [dataToEdit, setDataToEdit] = useState<any>(null)
    const [isEditing, setIsEditing] = useState<any>(null)

    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
        setIsEditing(null)
    }

    //onload
    useEffect(() => {
        getAllRoutes()
    }, [reFetch])

    //getAllRoutes
    const getAllRoutes = async () => {
        setIsFetching(true)
        try {
            const response = await BaseService.get_api(`${global_variables.routes}`)
            console.log(response?.data)
            setTheRoutes(response?.data?.payload)
            setTotal(response?.data?.totla)
            setIsFetching(false)
        } catch (error) {
            console.log(error)
        }
    }

    //triggerEdit
    const triggerEdit = (data: any) => {
        setDataToEdit(data)
        handleOpen()
        setIsEditing(true)
    }

    // Define the columns for the table
    const columns = [
        {
            name: "Route Name",
            selector: (row: any) => row?.name,
        },
        {
            name: "Pickup",
            cell: (row: any) => row?.pickup?.place,
        },
        {
            name: "Pickup City",
            cell: (row: any) => row?.pickup?.city,
        },
        {
            name: "Pickup Region",
            cell: (row: any) => row?.pickup?.region,
        },
        {
            name: "Destination",
            cell: (row: any) => row?.destination?.place,
        },
        {
            name: "Destination City",
            cell: (row: any) => row?.destination?.city,
        },
        {
            name: "Destination Region",
            cell: (row: any) => row?.destination?.region,
        },
        {
            name: "Action",
            selector: (row: any) => <div>
                <PencilSquareIcon className='h-5 w-5 cursor-pointer' onClick={() => triggerEdit(row)} />
            </div>,
        },
    ];

    //handleReFtech
    const handleReFtech = () => { setReFetch(!reFetch) }

    return (
        <>
            <div className="h-screen overflow-hidden">
                <div className="flex h-full">
                    {/* sidebar */}
                    <CustomSideBar opsActive="active" />
                    {/* navbar */}
                    <div className="w-full">
                        <CustomNavbar />
                        <div className="h-screen bg-[#1e7e7108] p-[20px] overflow-y-auto">
                            <p className="font-semibold text-lg">Routes Management</p>


                            <div className='p-5 rounded-[20px] bg-white shadow-lg mt-5'>
                                <div className='flex justify-end'>
                                    <button className='bg-gradient-to-r from-lightGreen to-midGreen h-[40px] px-4 rounded-[5px] text-white shadow-lg' onClick={() => handleOpen()} >
                                        + Add New
                                    </button>
                                </div>
                                <DataTable
                                    columns={columns}
                                    data={theRoutes}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={total}
                                    progressPending={isFetching}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>



            {/* open modal */}
            {
                open ? <CreateRouteModal openModal={open} handleOpenModal={handleOpen} handleReFetch={handleReFtech} dataToEdit={dataToEdit} isEditing={isEditing} handleCloseModal={handleClose} />
                    :
                    <></>
            }

        </>
    )
}

export default Operations
