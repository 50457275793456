import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { GOOGLE_KEY } from "../../helpers/constants";
import { StorageBox } from "../../helpers/stroage";

interface selectProps {
    onChange: (data: any) => void
    placeholder?: string
    disabled?: boolean
}

const LocationSelector = ({ onChange, placeholder, disabled }: selectProps) => {

    const selectedCountry = StorageBox.getSavedCountry()

    return (
        <>
            <GooglePlacesAutocomplete
                apiKey={`${GOOGLE_KEY}`}
                autocompletionRequest={{
                    componentRestrictions: {
                        country: selectedCountry?.country === "Ghana" ? ["gh"] : ["ng"],
                    },
                }}
                selectProps={{
                    className: "border-none",
                    placeholder: placeholder,
                    isClearable: true,
                    isSearchable: true,
                    onChange: (selected: any) => onChange(selected),
                    isDisabled: disabled,
                }}
            />
        </>
    )
}

export default LocationSelector
