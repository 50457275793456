import { Spinner } from "@material-tailwind/react";
import React, { useState } from "react";
import PinInput from "react-pin-input";
import verifyImg from "../../../images/verifyImg.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../helpers/constants";
import { global_variables } from "../../../helpers/globalVariables";
import { StorageBox } from "../../../helpers/stroage";
import ShowAlert from "../../../components/alerts/all_alerts";

const VerifyToken = () => {
  const [isVerifying, setIsVerifying] = useState(false);
  const [inputPin, setInputPin] = useState("");
  const navigate = useNavigate();

  // runVerification
  const runVerification = (value: any) => {
    setIsVerifying(true);
    console.log(value);
    const data = JSON.stringify({
      token: value,
    });

    localStorage.setItem("otp", inputPin)

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${BASE_URL}${global_variables.verifyTokenRoute}`,
      headers: {
        "Content-Type": "application/json",
        "x-access-token": StorageBox.getTempToken(),
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        StorageBox.saveUserData(response?.data?.payload?.user_data);
        StorageBox.saveAccessToken(response?.data?.token);
        navigate("/newPwd");
      })
      .catch((error) => {
        console.log(error);
        ShowAlert.error_alert(
          "Error",
          error?.response?.data?.error,
          "Retry"
        ).then((result) => {
          if (result.isConfirmed) {
            navigate("/");
          }
        });
        setIsVerifying(false);
      });
  };

  return (
    <>
      <div className="flex flex-col justify-center items-center h-screen">
        <div className="hidden lg:block">
          <img className="w-[200px]" src={verifyImg} alt="verify" />
        </div>
        <div className="p-[20px] flex justify-center items-center flex-col">
          <p className="text-center">
            We have sent a 5 digits pin to your email, enter it below and click
            confirm.
          </p>

          <div className="mt-3">
            <PinInput
              length={5}
              initialValue=""
              secret
              secretDelay={100}
              onChange={(value) => setInputPin(value)}
              type="numeric"
              inputMode="number"
              style={{ padding: "10px" }}
              inputStyle={{ borderColor: "red" }}
              inputFocusStyle={{ borderColor: "#165b4f" }}
              onComplete={(value) => runVerification(value)}
              autoSelect={true}
              disabled={isVerifying}
            />
          </div>

          {isVerifying ? (
            <div className="mt-3">
              <Spinner color="green" />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default VerifyToken;
