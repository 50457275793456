import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
  Spinner,
} from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../helpers/constants";
import { global_variables } from "../../../helpers/globalVariables";
import axios from "axios";
import { StorageBox } from "../../../helpers/stroage";
import ShowAlert from "../../../components/alerts/all_alerts";

interface modalProps {
  openPwdModal: boolean;
  handleOpenPwdModal: () => void;
}

const ForgotPwdModal = ({ openPwdModal, handleOpenPwdModal }: modalProps) => {
  const navigate = useNavigate();
  const [theEmail, setTheEmail] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleSendCode = async () => {
    if (theEmail === "") {
      setIsError(true);
    } else {
      setIsSending(true);
      let data = JSON.stringify({
        email: theEmail,
      });

      let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `${BASE_URL}${global_variables.forgotPwd}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      try {
        const response = await axios.request(config);
        console.log(response.data);
        StorageBox.saveTempToken(response?.data?.token);
        setIsSending(false);
        navigate("/verifyToken");
      } catch (error: any) {
        setIsSending(false);
        console.log(error);
        handleOpenPwdModal();
        ShowAlert.error_alert(
          "Error",
          `${error?.response?.data?.error}`,
          "Retry"
        );
      }
    }
  };

  return (
    <>
      <Dialog open={openPwdModal} handler={handleOpenPwdModal}>
        <DialogHeader>Password Recovery</DialogHeader>
        <DialogBody divider>
          <div>
            <p>
              Enter your email below. We will send you a verification pin to
              help you reset your password.
            </p>
            <div className="mt-2">
              <Input
                label="Enter your email"
                type="email"
                value={theEmail}
                onChange={(e) => setTheEmail(e.target.value)}
                error={isError}
                disabled={isSending}
              />
            </div>
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleOpenPwdModal}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <button
            className="px-3 py-2 rounded-[5px] bg-midGreen text-white disabled:cursor-not-allowed"
            onClick={() => handleSendCode()}
            disabled={isSending}
          >
            {isSending ? <Spinner /> : <span>Send Code</span>}
          </button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default ForgotPwdModal;
