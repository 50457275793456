import { AtSymbolIcon, LockClosedIcon, UserIcon } from "@heroicons/react/24/outline";
import { Input, Spinner, Typography } from "@material-tailwind/react";
import { Fragment, useState } from "react";
import { BASE_URL } from "../../../../helpers/constants";
import { global_variables } from "../../../../helpers/globalVariables";
import axios from "axios";
import ShowToast from "../../../../components/alerts/all_toasts";
import logo from "../../../../images/muuvLogo.png";
import { useNavigate } from "react-router-dom";
import InputPinModal from "./input-pin";
import CountrySelector from "../../../../components/general/countrySelector";
import { StorageBox } from "../../../../helpers/stroage";
import PhoneInput, { Value } from "react-phone-number-input";


const SignUpForm = () => {
  const navigate = useNavigate()
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [inputEmail, setInputEmail] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const [fName, setFName] = useState("")
  const [mName, setMName] = useState("")
  const [lName, setLName] = useState("")
  const [thePhone, setThePhone] = useState<Value>("")
  const [verifyToken, setVerifyToken] = useState("");
  const [selectedCountry, setSelectedCountry] = useState<any>(null)


  // handle on sign up click
  const doSignUp = (e: any) => {
    e.preventDefault();
    if (inputEmail === "" || inputPassword === "" || fName === "" || lName === "" || thePhone === "" || !selectedCountry) {
      ShowToast.warning_toast("Please fill out all fields!");
    } else {
      setIsLoggingIn(true);
      const data = mName !== "" ?
        JSON.stringify({
          "email": inputEmail,
          "password": inputPassword,
          "firstName": fName,
          "middleName": mName,
          "phone": thePhone,
          "lastName": lName
        })
        :
        JSON.stringify({
          "email": inputEmail,
          "password": inputPassword,
          "firstName": fName,
          "phone": thePhone,
          "lastName": lName
        })

      console.log(JSON.parse(data))

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${BASE_URL}${global_variables.signupRoute}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(response.data);
          StorageBox.saveCountry(selectedCountry)
          setVerifyToken(response?.data?.token);
          handleOpenModal();
        })
        .catch((error) => {
          console.log(error);
          setIsLoggingIn(false);
          ShowToast.error_toast(error?.response?.data?.error);
        });
    }
  };

  // otp modal
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);

  // forgot pwd modal
  const [openForgotModal, setOpenForgotModal] = useState(false);
  const handleOpenForgotModal = () => setOpenForgotModal(!openForgotModal);

  return (
    <Fragment>
      <div className="flex flex-col gap-6 w-full overflow-y-auto h-full py-5">
        <div className="lg:mx-20">
          <div className="flex justify-center items-center mb-5">
            <img className="w-[120px]" src={logo} alt="logo" />
          </div>

          <form onSubmit={doSignUp} className="mx-5 lg:mx-0">
            <Input
              label="First Name"
              icon={<UserIcon />}
              value={fName}
              onChange={(e) => setFName(e.target.value)}
              disabled={isLoggingIn}
            />

            <div className="mt-5">
              <Input
                label="Middle Name (Optional)"
                icon={<UserIcon />}
                value={mName}
                onChange={(e) => setMName(e.target.value)}
                disabled={isLoggingIn}
              />
            </div>

            <div className="mt-5">
              <Input
                label="Last Name"
                icon={<UserIcon />}
                value={lName}
                onChange={(e) => setLName(e.target.value)}
                disabled={isLoggingIn}
              />
            </div>

            <div className="mt-5">
              <Input
                label="Email"
                type="email"
                icon={<AtSymbolIcon />}
                value={inputEmail}
                onChange={(e) => setInputEmail(e.target.value)}
                disabled={isLoggingIn}
              />
            </div>

            <div className="mt-5">
              <Input
                label="Password"
                type="password"
                icon={<LockClosedIcon />}
                value={inputPassword}
                onChange={(e) => setInputPassword(e.target.value)}
                disabled={isLoggingIn}
              />
            </div>

            {/* CountrySelector */}
            <div className="mt-5">
              <CountrySelector onChange={(selected: any) => setSelectedCountry(selected?.value)} />
            </div>

            <div className="mt-5">
              <PhoneInput
                className="rounded-[5px] w-full border-[1px] border-gray-500 h-[40px] px-2 bg-white"
                defaultCountry={selectedCountry?.country === "Ghana" ? "GH" : "NG"}
                inputStyle={{ width: "100%" }}
                placeholder="Enter phone number"
                value={thePhone}
                onChange={(val: any) => setThePhone(val)}
                disabled={isLoggingIn}
                numberInputProps={{
                  className: "focus:outline-none"
                }}
              />
            </div>

            <button
              className={`${isLoggingIn
                ? "cursor-not-allowed flex justify-center items-center"
                : ""
                } w-full bg-midGreen text-white rounded-[5px] py-3 mt-6 text-center`}
              type="submit"
              disabled={isLoggingIn}
            >
              {isLoggingIn ? <Spinner /> : "Create Account"}
            </button>
          </form>


          <div className="flex items-center justify-center gap-2 mt-2">
            <Typography>Already a partner?</Typography>
            <button className="text-midGreen font-medium" onClick={() => navigate("/")}>Sign In</button>
          </div>
        </div>
      </div>



      {/* input pin modal */}
      {openModal ? (
        <InputPinModal
          openModal={openModal}
          handleOpenModal={() => handleOpenModal()}
          vToken={verifyToken}
        />
      ) : (
        <></>
      )}

    </Fragment>
  );
};

export default SignUpForm;
