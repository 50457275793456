import CustomSideBar from '../../components/layouts/sidebar'
import CustomNavbar from '../../components/layouts/navbar'
import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import BaseService from '../../helpers/baseServices';
import { global_variables } from '../../helpers/globalVariables';
import { EyeIcon } from '@heroicons/react/24/outline';
import { TripModel } from '../orderMgt/trips.model';
import ScheduleDetailsDrawer from '../schedules/scheduleDetailsDrawer';

const OrderHistory = () => {

    const [isFetching, setIsFetching] = useState(false)
    const [allScehdules, setAllScehdules] = useState([])
    const [selectedOrder, setSelectedOrder] = useState<TripModel | any>(null)
    const [total, setTotal] = useState(0)
    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(10)

    //open details
    const [openDrawer, setOpenDrawer] = useState(false);
    const handleOpen = () => {
        setOpenDrawer(!openDrawer);
    };

    //handleViewDetails
    const handleViewDetails = (data: any) => {
        setSelectedOrder(data)
        handleOpen();
    }

    //onload
    useEffect(() => {
        getAllHistory(limit, skip)
    }, [limit, skip])

    //getAllHistory
    const getAllHistory = async (lim: number, sk: number) => {
        setIsFetching(true)
        try {
            const response = await BaseService.get_api(`${global_variables.get_all_trips}?filter=[{"completed": true}]&limit=${lim}&skip=${sk}`)
            console.log(response?.data)
            setAllScehdules(response?.data?.payload)
            setTotal(response?.data?.total)
            setIsFetching(false)
        } catch (error) {
            console.log(error)
        }
    }


    // Define the columns for the table
    const columns = [
        {
            name: "Pickup",
            cell: (row: any) => row?.pickup?.place,
        },
        {
            name: "Destination",
            cell: (row: any) => row?.destination?.place,
        },
        {
            name: "Item Description",
            cell: (row: any) => row?.itemDescription || "No description",
            width: "20%"
        },
        {
            name: "Qty",
            selector: (row: any) => row?.quantity,
        },
        {
            name: "Weight",
            selector: (row: any) => `${row?.weight} ${row?.weightUnit}`,
        },
        {
            name: "Amount Paid",
            selector: (row: TripModel) => `${row?.countryData?.[0]?.currencyCode} ${row?.finalAmountMetrics?.amount}`,
        },
        {
            name: "Date",
            cell: (row: any) => moment(row?.createdAt).format("Do MMM YYYY - hh:mm A"),
        },
        {
            name: "Action",
            selector: (row: any) => <div className='flex gap-4'>
                <EyeIcon className='h-5 w-5 cursor-pointer' title='View Order Details' onClick={() => handleViewDetails(row)} />
            </div>
        }
    ];

    // pagination start
    const handleRowChange = (a: any) => {
        setLimit(a);
        getAllHistory(a, skip);
    }

    const pageChange = (a: any) => {
        if (a === 1) {
            getAllHistory(limit, 0);
            setSkip(0)
        } else {
            const newSkip = (a * limit) - limit;
            getAllHistory(limit, newSkip);
            setSkip(newSkip);
        }
    }
    // pagination end

    return (
        <>
            <div className="h-screen overflow-hidden">
                <div className="flex h-full">
                    {/* sidebar */}
                    <CustomSideBar historyActive="active" />
                    {/* navbar */}
                    <div className="w-full">
                        <CustomNavbar />
                        <div className="h-screen bg-[#1e7e7108] p-[20px] overflow-y-auto">
                            <div className='flex justify-between items-center'>
                                <p className="font-semibold text-lg">Delivery History</p>

                            </div>


                            <div className='mt-5 bg-white shadow-lg rounded-[20px] p-5 mb-20'>
                                <DataTable
                                    columns={columns}
                                    data={allScehdules}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={total}
                                    progressPending={isFetching}
                                    onChangeRowsPerPage={handleRowChange}
                                    onChangePage={pageChange}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>



            {openDrawer ? (
                <ScheduleDetailsDrawer
                    isOpened={openDrawer}
                    closeDrawer={() => setOpenDrawer(false)}
                    deliveryData={selectedOrder}
                />
            ) : (
                <></>
            )}
        </>
    )
}

export default OrderHistory
