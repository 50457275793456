import SignUpForm from "./signup-form";

const SignUp = () => {
  //https://cdn.pixabay.com/photo/2015/02/02/11/09/office-620822_1280.jpg
  return (
    <>
      <div className="h-screen overflow-hidden lg:grid lg:grid-cols-2 w-full">
        {/* left */}
        <div className="col-span-1 w-full h-full hidden lg:block">
          <img
            className="object-cover h-full w-full rounded-r-[100px]"
            src="https://images.unsplash.com/photo-1460925895917-afdab827c52f?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZGFzaGJvYXJkJTIwY29tcHV0ZXJ8ZW58MHx8MHx8fDA%3D"
            alt="sigin"
          />
        </div>

        {/* right */}
        <div className="col-span-1 w-full h-full overflow-y-auto">
          <div className="flex justify-center items-center w-full h-full">
            <SignUpForm />
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
