import { useEffect, useState } from "react";
import { Avatar, Drawer } from "@material-tailwind/react";
import socketConnection from "../../sockets/connection";
import { StorageBox } from "../../helpers/stroage";
import noRiderimg from "../../images/noRiderImg.svg"
import { TripAcceptedData } from "../request/tripAccepted.model";
import { DeleteTripFunction } from "../request/functions/createTrip";
import LoadingComponent from "../request/loadingComponent";
import ShowAlert from "../../components/alerts/all_alerts";
import {
    CloseOutlined,
} from '@ant-design/icons';

interface drawerProps {
    isOpened: boolean
    handleClose: () => void
    data: any
}

const OrderRequestDrawer = ({ isOpened, handleClose, data }: drawerProps) => {

    //const socketChannel = StorageBox.getSocketChannel()
    const [tripStep, setTripStep] = useState(0)
    const [isCreatingRequest, setIsCreatingRequest] = useState(true)



    const [findingRider, setFindingRider] = useState(false)

    //found rider
    const [foundRiderData, setFoundRiderData] = useState<TripAcceptedData>()
    const [foundRiderMsg, setFoundRiderMsg] = useState("")
    const [foundRiderStatus, setFoundRiderStatus] = useState<any>(null)
    const [starter, setStarter] = useState(true);



    useEffect(() => {
        //check if there is an ongoing request
        // socketConnection.socket.emit(`${StorageBox.getSocketChannel()}/core/request/user-once`, {
        //     "tripId": data?._id,
        // });
        socketConnection.addListener(`/core/request/user-once`, {
            "tripId": data?._id,
        }, false, "takeschannel");

        socketConnection.addListener(`tripRequest/${data?._id}`, null, true, null);

        socketConnection.addListener(`${data?._id}/events`, null, true,null);

        const connectionSubscription = socketConnection.listener$.subscribe((response) => {
            if (response['listener'] === `tripRequest/${data?._id}`) {

                const findDriverRes = response['data'];

                //no ongoing request
                if (findDriverRes == null) {
                    setStarter(true);
                    setFoundRiderMsg('Send request to our riders closer to your pickup location')
                    setTripStep(0);
                    setIsCreatingRequest(false);
                    return;
                }


                console.log("findDriverRes:", findDriverRes);
                setFindingRider(false)
                setFoundRiderStatus(findDriverRes?.success)
                //check if rider was found
                if (findDriverRes?.success === false) {
                    setTripStep(0);
                    setIsCreatingRequest(false);
                    setFoundRiderData(findDriverRes?.payload)
                    setFoundRiderMsg(findDriverRes?.message)
                }
                else {
                    setTripStep(1);
                    setIsCreatingRequest(false);
                    setFoundRiderData(findDriverRes?.payload)
                    setFoundRiderMsg(findDriverRes?.message)
                }
            }

            if(response['listener']===`${data['_id']}/events`){
                const eventsRes = response['data'];

                console.log("eventsRes:", eventsRes);
                setFoundRiderMsg(eventsRes?.payload?.current_event?.name)
            }
        });

        // socketConnection.socket.on(`tripRequest/${data?._id}`, (findDriverRes) => {
        //     //no ongoing request
        //     if (findDriverRes == null) {
        //         setStarter(true);
        //         setFoundRiderMsg('Send request to our riders closer to your pickup location')
        //         setTripStep(0);
        //         setIsCreatingRequest(false);
        //         return;
        //     }


        //     console.log("findDriverRes:", findDriverRes);
        //     setFindingRider(false)
        //     setFoundRiderStatus(findDriverRes?.success)
        //     //check if rider was found
        //     if (findDriverRes?.success === false) {
        //         setTripStep(0);
        //         setIsCreatingRequest(false);
        //         setFoundRiderData(findDriverRes?.payload)
        //         setFoundRiderMsg(findDriverRes?.message)
        //     }
        //     else {
        //         setTripStep(1);
        //         setIsCreatingRequest(false);
        //         setFoundRiderData(findDriverRes?.payload)
        //         setFoundRiderMsg(findDriverRes?.message)
        //     }

        // });

        //listen to trip events
        // socketConnection.socket.on(`${data?._id}/events`, (eventsRes) => {

        // });

        return () => {
            //unsubscribe connections
            connectionSubscription.unsubscribe();
            //stop socket listeners
            stopListeners();
        }

    }, [])

    const stopListeners = () => {
        //stop trip request listener
        socketConnection.removeListener(`tripRequest/${data?._id}`);
        //stop event listener
        socketConnection.removeListener(`${data?._id}/events`);
        
        //remove all listeners
        socketConnection.refresh()
    }

    //handling try again
    const tryAgainMethod = () => {
        setStarter(false);

        const findDriverData = {
            "tripId": data?._id,
            "longitude": data?.pickup.longitude,
            "latitude": data?.pickup.latitude,
            "country": StorageBox.getSavedCountry()?._id
        };

        socketConnection.addListener(`/core/request`, findDriverData, false,"takeschannel");

    }

    //peroformDeleteTrip
    const peroformDeleteTrip = async (tripId: any) => {

        const action = await ShowAlert.question_alert('Delete Delivery', 'Would you like to delete delivery? Please note that this can be undone', 'Delete Delivery');
        if (action.isConfirmed) {
            let delBody = JSON.stringify({
                "_id": tripId
            })

            DeleteTripFunction(delBody)

            handleClose()
        }

    }

    return (
        <>
            <Drawer
                size={700}
                placement="right"
                open={isOpened}
                className="py-4"
            >
                <div className="mb-4 flex items-center justify-between px-7">
                    <div className="flex justify-between items-center w-full">
                        <p className="font-semibold text-xl">Request For PickUp</p>
                        <button onClick={handleClose}>
                            <CloseOutlined />
                        </button>
                    </div>
                </div>

                <hr className="my-3" />

                {
                    isCreatingRequest ?
                        <div className="flex flex-col justify-center items-center h-full">
                            <LoadingComponent />
                            <p className="mt-2">Please wait...</p>
                        </div>

                        : tripStep === 0 ?
                            // step 0
                            <>
                                {

                                    <>
                                        <div className="mx-5 flex flex-col items-center justify-center h-full mb-10">
                                            <p className="text-center text-lg bg-red-400 text-white py-1 px-3 mb-4">{foundRiderMsg}</p>
                                            <img src={noRiderimg} alt="noRider" className="w-[400px]" />

                                            <div className="mt-8 w-full flex gap-3">
                                                <button onClick={() => peroformDeleteTrip(foundRiderData?.trip_details?._id)} className="h-[40px] w-full bg-gradient-to-r from-red-300 to-red-500 text-white shadow-lg rounded-[30px]">Delete Request</button>
                                                <button onClick={() => tryAgainMethod()} className="h-[40px] w-full bg-gradient-to-r from-green-400 to-green-600 text-white shadow-lg rounded-[30px]">{starter ? "Make Request" : "Try Again"}</button>
                                            </div>
                                        </div>
                                    </>
                                }
                            </>

                            :
                            <div className="h-full overflow-y-auto pb-20">
                                {/* finding driver */}
                                {
                                    findingRider ?
                                        <>
                                            <div className="flex flex-col justify-center items-center h-full">
                                                <LoadingComponent />
                                                <p className="mt-2">Finding a rider...</p>
                                            </div>
                                        </>
                                        :
                                        // if rider is found or not
                                        <>
                                            {
                                                foundRiderStatus === true ?
                                                    <>
                                                        <div className="mx-5 flex justify-center flex-col">
                                                            <div className="flex justify-center flex-col">
                                                                <p className="text-center bg-lightGreen text-white py-1 mb-3">{foundRiderMsg}</p>

                                                                <Avatar className="flex mr-auto ml-auto" size="lg" src={foundRiderData?.driver_data?.image || "https://www.nicepng.com/png/detail/136-1366211_group-of-10-guys-login-user-icon-png.png"} />
                                                                <p className="text-center">{foundRiderData?.driver_data?.fullName}</p>
                                                                <p className="text-center">{foundRiderData?.driver_data?.phone}</p>
                                                            </div>

                                                            <hr className="my-4" />

                                                            <div>
                                                                <div>
                                                                    <p className="font-semibold">Pick Up:</p>
                                                                    <p>{foundRiderData?.trip_details?.pickup?.place}</p>
                                                                </div>
                                                                <div className="mt-3">
                                                                    <p className="font-semibold">Destination:</p>
                                                                    <p>{foundRiderData?.trip_details?.destination?.place}</p>
                                                                </div>
                                                            </div>

                                                            <hr className="my-4" />

                                                            <div>
                                                                <Avatar className="flex mr-auto ml-auto" size="lg" src={foundRiderData?.car_data?.vehicleFront || "https://www.nicepng.com/png/detail/136-1366211_group-of-10-guys-login-user-icon-png.png"} />
                                                                <p className="text-center">{foundRiderData?.car_data?.registeration}</p>
                                                                <p className="text-center">{foundRiderData?.car_data?.brandData?.[0]?.brand}, {foundRiderData?.car_data?.vehicleColor}</p>
                                                            </div>

                                                            <div className="mt-8 flex gap-4 items-center">
                                                                {foundRiderMsg === "Trip Started" ?
                                                                    <button onClick={handleClose} className="h-[40px] w-full bg-gradient-to-r from-lightGreen to-midGreen text-white shadow-lg rounded-[30px]">View Order</button>
                                                                    :
                                                                    <></>
                                                                }
                                                                <button onClick={() => peroformDeleteTrip(foundRiderData?.trip_details?._id)} className="h-[40px] w-full bg-gradient-to-r from-red-300 to-red-500 text-white shadow-lg rounded-[30px]">Cancel</button>
                                                            </div>

                                                            {/* dummy try again */}
                                                            {/* <div className="mt-8 flex gap-4 items-center">
                                  <button onClick={() => handleOnContinue()} className="h-[40px] w-full bg-gradient-to-r from-red-300 to-red-500 text-white shadow-lg rounded-[30px]">Try Again</button>
                                </div> */}
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="mx-5 flex flex-col items-center justify-center h-full mb-10">
                                                            <p className="text-center text-lg bg-red-400 text-white py-1 px-3 mb-4">{foundRiderMsg}</p>
                                                            <img src={noRiderimg} alt="noRider" className="" />

                                                            <div className="mt-8 w-full flex gap-3">
                                                                <button onClick={() => peroformDeleteTrip(foundRiderData?.trip_details?._id)} className="h-[40px] w-full bg-gradient-to-r from-red-300 to-red-500 text-white shadow-lg rounded-[30px]">Cancel</button>
                                                                <button onClick={() => tryAgainMethod()} className="h-[40px] w-full bg-gradient-to-r from-yellow-400 to-yellow-600 text-white shadow-lg rounded-[30px]">Try Again</button>
                                                            </div>
                                                        </div>
                                                    </>
                                            }
                                        </>
                                }
                            </div>

                }

                {/* content */}
            </Drawer>
        </>
    );
};

export default OrderRequestDrawer;
