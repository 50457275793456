import BaseService from "../../../helpers/baseServices";
import { global_variables } from "../../../helpers/globalVariables";

/*steps to create trip
# hit google directions endpoint for distance and time
# get Initial price
# create trip
# apply initial price
# hit socket to search rider
*/

export const GetDirectionsFunction = async (theBody: any) => {
  try {
    const response = await BaseService.put_api(
      global_variables.get_directions,
      theBody
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const CreateInitialPriceFuntion = async (theBody: any) => {
  try {
    const response = await BaseService.post_api(
      global_variables.create_initial_price,
      theBody
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const CreateTripFunction = async (theBody: any) => {
  try {
    const response = await BaseService.post_api(
      global_variables.create_trip,
      theBody
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const ApplyInitialPriceFuntion = async (theBody: any) => {
  try {
    const response = await BaseService.put_api(
      global_variables.apply_initial_price,
      theBody
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const DeleteTripFunction = async (theBody: any) => {
  try {
    const response = await BaseService.delete_api(
      global_variables.delete_trip,
      theBody
    );
    console.log("delTripRes:", response?.data);
    return response;
  } catch (error) {
    console.log("delTripError:", error);
    return error;
  }
};

export const CheckTripPrice = async (theBody: any) => {
  try {
    const response = await BaseService.post_api(
      global_variables.check_price,
      theBody
    );
    return response;
  } catch (error) {
    return error;
  }
};
