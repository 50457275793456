import { useEffect, useState } from 'react'
import { TrashIcon } from '@heroicons/react/24/outline'
import moment from 'moment'
import DataTable from 'react-data-table-component'
import BaseService from '../../helpers/baseServices'
import { global_variables } from '../../helpers/globalVariables'
import CustomSideBar from '../../components/layouts/sidebar'
import CustomNavbar from '../../components/layouts/navbar'
import SaveNewRider from './saveNewRider'
import { RiderData } from './rider.model'
import ShowAlert from '../../components/alerts/all_alerts'
import ShowToast from '../../components/alerts/all_toasts'

const RiderManagement = () => {

    const [reFetch, setReFetch] = useState(false)
    const [isFetching, setIsFetching] = useState(false)
    const [allRiders, setAllRiders] = useState([])
    const [total, setTotal] = useState(0)
    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(10)

    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }

    //onload
    useEffect(() => {
        getAllRiders(limit, skip)
    }, [reFetch, limit, skip])

    //getAllRiders
    const getAllRiders = async (lim: number, sk: number) => {
        setIsFetching(true)
        try {
            const response = await BaseService.get_api(`${global_variables.get_all_saved_riders}?limit=${lim}&skip=${sk}`)
            console.log(response?.data)
            setAllRiders(response?.data?.payload)
            setTotal(response?.data?.total)
            setIsFetching(false)
        } catch (error) {
            console.log(error)
        }
    }

    //handleDeleteRider
    const handleDeleteRider = (data: RiderData) => {
        console.log(data?._id)

        const delData = JSON.stringify(
            {
                "_id": data?._id
            }
        )

        ShowAlert.question_alert('Confirm', `Do you want to delete ${data?.driverData?.[0]?.fullName} from your saved riders?`, 'Delete').then((result) => {
            if (result.isConfirmed) {
                BaseService.delete_api(`${global_variables.save_rider}`, delData)
                    .then((response) => {
                        console.log(response)
                        ShowToast.success_toast(response?.data?.message)
                        handleReFtech()
                    })
                    .catch((error) => {
                        console.log(error)
                        ShowToast.error_toast(error?.response?.data?.error)
                    })
            }
        })
    }


    // Define the columns for the table
    const columns: any = [
        {
            name: "#",
            selector: (row: any, index: number) => index + 1,
        },
        {
            name: "Full Name",
            selector: (row: any) => row?.driverData?.[0]?.fullName,
        },
        {
            name: "Phone Number",
            selector: (row: any) => row?.driverData?.[0]?.phone,
        },
        {
            name: "Date Saved",
            selector: (row: any) => moment(row?.createdAt).format("Do MMM YYYY - hh:mm A"),
        },
        {
            name: "Action",
            selector: (row: any) => <div>
                <TrashIcon className='h-5 w-5 cursor-pointer text-red-500' title='Delete saved rider' onClick={() => handleDeleteRider(row)} />
            </div>,
        },
    ];

    // pagination start
    const handleRowChange = (a: any) => {
        setLimit(a);
        getAllRiders(a, skip);
    }

    const pageChange = (a: any) => {
        if (a === 1) {
            getAllRiders(limit, 0);
            setSkip(0)
        } else {
            const newSkip = (a * limit) - limit;
            getAllRiders(limit, newSkip);
            setSkip(newSkip);
        }
    }
    // pagination end

    //handleReFtech
    const handleReFtech = () => { setReFetch(!reFetch) }

    return (
        <>
            <div className="h-screen overflow-hidden">
                <div className="flex h-full">
                    {/* sidebar */}
                    <CustomSideBar riderActive="active" />
                    {/* navbar */}
                    <div className="w-full">
                        <CustomNavbar />
                        <div className="h-screen bg-[#1e7e7108] p-[20px] overflow-y-auto">
                            <p className="font-semibold text-lg">Rider Management</p>


                            <div className='p-5 rounded-[20px] bg-white shadow-lg mt-5'>
                                <div className='flex justify-end'>
                                    <button className='bg-gradient-to-r from-lightGreen to-midGreen h-[40px] px-4 rounded-[5px] text-white shadow-lg' onClick={() => handleOpen()} >
                                        + Add New
                                    </button>
                                </div>
                                <DataTable
                                    columns={columns}
                                    data={allRiders}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={total}
                                    progressPending={isFetching}
                                    onChangeRowsPerPage={handleRowChange}
                                    onChangePage={pageChange}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>


            {/* open modal */}
            {
                open ? <SaveNewRider openModal={open} handleOpenModal={handleOpen} handleReFetch={handleReFtech} handleCloseModal={handleClose} />
                    :
                    <></>
            }
        </>
    )

}

export default RiderManagement
