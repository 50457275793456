import { Fragment, useEffect, useState } from "react";
import {
  Drawer,
  IconButton,
} from "@material-tailwind/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import ShippingInfoTab from "./tabs/shippingInfo";
import LiveChat from "./tabs/liveChat/liveChat";
import { TripModel } from "./trips.model";
import RiderTracking from "./tabs/riderTracking/riderTracking";
import socketConnection from "../../sockets/connection";
interface drawerProps {
  isOpened: boolean;
  closeDrawer: () => void;
  deliveryData: TripModel
}

const LiveDeliveryDrawer = ({ isOpened, closeDrawer, deliveryData }: drawerProps) => {
  const [activeTab, setActiveTab] = useState(0);
  const [currStatus, setCurrStatus] = useState("")


  const tabNames = ["Delivery Info", "Tracking", "Live Chat"];
  const tabComponents = [
    <ShippingInfoTab shippingData={deliveryData} />,
    <RiderTracking tripData={deliveryData} />,
    <LiveChat shippingData={deliveryData} />,
  ];

  useEffect(() => {
    socketConnection.socket.on(`${deliveryData?._id}/events`, (eventsRes) => {
      console.log("eventsRes:", eventsRes);
      setCurrStatus(eventsRes?.payload?.current_event?.name)
    })
  }, [])

  return (
    <Fragment>
      <Drawer
        size={900}
        placement="right"
        open={isOpened}
        className="py-4"
      >
        <div className="mb-4 flex items-center justify-between px-7">
          <div className="flex items-center gap-3">
            <p className="font-semibold text-xl">Delivery to {deliveryData?.destination?.place}</p>
            <p className="font-semibold text-midGreen">{`(${deliveryData?.currentStatus?.name})`}</p>
          </div>
          <IconButton variant="text" color="blue-gray" onClick={closeDrawer}>
            <XMarkIcon strokeWidth={2} className="h-5 w-5" />
          </IconButton>
        </div>
        {/* tabs */}
        <div className="mt-2 flex items-center gap-5 px-7">
          {tabNames.map((tab, i) => (
            <div
              key={i}
              className={`${activeTab === i ? "border-b-[2px] border-b-midGreen" : ""
                } cursor-pointer pb-1`}
              onClick={() => setActiveTab(i)}
            >
              <p>{tab}</p>
            </div>
          ))}
        </div>
        <hr />
        <div className="mt-4 overflow-y-auto h-full pb-[100px] px-7">
          {tabComponents[activeTab]}
        </div>
      </Drawer>
    </Fragment>
  );
};

export default LiveDeliveryDrawer;
