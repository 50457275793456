import CustomSideBar from "../../components/layouts/sidebar";
import CustomNavbar from "../../components/layouts/navbar";
import OrdersLimited from "./ordersLimited";
import SchedulesLimited from "./schedulesLimited";
import AllTimeOrders from "./allTimeOrders";
import LiveOrders from "./liveOrders";
import TotalSchedules from "./totalSchedules";
import CompletedOrders from "./completedOrders";

const Dashboard = () => {


  return (
    <div className="h-screen overflow-hidden">
      <div className="flex h-full">
        {/* sidebar */}
        <CustomSideBar dashActive="active" />
        {/* navbar */}
        <div className="w-full">
          <CustomNavbar />
          <div className="h-screen bg-[#1e7e7108] p-[20px] overflow-y-auto">
            <p className="font-semibold text-lg">Dashboard</p>

            {/* section one */}
            <div className="lg:grid lg:grid-cols-4 mt-3 gap-4">
              <LiveOrders />

              <TotalSchedules />

              <CompletedOrders />

              <AllTimeOrders />
            </div>

            {/* section two */}
            <div className="mt-5">
              <OrdersLimited />
            </div>

            <div className="mt-5 mb-20">
              <SchedulesLimited />
            </div>

          </div>

        </div>
      </div>
    </div>
  );
};

export default Dashboard;
