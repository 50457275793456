import Lottie from "lottie-react";
import pendingImg from "../animations/pending.json"



const PendingApproval = () => {


    return (
        <>
            <div className='h-screen overflow-hidden'>
                <div className='flex justify-center items-center h-full'>
                    <div>
                        <div className='w-[500px]'>
                            <Lottie animationData={pendingImg} loop={true} />
                        </div>
                        <p className='text-2xl text-center mb-0'>Your Account Is Pending Approval</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PendingApproval
