import { Fragment, useState, useEffect } from "react";
import { Dialog, DialogBody, Spinner } from "@material-tailwind/react";
import verifyImg from "../../../../images/verifyImg.svg";
import axios from "axios";
import { BASE_URL } from "../../../../helpers/constants";
import { global_variables } from "../../../../helpers/globalVariables";
import { StorageBox } from "../../../../helpers/stroage";
import ShowAlert from "../../../../components/alerts/all_alerts";
import PinInput from "react-pin-input";
import { useNavigate } from "react-router-dom";

interface modalProps {
  openModal: boolean;
  handleOpenModal: () => void;
  vToken: any;
}

const InputPinModal = ({ openModal, handleOpenModal, vToken }: modalProps) => {
  const [isVerifying, setIsVerifying] = useState(false);
  const [verifyToken, setVerifyToken] = useState("");
  const [inputPin, setInputPin] = useState("");
  const navigate = useNavigate()

  useEffect(() => {
    setVerifyToken(vToken);
  }, [vToken]);

  // runVerification
  const runVerification = (value: any) => {
    setIsVerifying(true);
    console.log(value);
    const data = JSON.stringify({
      token: value,
    });

    localStorage.setItem("otp", inputPin)

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${BASE_URL}${global_variables.verifyTokenRoute}`,
      headers: {
        "Content-Type": "application/json",
        "x-access-token": verifyToken,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        StorageBox.saveUserData(response?.data?.payload);
        StorageBox.saveAccessToken(response?.data?.token);
        navigate("/companyDetails")
      })
      .catch((error: any) => {
        console.log(error);
        ShowAlert.error_alert("Error", error?.response?.data?.error, "Retry");
        setIsVerifying(false);
      });
  };

  return (
    <Fragment>
      <Dialog open={openModal} handler={handleOpenModal} size="lg">
        <DialogBody>
          <div className="lg:grid lg:grid-cols-2">
            <div className="hidden lg:block">
              <img className="" src={verifyImg} alt="verify" />
            </div>
            <div className="p-[20px] flex justify-center items-center flex-col">
              <p className="text-center">
                We have sent a 5 digits pin to your email, enter it below and
                click confirm.
              </p>

              <div className="mt-3">
                <PinInput
                  length={5}
                  initialValue=""
                  secret
                  secretDelay={100}
                  onChange={(value, index) => setInputPin(value)}
                  type="numeric"
                  inputMode="number"
                  style={{ padding: "10px" }}
                  inputStyle={{ borderColor: "red" }}
                  inputFocusStyle={{ borderColor: "#165b4f" }}
                  onComplete={(value, index) => runVerification(value)}
                  autoSelect={true}
                  disabled={isVerifying}
                />
              </div>

              {isVerifying ? (
                <div className="mt-3">
                  <Spinner color="green" />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </DialogBody>
      </Dialog>
    </Fragment>
  );
};

export default InputPinModal;
