import "./App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import SignIn from "./pages/authentication/screens/signin";
import Dashboard from "./pages/dashboard/dashboard";
import NewPwd from "./pages/authentication/screens/newPwd";
import VerifyToken from "./pages/authentication/screens/verifyToken";
import ProtectedRoute from "./HOC/ProtectedRoute";
import SignUp from "./pages/authentication/screens/signup/signup";
import ProtectCompany from "./HOC/ProtectCompany";
import CompanyDetails from "./pages/authentication/createCompnayDetails/companyDetails";
import MyProfile from "./pages/myProfile/myProfile";
import EditCompanyDetails from "./pages/myProfile/editCompanyDetails";
import Operations from "./pages/operations/operations";
import Schedules from "./pages/schedules/schedules";
import AllOrders from "./pages/orderMgt/orders";
import RiderManagement from "./pages/riderMgt/allRiders";
import OrderHistory from "./pages/orderHistory/orderHistory";
import MyWallet from "./pages/myWallet/myWallet";
import PendingApproval from "./pages/pendingApproval";
import { useEffect, useState } from "react";
import socketConnection from "./sockets/connection";
import { connectionEnum } from "./sockets/connection.model";
import { Alert } from "antd";
import SideMenuContext from "./context/GeneralContext";

function App() {

  const [connection, setConnection] = useState(connectionEnum.connected)
  const [hideAlert, setHideAlert] = useState(false);
  const [alertDisplay, setAlertDisplay] = useState("Connecting ...")

  const switcherName = (value: any) => {
    switch (value) {
      case "Connected to the internet":
        return "success";
      case "Connection lost":
        return "error"
      default:
        return "warning"
    }
  }

  useEffect(() => {

    const connectionSubscription = socketConnection.listener$.subscribe((response) => {
      setHideAlert(false);
      if (response['listener'] == "connection") {
        setConnection(response.data.stage)
      }
    });

    return () => {
      //stop subscriptions
      connectionSubscription.unsubscribe();
    }
  }, [])

  useEffect(() => {
    switch (connection) {
      case connectionEnum.connected:
        setAlertDisplay('Connected to the internet')
        break;

      case connectionEnum.disconnected:
        setAlertDisplay('Connection lost')
        break;

      case connectionEnum.reconnecting:
        setAlertDisplay('Internet is not stable, reconnecting now')
        break;
    }

    if (connection === connectionEnum.connected) {
      setTimeout(() => {
        setHideAlert(true);
      }, 5000)
    }
  }, [connection])

  //context action
  const [triggerMenu, setTriggerMenu] = useState(false)

  const handleMenuClick = () => {
    setTriggerMenu(!triggerMenu)
  };


  return (
    <>
      <SideMenuContext.Provider value={{ triggerMenu, handleMenuClick }}>
        <div className="relative">
          <Router>
            <Routes>
              <Route path="/*" element={<h4>Page not found!</h4>} />

              {/* auth routes */}
              <Route
                path="/"
                element={
                  <SignIn />
                }
              />
              <Route
                path="/pending"
                element={
                  <PendingApproval />
                }
              />
              <Route
                path="/signup"
                element={
                  <SignUp />
                }
              />
              <Route
                path="/newPwd"
                element={
                  <NewPwd />
                }
              />
              <Route
                path="/verifyToken"
                element={
                  <VerifyToken />
                }
              />
              <Route
                path="/companyDetails"
                element={
                  <ProtectCompany><CompanyDetails /></ProtectCompany>
                }
              />

              {/* page routes */}
              <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
              <Route path="/myprofile" element={<ProtectedRoute><MyProfile /></ProtectedRoute>} />
              <Route path="/myCompany" element={<ProtectedRoute><EditCompanyDetails /></ProtectedRoute>} />
              <Route path="/operations" element={<ProtectedRoute><Operations /></ProtectedRoute>} />
              <Route path="/schedules" element={<ProtectedRoute><Schedules /></ProtectedRoute>} />
              <Route path="/orders" element={<ProtectedRoute><AllOrders /></ProtectedRoute>} />
              <Route path="/riderMgt" element={<ProtectedRoute><RiderManagement /></ProtectedRoute>} />
              <Route path="/orderHistory" element={<ProtectedRoute><OrderHistory /></ProtectedRoute>} />
              {/* <Route path="/wallet" element={<ProtectedRoute><MyWallet /></ProtectedRoute>} /> */}
            </Routes>
          </Router>
          {/* loading alert */}
          <div className="fixed bottom-5 z-50 right-5">

            {hideAlert ? <></> : <Alert message={alertDisplay} type={switcherName(alertDisplay) as any ?? 'warning' as any} showIcon closable />}
          </div>
        </div>
      </SideMenuContext.Provider>
    </>
  );
}

export default App;
