import io from "socket.io-client";
import { BASE_URL } from "../helpers/constants";
import { StorageBox } from "../helpers/stroage";
import { Subject } from 'rxjs';
import ShowAlert from "../components/alerts/all_alerts";

const socketConnection = (function () {
  
  let socket = io(`${BASE_URL}/muuvexpress-realtime`,{
    reconnection: true,
    reconnectionDelay: 5000,
    reconnectionAttempts:10,
    autoConnect:true,
  });
  
  const listenerSubject = new Subject<any>();

  const handshake = () => {
    const initChannel = "1";
    const handShakeParams = {
      token: StorageBox.getAccessToken(),
      initial_channel: initChannel,
    };

    socket.on("connect", () => {
      console.log("Connected to socket");

      if(StorageBox.getAccessToken()!=null){
        socket.emit("/muuvexpress/handshake", handShakeParams);
      }
    });

    // Handle the handshake response here
    socket.on(initChannel, (response: any) => {
      if (response?.success) {
        const main_channel = response?.payload?.main_channel;
        StorageBox.saveSocketChannel(main_channel);
        reloadActiveListener();
      }
    });

    //socket io events
    socket.io.on("reconnect_attempt", (data)=>{
      console.log('----- reconnect attempt', data);
    })

    socket.io.on("reconnect_error", (data)=>{
      console.log('----- reconnect error', data);
    })

    socket.io.on('reconnect_failed', ()=>{
      destroySocket();
    })
  };

  const removeListener = (listener:string) =>{
    socket.off(listener)
    StorageBox.removeListener(listener);
  }

  const addListener = (listener:string, data:any, stream:boolean, channel:string|null)=>{

    if(data==null){
      data = {};
    }
    data['stream'] = stream;
    data['channel']=channel;

    StorageBox.addListener(listener, data);
    delete data['stream'];
    delete data['channel'];

    console.log('the data',data)

    if(!stream && channel==null){
      console.log('------emitted 1',listener);
      socket.emit(listener, data);
      return;
    }

    if(!stream && channel!==null){
      console.log('------emitted 4',`${StorageBox.getSocketChannel()}${listener}`,data, socket.active);
      socket.emit(`${StorageBox.getSocketChannel()}${listener}`, data);
      // socket.on(`${StorageBox.getSocketChannel()}${listener}`, (data)=>{
      //   console.log('----- the emitter',data )
      // });
      return;
    }

    

    if(stream && channel!=null){
      //socket.off(`${StorageBox.getSocketChannel()}/${listener}`);
      console.log('------emitted 2',`${StorageBox.getSocketChannel()}${listener}`, data);
      socket.once(`${StorageBox.getSocketChannel()}${listener}`, (data)=>{
        let theData = {
          listener,
          data
        };
        console.log('----data', theData);
        listenerSubject.next(theData)
      })
      return;
    }

    if(stream && channel===null){
      //socket.off(`${listener}`);
      console.log('------emitted 3',listener);
      socket.on(`${listener}`, (data)=>{

        let theData = {
          listener,
          data
        };
        console.log('----data 2', theData);
        listenerSubject.next(theData)
      })
      return;
    }
  }

  const reloadActiveListener = ()=>{
    console.log('----- reload listeners');
    const listeners = StorageBox.getListenerList();
    console.log('----- all listeners', listeners);
    for (const listener of listeners){
      //get the stored data
      const data = StorageBox.getListenerData(listener);
      const stream = data['stream'];
      const channel = data['channel'];

      delete data['stream'];
      delete data['channel'];

      addListener(listener, data, stream, channel);
    }
  }

  const refresh = () =>{
    const listeners = StorageBox.getListenerList();
    for (const listener of listeners){
      //get the stored data
      const data = StorageBox.getListenerData(listener);
      const stream = data['stream'];
      const channel = data['channel'];

      if(stream==true && channel==null){
        socket.off(listener);
      }

      if(stream==true && channel!=null){
        socket.off(`${StorageBox.getSocketChannel()}${listener}`);
      }
    }
    StorageBox.clearListenerList();
  }

  const destroySocket = async ()=>{
    socket.disconnect();
    refresh();
    //display alert
    const response = await ShowAlert.question_alert('Connection Lost','You have lost internet connection, check your connection and try again','try again');
    if(response.isConfirmed){
      window.location.reload()
    }
  }

  const checkSocketConnection = (computation: () => void) => {
    if (socket.active) {
      computation();
    }
  };

  return {
    socket,
    handshake,
    checkSocketConnection,
    removeListener,
    addListener,
    refresh,
    listener$: listenerSubject.asObservable(),
  };
})();

export default socketConnection;
