import { ChevronLeftIcon, CloudArrowUpIcon, PhotoIcon } from '@heroicons/react/24/outline';
import { Input, Spinner, Typography } from '@material-tailwind/react';
import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import BaseService from '../../helpers/baseServices';
import { global_variables } from '../../helpers/globalVariables';
import ShowToast from '../../components/alerts/all_toasts';

const MyProfile = () => {
    const navigate = useNavigate();
    const [isBusy, setIsBusy] = useState(false)
    const [isFetching, setIsFetching] = useState(false)
    const [selectedImage, setSelectedImage] = useState<any>(null)
    const [userData, setUserData] = useState<any>(null)
    const [theFile, setTheFile] = useState<any>(null)
    const [fName, setFName] = useState("")
    const [lName, setLName] = useState("")
    const [mName, setMName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [reFetch, setReFetch] = useState(false)

    //onload
    useEffect(() => {
        fetchUserDeatils()
    }, [reFetch])

    //fetch user details
    const fetchUserDeatils = async () => {
        setIsFetching(true)
        try {
            const response = await BaseService.get_api(`${global_variables.get_user_details}`)
            // console.log(response?.data?.payload?.user_data)
            setUserData(response?.data?.payload?.user_data)
            setFName(response?.data?.payload?.user_data?.firstName)
            setMName(response?.data?.payload?.user_data?.middleName)
            setLName(response?.data?.payload?.user_data?.lastName)
            setPhone(response?.data?.payload?.user_data?.phone)
            setEmail(response?.data?.payload?.user_data?.email)
            setSelectedImage(response?.data?.payload?.user_data?.image)
            setIsFetching(false)
        } catch (error) {
            console.log(error)
            setIsFetching(false)
        }
    }

    //handle image slected
    const handleImageSelected = (e: any) => {
        const file = e.target.files[0];
        setTheFile(file)
        const imageUrl = URL.createObjectURL(file);
        setSelectedImage(imageUrl);
    };

    //hnadle update
    const handleUpdate = async () => {
        if (selectedImage === "" || selectedImage === null || selectedImage === undefined) {
            let data = JSON.stringify({
                "fullName": userData?.fullName,
                "firstName": fName,
                "middleName": mName,
                "lastName": lName,
            });
            setIsBusy(true);
            try {
                const response = await BaseService.put_api(`${global_variables.get_user_details}`, data)
                console.log(response)
                if (response.status === 200) {
                    ShowToast.success_toast(response?.data?.message);
                    setReFetch(!reFetch)
                }
                setIsBusy(false)
            } catch (error) {
                console.log(error)
                ShowToast.error_toast("Failed to update user details!")
                setIsBusy(false)
            }
        }
        else {
            try {
                setIsBusy(true)
                const response: any = await BaseService.file_upload(`${global_variables.file_upload}?imagename=${fName}&storage=testfolder`, theFile)
                console.log(response)
                if (response?.status === 200) {
                    let data = JSON.stringify({
                        "fullName": userData?.fullName,
                        "firstName": fName,
                        "middleName": mName,
                        "lastName": lName,
                        "image": response?.data?.url
                    });

                    try {
                        const postResponse: any = await BaseService.put_api(`${global_variables.get_user_details}`, data)
                        console.log(postResponse)
                        if (postResponse?.status === 200) {
                            ShowToast.success_toast(postResponse?.data?.message);
                            setIsBusy(false)
                            setReFetch(!reFetch)
                        }
                    } catch (error: any) {
                        console.log(error)
                        ShowToast.error_toast("Failed to update user details!")
                        setIsBusy(false)
                    }
                }
            } catch (error) {
                console.log(error)
                ShowToast.error_toast("Failed to update details! Change your profile picture and retry.")
                setIsBusy(false)
            }
        }
    }

    return (
        <Fragment>
            <div className="h-screen bg-[#1e7e711d] flex justify-center items-center lg:px-[200px] py-[50px] overflow-hidden">
                <div className="rounded-[20px] bg-white shadow-lg w-full h-full p-10 relative">
                    {/* top */}
                    <div className='flex justify-between items-center'>
                        <button className='flex gap-2 items-center bg-gradient-to-r from-lightGreen to-midGreen text-white px-4 py-2 rounded-[5px]' onClick={() => navigate("/dashboard")}>
                            <ChevronLeftIcon className='h-5 w-5' />
                            <p>Back</p>
                        </button>
                        <Typography className="font-semibold" variant="h4">Your Personal Information</Typography>
                        <div></div>
                    </div>

                    {/* main */}
                    <div className='mt-4'>
                        {/* image */}
                        <div className='flex justify-center items-center'>
                            <div>
                                <label htmlFor="chooseLogo">
                                    <div className='h-[150px] w-[150px] bg-midGreen bg-opacity-[7%] border-[1px] border-dashed border-midGreen flex flex-col justify-center items-center cursor-pointer rounded-full'>
                                        {
                                            selectedImage ? <img className='h-full w-full rounded-full object-cover' src={selectedImage} alt="selected" />
                                                :
                                                <>
                                                    <PhotoIcon className='h-10 w-10 text-midGreen' />
                                                    <p className='text-midGreen font-medium'>Upload Image</p>
                                                </>
                                        }
                                    </div>
                                </label>
                                <input type="file" id="chooseLogo" className='hidden' onChange={handleImageSelected} />
                            </div>
                        </div>

                        {/* form */}
                        <div className='lg:grid lg:grid-cols-2 lg:gap-4'>
                            <div className='mt-4'>
                                <small>Your first name</small>
                                <Input label="First Name" value={fName} onChange={(e) => setFName(e.target.value)} disabled={isBusy || isFetching} />
                            </div>
                            <div className='mt-4'>
                                <small>Your last name</small>
                                <Input label="Last Name" value={lName} onChange={(e) => setLName(e.target.value)} disabled={isBusy || isFetching} />
                            </div>
                            <div className='mt-2'>
                                <small>Your middle name</small>
                                <Input label="Middle Name (Optional)" value={mName} onChange={(e) => setMName(e.target.value)} disabled={isBusy || isFetching} />
                            </div>
                            <div className='mt-2'>
                                <small>Your email</small>
                                <Input label="Email" value={email} onChange={(e) => setEmail(e.target.value)} disabled />
                            </div>
                            <div className='mt-2'>
                                <small>Your phone number</small>
                                <Input label="Phone Number" value={phone} onChange={(e) => setPhone(e.target.value)} disabled />
                            </div>
                            {/* button */}
                            <div className='w-full mt-8'>
                                <button className='w-full h-[40px] bg-gradient-to-r from-lightGreen to-midGreen text-white rounded-[5px] flex gap-2 items-center justify-center' onClick={() => handleUpdate()} disabled={isBusy}>
                                    {
                                        isBusy ? <Spinner />
                                            :
                                            <>
                                                <CloudArrowUpIcon className='h-5 w-5' />
                                                <p>Update</p>
                                            </>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default MyProfile
