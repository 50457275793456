import { Navigate } from 'react-router-dom';
import { StorageBox } from '../helpers/stroage';
import { useEffect, useState } from 'react';
import { global_variables } from '../helpers/globalVariables';
import BaseService from '../helpers/baseServices';
import { Spinner } from '@material-tailwind/react';

const ProtectedRoute = ({ children }: any) => {

  const token = StorageBox.getAccessToken();
  const [isFetching, setIsFetching] = useState(true)
  const [companyDetails, setCompanyDetails] = useState(null)

  //onload
  useEffect(() => {
    fetchUserDeatils()
  }, [])

  //fetch user details
  const fetchUserDeatils = async () => {
    setIsFetching(true)
    try {
      const response = await BaseService.get_api(`${global_variables.get_user_details}`)
      console.log(response?.data?.payload)
      console.log('----company Data', response?.data?.payload?.company_data);
      setCompanyDetails(response?.data?.payload?.company_data)
      setIsFetching(false)
    } catch (error) {
      console.log(error)
      setIsFetching(false)
    }
  }

  if (token) {
    if (isFetching) {
      return <div className='flex justify-center items-center h-screen'>
        <Spinner className='h-10 w-10' />
      </div>
    }
    else if (companyDetails === null) {
      return <Navigate to="/companyDetails" />
    }
    else {
      return children;
    }
  }
  else {
    return <Navigate to="/" />
  }

}

export default ProtectedRoute;