import CryptoJS from "crypto-js";
import { CRYPTO_KEY } from "./constants";
const SECRET_KEY: any = CRYPTO_KEY;

export const StorageBox = {
  getAccessToken: () => {
    const encryptedData = localStorage.getItem("access_token");
    if (encryptedData !== null) {
      const decryptedData = CryptoJS.AES.decrypt(
        encryptedData,
        SECRET_KEY
      ).toString(CryptoJS.enc.Utf8);
      return decryptedData;
    }
    return null;
  },
  saveAccessToken: (token: any) => {
    const encryptedData = CryptoJS.AES.encrypt(token, SECRET_KEY).toString();
    localStorage.setItem("access_token", encryptedData);
  },
  getTempToken: () => {
    const encryptedData = localStorage.getItem("temp_token");
    if (encryptedData !== null) {
      const decryptedData = CryptoJS.AES.decrypt(
        encryptedData,
        SECRET_KEY
      ).toString(CryptoJS.enc.Utf8);
      return decryptedData;
    }
    return null;
  },
  saveTempToken: (token: any) => {
    const encryptedData = CryptoJS.AES.encrypt(token, SECRET_KEY).toString();
    localStorage.setItem("temp_token", encryptedData);
  },
  saveUserData: (data: any) => {
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      SECRET_KEY
    ).toString();
    localStorage.setItem("userData", encryptedData);
  },
  retrieveUserData: () => {
    const encryptedData = localStorage.getItem("userData");
    if (encryptedData !== null) {
      const decryptedData = JSON.parse(
        CryptoJS.AES.decrypt(encryptedData, SECRET_KEY).toString(
          CryptoJS.enc.Utf8
        )
      );
      // console.log(decryptedData);
      return decryptedData;
    } else {
      return null;
    }
  },
  saveSocketChannel: (channel: any) => {
    const encryptedData = CryptoJS.AES.encrypt(channel, SECRET_KEY).toString();
    localStorage.setItem("socketMainChannel", encryptedData);
  },
  getSocketChannel: () => {
    const encryptedData = localStorage.getItem("socketMainChannel");
    if (encryptedData !== null) {
      const decryptedData = CryptoJS.AES.decrypt(
        encryptedData,
        SECRET_KEY
      ).toString(CryptoJS.enc.Utf8);
      return decryptedData;
    }
    return null;
  },
  saveCountry: (data: any) => {
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      SECRET_KEY
    ).toString();
    localStorage.setItem("selected_country", encryptedData);
  },
  getSavedCountry: () => {
    const encryptedData = localStorage.getItem("selected_country");
    if (encryptedData !== null) {
      const decryptedData = JSON.parse(
        CryptoJS.AES.decrypt(encryptedData, SECRET_KEY).toString(
          CryptoJS.enc.Utf8
        )
      );
      // console.log(decryptedData);
      return decryptedData;
    } else {
      return null;
    }
  },

  //socket listeners
  addListener:(listener:string, data:any)=>{
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      SECRET_KEY
    ).toString();
    StorageBox.addListenerList(listener);
    localStorage.setItem(listener, encryptedData);
  },

  removeListener:(listener:string)=>{
    StorageBox.removeFromListenerList(listener);
    localStorage.removeItem(listener);
  },

  getListenerData:(listener:string)=>{
    const encryptedData = localStorage.getItem(listener);
    if (encryptedData !== null) {
      const decryptedData = JSON.parse(
        CryptoJS.AES.decrypt(encryptedData, SECRET_KEY).toString(
          CryptoJS.enc.Utf8
        )
      );
      return decryptedData;
    } else {
      return null;
    }
  },

  addListenerList:(listener:string)=>{
    const encryptedData = localStorage.getItem('listenerList');

    if (encryptedData !== null) {
      const decryptedData = JSON.parse(
        CryptoJS.AES.decrypt(encryptedData, SECRET_KEY).toString(
          CryptoJS.enc.Utf8
        )
      );
      if(!decryptedData.includes(listener)){
        decryptedData.push(listener);

        const encryptedData = CryptoJS.AES.encrypt(
          JSON.stringify(decryptedData),
          SECRET_KEY
        ).toString();
  
        localStorage.setItem('listenerList', encryptedData);
      }
    } else {
      const encryptedData = CryptoJS.AES.encrypt(
        JSON.stringify([listener]),
        SECRET_KEY
      ).toString();

      localStorage.setItem('listenerList', encryptedData);
    }
  },

  removeFromListenerList:(listener:string)=>{
    const encryptedData = localStorage.getItem('listenerList');
    if (encryptedData !== null) {
      const decryptedData = JSON.parse(
        CryptoJS.AES.decrypt(encryptedData, SECRET_KEY).toString(
          CryptoJS.enc.Utf8
        )
      );
      if(decryptedData.includes(listener)){
        const filteredItems = decryptedData.filter((element:any)=>element!=listener);

        const encryptedData = CryptoJS.AES.encrypt(
          JSON.stringify(filteredItems),
          SECRET_KEY
        ).toString();
  
        localStorage.setItem('listenerList', encryptedData);
      }
    }
  },

  getListenerList:():string[]=>{
    const encryptedData = localStorage.getItem('listenerList');
    if (encryptedData !== null) {
      const decryptedData = JSON.parse(
        CryptoJS.AES.decrypt(encryptedData, SECRET_KEY).toString(
          CryptoJS.enc.Utf8
        )
      );
      
      return decryptedData;
    } else {
      return [];
    }
  },

  clearListenerList:()=>{
    const listeners = StorageBox.getListenerList();

    for (const listener of listeners){
      StorageBox.removeListener(listener)
    }
  },

  clearStorage: () => {
    localStorage.clear();
  },
};
