import { useState, useEffect } from 'react'
import BaseService from '../../helpers/baseServices'
import { global_variables } from '../../helpers/globalVariables'
import Select from 'react-select';

interface selectProps {
    onChange: any
}

const RoutesSelector = ({ onChange }: selectProps) => {

    const [allRoutes, setAllRoutes] = useState([])
    const [isFetching, setIsFetching] = useState(false)

    useEffect(() => {
        const getAllRoutes = async () => {
            setIsFetching(true)
            try {
                const response = await BaseService.get_api(`${global_variables.routes}?limit=1000000000`)
                // console.log(response?.data?.payload)
                const arrangedData = response?.data?.payload.map((dd: any) => (
                    { value: dd, label: dd?.name }
                ))
                setAllRoutes(arrangedData)
                setIsFetching(false)
            } catch (error) {
                console.log(error)
            }
        }

        getAllRoutes()
    }, [])

    return (
        <>
            <Select
                className="basic-single"
                placeholder="Search route name"
                isDisabled={isFetching}
                isLoading={isFetching}
                isClearable={true}
                isSearchable={true}
                options={allRoutes}
                onChange={onChange}
            />
        </>
    )
}

export default RoutesSelector
