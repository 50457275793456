import CustomSideBar from '../../components/layouts/sidebar'
import CustomNavbar from '../../components/layouts/navbar'
import { useEffect, useState } from 'react';
import { EyeIcon, TrashIcon } from '@heroicons/react/24/outline';
import CreateRequestDrawer from '../request/createRequestDrawer';
import BaseService from '../../helpers/baseServices';
import { global_variables } from '../../helpers/globalVariables';
import { TripModel } from './trips.model';
import LiveDeliveryDrawer from './liveDeliveryDrawer';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import ShowAlert from '../../components/alerts/all_alerts';
import { DeleteTripFunction } from '../request/functions/createTrip';
import ShowToast from '../../components/alerts/all_toasts';
import socketConnection from '../../sockets/connection';
import OrderRequestDrawer from './orderRequestDrawer';


const AllOrders = () => {

    const [isFetching, setIsFetching] = useState(false)
    const [liveTrips, setLiveTrips] = useState<TripModel[]>([])
    const [selectedOrder, setSelectedOrder] = useState<TripModel | any>(null)
    const [reFetch, setReFetch] = useState(false)
    const [limit, setLimit] = useState(10)
    const [skip, setSkip] = useState(0)
    const [total, setTotal] = useState(0)

    //open details
    const [openDrawer, setOpenDrawer] = useState(false);
    const handleOpen = () => {
        setOpenDrawer(!openDrawer);
    };

    //handleViewDetails
    const handleViewDetails = async (data: any) => {
        if (data?.currentStatus?.name === "Pending") {
            const action = await ShowAlert.question_alert('Request Delivery', 'Would you like to request for a pickup', 'Request Pickup');
            if (action.isConfirmed) {
                setSelectedOrder(data);
                handleOpen();
            }
            return;
        }
        setSelectedOrder(data);
        handleOpen();
        //check when its pending
        //to open you have to check if it's been given a rider if not request for one
    }





    //on page load
    useEffect(() => {
        getLiveOrders(limit, skip)

        return () => {
            //stop listeners
            stopListeners();
        }
    }, [reFetch, limit, skip])

    const stopListeners = () => {

    }

    //getLiveOrders
    const getLiveOrders = async (lim: number, sk: number) => {
        setIsFetching(true)
        try {
            const response = await BaseService.get_api(`${global_variables.get_all_trips}?filter=[{"completed": false}]&limit=${lim}&skip=${sk}`)
            console.log(response?.data?.payload)
            setLiveTrips(response?.data?.payload)
            setTotal(response?.data?.total)
            setIsFetching(false)
        } catch (error) {
            console.log(error)
        }
    }

    // open create
    const [openAddDrawer, setOpenAddDrawer] = useState(false)
    const handleOpenDrawer = () => {
        setOpenAddDrawer(true);
    };
    // close create
    const handleCloseDrawer = () => {
        setOpenAddDrawer(false);
        handleRefetch()
    };

    //handleDelete trip
    const handleDelete = async (trip: TripModel) => {
        console.log(trip)
        const action = await ShowAlert.question_alert('Confirm', `Do you want to delete your order from ${trip?.pickup?.place} to ${trip?.destination?.place}?`, 'Proceed');

        if (action.isConfirmed) {
            let delBody = JSON.stringify({
                "_id": trip?._id
            });

            try {
                const res: any = await DeleteTripFunction(delBody);

                if (res?.status === 200) {
                    ShowToast.success_toast(res?.data?.message)
                    handleRefetch();
                    return;
                }

                ShowToast.error_toast("Trip cannot be deleted")

            } catch (error: any) {
                ShowToast.error_toast(error?.response?.data?.error)
            }
        }
    }

    // Define the columns for the table
    const columns: any = [
        {
            name: "Pickup",
            cell: (row: any) => row?.pickup?.place,
        },
        {
            name: "Destination",
            cell: (row: any) => row?.destination?.place,
        },
        {
            name: "Item Description",
            cell: (row: any) => row?.itemDescription || "No description",
            width: "20%"
        },
        {
            name: "Qty",
            selector: (row: any) => row?.quantity,
            width: "5%",
        },
        {
            name: "Weight",
            selector: (row: any) => `${row?.weight} ${row?.weightUnit}`,
            width: "10%",
        },
        {
            name: "Current Status",
            selector: (row: TripModel) => <div className='w-fit px-3 py-1 rounded-[5px] bg-midGreen bg-opacity-[10%] text-midGreen'>
                {row?.currentStatus?.name}
            </div>,
        },
        {
            name: "Date Created",
            cell: (row: any) => moment(row?.createdAt).format("Do MMM YYYY - hh:mm A"),
        },
        {
            name: "Action",
            selector: (row: any) => <div className='flex gap-4'>
                <EyeIcon className='h-5 w-5 cursor-pointer' title='View Order Details' onClick={() => handleViewDetails(row)} />

                <TrashIcon className='h-5 w-5 cursor-pointer text-red-500' title='Delete Order' onClick={() => handleDelete(row)} />

            </div>
        }
    ];

    // pagination start
    const handleRowChange = (a: any) => {
        setLimit(a);
        getLiveOrders(a, skip);
    }

    const pageChange = (a: any) => {
        if (a === 1) {
            getLiveOrders(limit, 0);
            setSkip(0)
        } else {
            const newSkip = (a * limit) - limit;
            getLiveOrders(limit, newSkip);
            setSkip(newSkip);
        }
    }
    // pagination end


    //handleRefetch
    const handleRefetch = () => {
        setReFetch(!reFetch)
        setLimit(10)
        setSkip(0)
    }


    return (
        <>
            <div className="h-screen overflow-hidden">
                <div className="flex h-full">
                    {/* sidebar */}
                    <CustomSideBar orderActive="active" />
                    {/* navbar */}
                    <div className="w-full">
                        <CustomNavbar />
                        <div className="h-screen bg-[#1e7e7108] p-[20px] overflow-y-auto">
                            <div className='flex justify-between items-center'>
                                <p className="font-semibold text-lg">My Orders</p>
                                <button className='w-fit px-4 py-2 bg-gradient-to-r from-lightGreen to-midGreen text-white rounded-[5px]' onClick={() => handleOpenDrawer()}>Create Order</button>
                            </div>


                            <div className='mt-5 bg-white shadow-lg rounded-[20px] p-5 mb-20'>
                                <DataTable
                                    columns={columns}
                                    data={liveTrips}
                                    paginationTotalRows={total}
                                    progressPending={isFetching}
                                    responsive
                                    pagination
                                    paginationServer
                                    onChangeRowsPerPage={handleRowChange}
                                    onChangePage={pageChange}
                                />
                            </div>


                            {/* <div className="grid grid-cols-3 gap-4 mt-3 mb-20">

                                {liveTrips.map(
                                    (theTrip, i) => (
                                        <div
                                            key={i}
                                            className="p-[20px] bg-white shadow-lg rounded-[10px] border-[1px] border-transparent cursor-pointer hover:border-[1px] hover:border-midGreen"
                                            onClick={() => handleOpen()}
                                        >
                                            <div className="flex justify-between items-center">
                                                <p className="text-lg font-medium">XR-9852468872</p>
                                                <div className="flex items-center gap-2 text-midGreen">
                                                    <TruckIcon className="h-5 w-5" />
                                                    <p className="text-sm font-medium">On Route</p>
                                                </div>
                                            </div>
                                            <div className="mt-3 flex justify-between">
                                                <img
                                                    className="w-[100px]"
                                                    src={motorImg}
                                                    alt="vehicle"
                                                />
                                                <div>
                                                    <p className="text-md font-medium">00:33:06</p>
                                                    <p className="text-xs font-medium mt-5 text-gray-700">
                                                        90 mi. left
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )}
                            </div> */}

                        </div>
                    </div>
                </div>
            </div>

            {/* create request drawer modal */}
            {
                openAddDrawer ? <CreateRequestDrawer isOpened={openAddDrawer} handleClose={handleCloseDrawer} />
                    :
                    <></>
            }

            {openDrawer ?
                selectedOrder?.currentStatus?.name === "Pending" ?
                    (
                        <OrderRequestDrawer
                            isOpened={openDrawer}
                            handleClose={() => setOpenDrawer(false)}
                            data={selectedOrder}
                        />
                    ) :
                    (
                        <LiveDeliveryDrawer
                            isOpened={openDrawer}
                            closeDrawer={() => setOpenDrawer(false)}
                            deliveryData={selectedOrder}
                        />
                    ) : (
                    <></>
                )}


        </>
    )
}

export default AllOrders
