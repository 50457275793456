// export const iconPath = `M42.3 110.94c2.22 24.11 2.48 51.07 1.93 79.75-13.76.05-24.14 1.44-32.95 6.69-4.96 2.96-8.38 6.28-10.42 12.15-1.37 4.3-.36 7.41 2.31 8.48 4.52 1.83 22.63-.27 28.42-1.54 2.47-.54 4.53-1.28 5.44-2.33.55-.63 1-1.4 1.35-2.31 1.49-3.93.23-8.44 3.22-12.08.73-.88 1.55-1.37 2.47-1.61-1.46 62.21-6.21 131.9-2.88 197.88 0 43.41 1 71.27 43.48 97.95 41.46 26.04 117.93 25.22 155.25-8.41 32.44-29.23 30.38-50.72 30.38-89.54 5.44-70.36 1.21-134.54-.79-197.69.69.28 1.32.73 1.89 1.42 2.99 3.64 1.73 8.15 3.22 12.08.35.91.8 1.68 1.35 2.31.91 1.05 2.97 1.79 5.44 2.33 5.79 1.27 23.9 3.37 28.42 1.54 2.67-1.07 3.68-4.18 2.31-8.48-2.04-5.87-5.46-9.19-10.42-12.15-8.7-5.18-18.93-6.6-32.44-6.69-.75-25.99-1.02-51.83-.01-77.89C275.52-48.32 29.74-25.45 42.3 110.94zm69.63-90.88C83.52 30.68 62.75 48.67 54.36 77.59c21.05-15.81 47.13-39.73 57.57-57.53zm89.14-4.18c28.41 10.62 49.19 28.61 57.57 57.53-21.05-15.81-47.13-39.73-57.57-57.53zM71.29 388.22l8.44-24.14c53.79 8.36 109.74 7.72 154.36-.15l7.61 22.8c-60.18 28.95-107.37 32.1-170.41 1.49zm185.26-34.13c5.86-34.1 4.8-86.58-1.99-120.61-12.64 47.63-9.76 74.51 1.99 120.61zM70.18 238.83l-10.34-47.2c45.37-57.48 148.38-53.51 193.32 0l-12.93 47.2c-57.58-14.37-114.19-13.21-170.05 0zM56.45 354.09c-5.86-34.1-4.8-86.58 1.99-120.61 12.63 47.63 9.76 74.51-1.99 120.61z`;

export const tracker =
  "M 208.755 187.458 L 258.149 138.064 L 258.149 401.852 C 258.149 410.136 264.865 416.852 273.149 416.852 C 281.433 416.852 288.149 410.136 288.149 401.852 L 288.149 138.064 L 337.543 187.458 C 340.471 190.387 344.31 191.852 348.149 191.852 C 351.988 191.852 355.827 190.387 358.755 187.458 C 364.613 181.601 364.613 172.103 358.755 166.245 L 283.755 91.245 C 277.898 85.387 268.4 85.387 262.542 91.245 L 187.542 166.245 C 181.684 172.102 181.684 181.6 187.542 187.458 C 193.4 193.315 202.898 193.315 208.755 187.458 Z";

// export const iconPath = `M1171 3583 c-33 -6 -71 -32 -71 -48 0 -8 -9 -16 -19 -20 -55 -17
//   -111 -129 -111 -220 0 -61 27 -141 61 -179 46 -53 99 -66 266 -66 79 0 143 -2
//   143 -5 0 -8 -431 -1428 -436 -1436 -2 -4 -23 -2 -46 4 -24 5 -106 10 -183 10
//   -112 0 -156 -5 -219 -22 -108 -29 -229 -89 -323 -160 -80 -61 -190 -167 -182
//   -175 2 -3 76 -39 163 -81 l159 -75 -30 -33 c-181 -190 -209 -520 -64 -744 118
//   -182 302 -284 516 -286 182 -1 316 54 446 183 89 89 140 181 165 301 10 43 20
//   79 24 79 4 0 78 -34 165 -75 87 -42 160 -73 163 -70 4 3 8 48 10 100 l4 95
//   804 0 804 0 0 -43 c0 -62 25 -151 67 -237 27 -55 56 -95 112 -150 126 -126
//   263 -183 436 -183 179 0 306 53 435 183 117 116 180 251 180 382 l0 48 266 0
//   267 0 -6 163 c-11 310 -77 540 -210 739 -139 208 -329 341 -557 388 l-85 18
//   402 4 403 3 32 32 c30 30 32 37 36 120 6 132 -13 167 -110 197 -17 5 -18 35
//   -18 451 l0 447 -29 29 -29 29 -777 0 -777 0 -29 -29 -29 -29 0 -451 0 -451
//   -394 0 -395 0 -28 -24 -28 -24 -3 -225 -3 -224 78 -80 c94 -95 155 -198 183
//   -307 24 -95 25 -161 5 -241 -22 -83 -61 -151 -125 -218 l-56 -57 -245 0 -245
//   0 -12 92 c-34 270 -172 474 -479 710 -43 33 -86 66 -95 72 -20 14 -33 -31 138
//   486 96 289 98 297 99 390 0 84 -3 102 -27 151 -15 30 -46 75 -68 99 l-40 43
//   25 91 c14 50 28 126 32 169 3 42 8 77 10 77 3 0 67 -14 144 -31 77 -16 150
//   -29 161 -27 26 4 46 54 47 116 1 35 -3 44 -21 52 -33 14 -286 60 -328 60 -23
//   0 -70 18 -131 49 -67 34 -113 51 -150 55 -91 9 -213 14 -238 9z m-68 -300 c3
//   -62 1 -113 -3 -113 -4 0 -15 15 -24 34 -21 45 -21 138 1 179 20 39 20 38 26
//   -100z m3215 -290 c1 -112 0 -203 -3 -203 -3 0 -46 32 -94 70 -49 39 -94 70
//   -99 70 -6 0 -47 -31 -92 -70 -45 -38 -83 -70 -86 -70 -2 0 -4 92 -4 205 l0
//   205 188 -2 187 -3 3 -202z m540 -466 l3 -47 -263 2 -263 3 -3 33 c-6 65 -18
//   63 265 60 l258 -3 3 -48z m0 -234 l-3 -48 -260 0 -260 0 -3 33 c-6 65 -18 62
//   268 62 l261 0 -3 -47z m-3964 -1429 c162 -74 170 -81 182 -171 20 -152 -112
//   -303 -267 -303 -81 0 -145 26 -199 80 -111 110 -106 289 9 393 41 37 92 66
//   117 67 6 0 77 -30 158 -66z m3376 -232 c0 -42 -57 -148 -94 -177 -126 -96
//   -279 -85 -383 28 -37 41 -73 120 -73 164 0 10 54 13 275 13 l275 0 0 -28z`;

export const iconPath = `M6410 11694 c-57 -22 -87 -42 -134 -89 -80 -79 -88 -100 -95 -245 -5
-105 -9 -130 -27 -155 -37 -52 -81 -155 -128 -298 -25 -75 -46 -142 -46 -148
0 -6 -7 -25 -15 -42 -8 -18 -24 -70 -35 -117 -12 -47 -37 -131 -56 -188 -19
-56 -34 -114 -34 -128 0 -23 3 -25 23 -19 61 19 66 18 73 -10 17 -70 -8 -143
-86 -250 -16 -22 -39 -57 -50 -78 -11 -21 -39 -64 -63 -95 -23 -31 -57 -79
-75 -105 -18 -26 -55 -71 -83 -99 -27 -27 -52 -61 -55 -74 -3 -13 -3 -75 1
-137 3 -61 10 -179 15 -262 15 -228 22 -484 16 -509 -3 -12 -41 -59 -83 -104
-100 -104 -113 -141 -113 -330 0 -149 -5 -161 -77 -181 -53 -15 -68 -14 -110
9 -57 30 -120 27 -174 -10 -54 -37 -66 -37 -115 -3 -52 35 -176 44 -256 19
-94 -30 -158 -65 -196 -108 -20 -22 -44 -49 -53 -58 -8 -9 -43 -22 -77 -30
l-62 -13 1 -66 c0 -36 8 -93 17 -126 19 -66 16 -64 103 -49 l36 6 6 -49 c18
-145 27 -197 50 -288 14 -55 27 -120 30 -145 4 -25 13 -59 21 -75 9 -17 16
-35 16 -42 0 -6 13 -51 29 -100 16 -48 32 -104 36 -123 4 -19 11 -39 15 -45 4
-5 17 -39 30 -75 12 -36 30 -84 41 -107 10 -24 19 -46 19 -51 0 -4 13 -34 30
-67 16 -33 30 -65 30 -70 0 -15 100 -211 133 -262 15 -24 27 -46 27 -50 0 -3
6 -14 14 -22 8 -9 22 -32 32 -51 11 -19 46 -75 79 -125 l60 -90 7 -140 c6
-139 17 -244 37 -362 50 -294 52 -304 62 -533 13 -308 25 -448 59 -680 6 -41
15 -113 19 -160 5 -47 15 -112 24 -145 8 -33 20 -91 27 -130 13 -79 17 -95 66
-285 38 -146 104 -349 135 -417 10 -24 19 -46 19 -51 0 -4 24 -55 53 -112 86
-172 177 -299 307 -431 56 -56 82 -91 89 -117 29 -110 61 -247 76 -327 10 -49
21 -94 25 -100 5 -5 18 -48 30 -95 42 -164 85 -280 119 -316 46 -49 93 -59
266 -59 169 0 187 5 253 76 26 27 43 59 55 102 10 34 31 105 46 157 33 112 83
312 116 463 26 120 22 113 122 214 168 169 332 448 422 718 42 128 157 571
172 668 5 31 14 85 20 120 6 34 15 97 20 139 16 138 29 241 40 318 6 41 14
203 18 360 4 157 10 292 14 300 14 29 38 156 48 250 5 52 14 120 20 150 6 30
13 134 17 230 l7 176 46 69 c85 128 104 158 125 194 12 19 32 39 45 44 102 38
119 63 119 179 0 54 5 93 15 112 8 15 15 32 15 38 0 5 9 28 20 50 11 23 20 46
20 51 0 4 13 36 29 70 16 34 42 98 56 142 15 44 33 94 40 110 16 34 40 120 61
215 8 36 23 90 34 120 11 30 37 144 59 253 21 109 41 201 44 204 3 3 21 -1 41
-8 33 -12 36 -11 80 25 57 47 79 88 80 147 0 80 -57 135 -150 144 -42 5 -86
27 -123 63 -46 44 -161 77 -312 89 -65 5 -67 4 -107 -31 -38 -35 -43 -37 -89
-30 -27 3 -66 1 -88 -5 -33 -9 -57 -8 -135 10 -112 25 -154 43 -167 72 -6 12
-8 72 -5 133 4 95 1 122 -17 181 -25 82 -40 105 -100 158 -25 22 -58 53 -74
68 l-29 28 7 107 c21 331 25 425 31 606 3 109 3 209 0 221 -3 12 -23 40 -43
61 -21 22 -50 55 -64 74 -15 19 -38 48 -52 64 -14 16 -31 41 -38 55 -8 14 -26
42 -41 61 -15 19 -33 46 -40 60 -7 14 -36 59 -63 100 -47 71 -50 79 -53 153
-2 42 0 77 5 77 12 0 56 -31 66 -47 11 -17 37 -17 37 1 0 7 -7 27 -15 42 -8
16 -15 39 -15 51 0 13 -11 57 -25 98 -14 42 -31 102 -36 133 -6 31 -15 62 -19
67 -8 10 -37 98 -116 350 -21 66 -43 127 -50 135 -6 8 -24 40 -40 70 -25 48
-29 70 -35 170 -4 94 -10 122 -28 155 -41 71 -90 123 -149 156 -51 30 -66 34
-147 36 -49 1 -97 0 -105 -3z m242 -3315 c82 -16 221 -50 243 -59 11 -4 56
-23 100 -41 44 -18 89 -41 101 -51 11 -10 26 -18 32 -18 34 0 205 -153 223
-198 25 -64 -1 -131 -61 -162 -38 -19 -58 -20 -782 -20 l-744 0 -44 23 c-36
17 -48 31 -59 62 -22 64 -9 102 57 167 74 74 147 128 172 128 11 0 20 5 20 11
0 5 17 17 38 25 20 9 60 26 87 39 64 30 200 71 290 89 86 17 256 20 327 5z
m-1345 -701 c-7 -176 -39 -620 -57 -783 -19 -169 -52 -430 -56 -434 -13 -14
-39 26 -107 163 -42 86 -77 160 -77 165 0 5 -22 60 -49 122 -27 63 -64 155
-81 204 -18 50 -36 99 -41 110 -22 55 -59 180 -59 201 0 13 -4 24 -10 24 -21
0 -9 37 23 66 43 41 93 69 161 88 65 19 81 41 73 101 l-6 43 39 6 c31 5 40 11
40 26 0 12 8 22 23 25 21 4 178 42 186 44 2 1 1 -77 -2 -171z m2526 135 c53
-11 67 -18 67 -33 0 -13 10 -20 38 -25 56 -9 67 -18 51 -43 -10 -16 -10 -27 2
-58 13 -35 20 -40 69 -53 30 -8 57 -18 60 -21 3 -4 17 -10 31 -13 27 -7 99
-71 99 -88 0 -18 -109 -357 -130 -404 -19 -42 -83 -202 -102 -255 -11 -30 -24
-56 -29 -58 -5 -2 -9 -8 -9 -14 0 -16 -144 -308 -152 -308 -4 0 -10 10 -13 23
-9 39 -63 528 -74 667 -16 198 -41 597 -41 658 l0 54 33 -7 c17 -4 62 -14 100
-22z m-1261 -567 c167 -62 286 -141 418 -275 188 -191 328 -454 439 -823 l41
-133 -37 -30 c-20 -16 -44 -34 -54 -40 -9 -5 -54 -33 -100 -61 -130 -79 -126
-80 -127 37 -1 55 -6 108 -12 119 -6 11 -13 37 -16 58 -2 20 -11 51 -19 67 -7
17 -22 51 -31 77 -9 26 -20 45 -25 42 -5 -3 -9 4 -9 15 0 11 -5 23 -10 26 -6
4 -8 10 -5 15 3 4 -2 10 -10 14 -8 3 -15 11 -15 18 0 7 -9 19 -21 26 -12 8
-18 19 -15 28 10 25 -65 163 -96 177 -16 6 -28 15 -28 19 0 5 -9 8 -20 8 -11
0 -20 5 -20 10 0 6 -7 10 -15 10 -8 0 -15 5 -15 10 0 6 -7 10 -15 10 -8 0 -15
5 -15 10 0 6 -7 10 -15 10 -8 0 -15 5 -15 10 0 6 -9 10 -20 10 -13 0 -20 7
-20 20 0 12 -9 22 -23 25 -15 4 -28 19 -36 41 -8 23 -19 34 -32 34 -10 0 -19
5 -19 10 0 6 -9 10 -19 10 -11 0 -23 -4 -26 -10 -3 -5 -14 -10 -24 -10 -11 0
-24 -12 -31 -30 -7 -16 -16 -30 -20 -30 -5 0 -26 -17 -47 -37 -21 -21 -52 -45
-68 -53 -17 -8 -37 -23 -46 -32 -9 -10 -28 -18 -43 -18 -14 0 -26 -3 -26 -8 0
-4 -11 -19 -24 -33 -28 -30 -66 -101 -66 -124 0 -8 -4 -15 -9 -15 -5 0 -11
-12 -14 -27 -5 -26 -4 -26 9 -8 15 20 18 17 8 -9 -3 -9 -12 -16 -20 -16 -8 0
-14 -7 -14 -15 0 -8 -9 -24 -20 -35 -11 -11 -20 -24 -20 -30 0 -5 -10 -18 -22
-30 -12 -11 -19 -23 -15 -26 3 -4 -4 -25 -17 -48 -45 -82 -76 -299 -58 -415
21 -133 21 -137 5 -71 l-17 65 -84 40 c-46 22 -87 45 -90 51 -4 5 -24 18 -45
28 -21 10 -41 24 -44 32 -3 8 -13 14 -22 14 -9 0 -33 14 -52 31 l-36 30 38
127 c52 180 82 258 154 409 168 352 413 595 702 698 37 13 69 24 70 25 2 0 31
-11 65 -24z m53 -633 c-11 -3 -40 -25 -65 -49 l-46 -44 -42 43 c-23 24 -39 46
-36 50 4 4 53 6 108 6 56 -1 92 -3 81 -6z m512 -940 c-3 -10 -5 -2 -5 17 0 19
2 27 5 18 2 -10 2 -26 0 -35z m-1139 -154 c-3 -8 -7 -3 -11 10 -4 17 -3 21 5
13 5 -5 8 -16 6 -23z m1082 -6 c-11 -17 -11 -17 -6 0 3 10 6 24 7 30 0 9 2 9
5 0 3 -7 0 -20 -6 -30z m-1042 -58 c6 -14 10 -25 8 -25 -2 0 -11 11 -20 25 -9
14 -13 25 -8 25 5 0 14 -11 20 -25z m1022 16 c0 -6 -4 -13 -10 -16 -5 -3 -10
1 -10 9 0 9 5 16 10 16 6 0 10 -4 10 -9z m-44 -69 c-18 -16 -18 -16 -6 6 6 13
14 21 18 18 3 -4 -2 -14 -12 -24z m-22 -37 c-4 -8 -11 -15 -16 -15 -6 0 -5 6
2 15 7 8 14 15 16 15 2 0 1 -7 -2 -15z m-754 -135 c0 -5 7 -10 16 -10 8 0 22
-7 30 -15 24 -24 -3 -18 -44 10 -24 16 -31 25 -19 25 9 0 17 -4 17 -10z m599
-8 c-42 -31 -78 -52 -91 -52 -7 0 12 15 42 34 59 37 88 47 49 18z m-124 -72
c-28 -12 -57 -12 -50 0 3 6 21 10 38 9 27 0 29 -2 12 -9z m-1244 -1047 c8 -10
26 -39 39 -65 13 -27 26 -48 30 -48 4 0 19 -23 35 -51 31 -58 256 -288 327
-335 59 -40 200 -108 273 -133 129 -43 180 -51 325 -51 118 0 158 4 243 25 56
14 121 35 144 45 24 11 47 20 52 20 14 0 49 21 160 94 97 64 311 262 311 288
0 18 105 168 118 168 21 0 34 -52 28 -115 -16 -183 -35 -293 -56 -320 -4 -5
-13 -35 -20 -65 -7 -30 -25 -82 -40 -115 -15 -33 -40 -89 -56 -125 -48 -107
-129 -216 -249 -334 -104 -104 -122 -117 -229 -170 -64 -32 -149 -66 -189 -77
-133 -35 -448 -21 -491 22 -6 5 -17 9 -25 9 -29 1 -149 68 -228 127 -161 120
-246 229 -356 455 -83 172 -105 246 -166 556 -13 64 -15 212 -3 212 5 0 15 -8
23 -17z`;
