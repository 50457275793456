import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Spinner,
} from "@material-tailwind/react";
import { useState } from "react";
import PhoneInput from "react-phone-number-input";
import BaseService from "../../helpers/baseServices";
import { global_variables } from "../../helpers/globalVariables";
import ShowToast from "../../components/alerts/all_toasts";
import { StorageBox } from "../../helpers/stroage";


interface modalProps {
    openModal: boolean
    handleOpenModal: () => void
    handleCloseModal: () => void
    handleReFetch: () => void
}

const SaveNewRider = ({ handleOpenModal, openModal, handleReFetch, handleCloseModal }: modalProps) => {

    const selectedCountry = StorageBox.getSavedCountry()

    const [riderPhone, setRiderPhone] = useState("")

    const [isBusy, setIsBusy] = useState(false)
    const [isError, setIsError] = useState(false)


    const handleSaveDriver = async () => {
        if (!riderPhone) {
            setIsError(true)
        }
        else {
            setIsBusy(true)
            try {
                let data = JSON.stringify({
                    "phone": riderPhone
                })

                const response = await BaseService.post_api(global_variables.save_rider, data)
                console.log(response?.data)
                handleCloseModal()
                ShowToast.success_toast(response?.data?.message)
                handleReFetch()

            } catch (error: any) {
                console.log(error)
                setIsBusy(false)
                ShowToast.error_toast(error?.response?.data?.error)
                handleCloseModal()
            }
        }
    }


    return (
        <Dialog open={openModal} handler={handleOpenModal}>
            <DialogHeader>Save New Rider</DialogHeader>
            <DialogBody divider>
                <div>
                    <small className={isError ? 'text-red-500' : ''}>Enter Rider's Phone Number</small>
                    <PhoneInput
                        className="rounded-[5px] w-full border-[1px] border-gray-500 h-[40px] px-2 bg-white"
                        defaultCountry={selectedCountry?.country === "Ghana" ? "GH" : "NG"}
                        inputStyle={{ width: "100%" }}
                        placeholder="Enter phone number"
                        value={riderPhone}
                        onChange={(phone: any) => setRiderPhone(phone)}
                        disabled={isBusy}
                    />
                </div>
            </DialogBody>
            <DialogFooter>
                <Button
                    variant="text"
                    color="red"
                    onClick={handleCloseModal}
                    className="mr-1"
                >
                    <span>Cancel</span>
                </Button>
                <Button variant="gradient" color="green" onClick={() => handleSaveDriver()}>
                    {
                        isBusy ? <Spinner /> : <span>Save Rider</span>
                    }
                </Button>
            </DialogFooter>
        </Dialog>
    )
}

export default SaveNewRider
