import { GoogleMap, Marker, Polyline, useJsApiLoader, InfoWindow } from '@react-google-maps/api';
import polylineDecoder from '@mapbox/polyline';
import { GOOGLE_KEY } from '../../helpers/constants';
import { useState } from 'react';

interface theProps {
    locationData: any
}

const MapContainerStyle = {
    width: '100%',
    height: '400px',
};

const TripMapComponent = ({ locationData }: theProps) => {

    const [infoWindow, setInfoWindow] = useState<any>(null);

    // Extracting coordinates from props
    const { start_location, end_location, steps, end_address, start_address } = locationData[0];

    // Calculating the center of the map
    const center = {
        lat: (start_location?.lat + end_location?.lat) / 2,
        lng: (start_location?.lng + end_location?.lng) / 2,
    };

    //start marker
    const startMarkerOptions = {
        icon: {
            path: window.google.maps.SymbolPath.CIRCLE,
            fillColor: 'white',
            fillOpacity: 1,
            scale: 8,
            strokeColor: '#800808',
            strokeWeight: 5,
        },
        position: start_location,
    };

    //end marker
    const endMarkerOptions = {
        icon: {
            path: window.google.maps.SymbolPath.CIRCLE,
            fillColor: 'white',
            fillOpacity: 1,
            scale: 8,
            strokeColor: '#165b4f',
            strokeWeight: 5,
        },
        position: end_location,
    };


    // Creating a path for the polyline
    const path = steps.flatMap((step: any) =>
        polylineDecoder.decode(step.polyline.points).map((point) => ({
            lat: point[0],
            lng: point[1],
        }))
    );

    const polylineOptions = {
        strokeColor: '#165b4f',
        strokeOpacity: 1,
        strokeWeight: 4,
    };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: `${GOOGLE_KEY}`
    })

    const handleStartMarkerClick = () => {
        setInfoWindow({ marker: 'start', isOpen: true });
    };

    const handleEndMarkerClick = () => {
        setInfoWindow({ marker: 'end', isOpen: true });
    };

    return (
        <div>
            {
                isLoaded ?
                    <GoogleMap
                        mapContainerStyle={MapContainerStyle}
                        center={center}
                        zoom={12}
                    >
                        {/* Adding markers for start and end locations */}
                        <Marker {...startMarkerOptions} onClick={handleStartMarkerClick} />
                        <Marker {...endMarkerOptions} onClick={handleEndMarkerClick} />

                        {/* Adding a polyline */}
                        <Polyline path={path} options={polylineOptions} />

                        {infoWindow && infoWindow.marker === 'start' && (
                            <InfoWindow
                                position={start_location}
                                onCloseClick={() => setInfoWindow(null)}
                            >
                                <div>
                                    <p>Start Address: {start_address}</p>
                                </div>
                            </InfoWindow>
                        )}

                        {infoWindow && infoWindow.marker === 'end' && (
                            <InfoWindow
                                position={end_location}
                                onCloseClick={() => setInfoWindow(null)}
                            >
                                <div>
                                    <p>End Address: {end_address}</p>
                                </div>
                            </InfoWindow>
                        )}
                    </GoogleMap>
                    :
                    <></>
            }
        </div>
    );
};

export default TripMapComponent;
