import Lottie, { useLottie } from "lottie-react";
import riderAnimation from "../../animations/riderLottie.json"

const LoadingComponent = () => {
    return (
        <div className="lg:w-[400px]">
            <Lottie animationData={riderAnimation} loop={true} />
        </div>
    )
}

export default LoadingComponent
