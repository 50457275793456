import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Input,
    Spinner,
} from "@material-tailwind/react";
import GooglePlacesAutocomplete, {
    geocodeByPlaceId,
    getLatLng,
} from "react-google-places-autocomplete";
import { GOOGLE_KEY } from "../../../helpers/constants";
import { useEffect, useState } from "react";
import BaseService from "../../../helpers/baseServices";
import { global_variables } from "../../../helpers/globalVariables";
import ShowToast from "../../../components/alerts/all_toasts";
import ShowAlert from "../../../components/alerts/all_alerts";
import { StorageBox } from "../../../helpers/stroage";

interface modalProps {
    openModal: boolean
    handleOpenModal: () => void
    handleCloseModal: () => void
    handleReFetch: () => void
    dataToEdit: any
    isEditing: boolean
}

const CreateRouteModal = ({ handleOpenModal, openModal, handleReFetch, isEditing, dataToEdit, handleCloseModal }: modalProps) => {

    const selectedCountry = StorageBox.getSavedCountry()

    const [customName, setCustomName] = useState("")
    const [pickupPlaceName, setPickupPlaceName] = useState("")
    const [pickupCity, setPickupCity] = useState("")
    const [pickupRegion, setPickupRegion] = useState("")
    const [pickupLong, setPickupLong] = useState<any>(0)
    const [pickupLat, setPickupLat] = useState<any>(0)

    const [destPlaceName, setDestPlaceName] = useState("")
    const [destCity, setDestCity] = useState("")
    const [destRegion, setDestRegion] = useState("")
    const [destLong, setDestLong] = useState<any>(0)
    const [destLat, setDestLat] = useState<any>(0)

    const [isBusy, setIsBusy] = useState(false)
    const [isError, setIsError] = useState(false)

    //onLoad
    useEffect(() => {
        if (isEditing) {
            setCustomName(dataToEdit?.name)
        }
    }, [dataToEdit, isEditing])

    // handle pickup slected
    const handlePickupSelected = (selected: any) => {
        if (selected) {
            console.log(selected.value);
            geocodeByPlaceId(selected.value.place_id)
                .then((results) => {
                    console.log(results);
                    getLatLng(results[0]).then(({ lat, lng }) => {
                        console.log(lat, lng);
                        setPickupLat(lat);
                        setPickupLong(lng);
                    });
                    setPickupRegion(results?.[0]?.address_components?.[2]?.long_name)
                    setPickupCity(results?.[0]?.address_components?.[1]?.long_name)
                })
                .catch((error) => console.error(error));
            setPickupPlaceName(selected.value.description);
        } else {
            console.log("No option selected");
            setPickupPlaceName("");
        }
    };

    // handle destination slected
    const handleDestinationSelected = (selected: any) => {
        if (selected) {
            console.log(selected.value);
            geocodeByPlaceId(selected.value.place_id)
                .then((results) => {
                    console.log(results);
                    getLatLng(results[0]).then(({ lat, lng }) => {
                        console.log(lat, lng);
                        setDestLat(lat);
                        setDestLong(lng);
                    });
                    setDestRegion(results?.[0]?.address_components?.[2]?.long_name)
                    setDestCity(results?.[0]?.address_components?.[1]?.long_name)
                })
                .catch((error) => console.error(error));
            setDestPlaceName(selected.value.description);
        } else {
            console.log("No option selected");
            setDestPlaceName("");
        }
    };

    //doCreate
    const doCreate = () => {
        if (!customName || !pickupPlaceName || !destPlaceName) {
            setIsError(true)
        }
        else {
            let data = JSON.stringify({
                "name": customName,
                "pickup": {
                    "city": pickupCity,
                    "region": pickupRegion,
                    "place": pickupPlaceName,
                    "latitude": pickupLat,
                    "longitude": pickupLong
                },
                "destination": {
                    "region": destRegion,
                    "place": destPlaceName,
                    "city": destCity,
                    "latitude": destLat,
                    "longitude": destLong
                }
            });
            setIsBusy(true)

            BaseService.post_api(global_variables.routes, data)
                .then((response) => {
                    console.log(response)
                    if (response?.status === 200) {
                        ShowToast.success_toast(response?.data?.message)
                        handleCloseModal()
                        handleReFetch()
                        setIsBusy(false)
                    }
                }).catch((error) => {
                    console.log(error)
                    ShowAlert.error_alert("Error", error?.response?.data?.error, "Retry")
                    handleCloseModal()
                    setIsBusy(false)
                })
        }
    }

    //doEdit
    const doEdit = () => {
        if (!customName || !pickupPlaceName || !destPlaceName) {
            setIsError(true)
        }
        else {
            let data = JSON.stringify({
                "_id": dataToEdit?._id,
                "name": customName,
                "pickup": {
                    "city": pickupCity,
                    "region": pickupRegion,
                    "place": pickupPlaceName,
                    "latitude": pickupLat,
                    "longitude": pickupLong
                },
                "destination": {
                    "region": destRegion,
                    "place": destPlaceName,
                    "city": destCity,
                    "latitude": destLat,
                    "longitude": destLong
                }
            });
            setIsBusy(true)

            BaseService.put_api(global_variables.routes, data)
                .then((response) => {
                    console.log(response)
                    if (response?.status === 200) {
                        ShowToast.success_toast(response?.data?.message)
                        handleCloseModal()
                        handleReFetch()
                        setIsBusy(false)
                    }
                }).catch((error) => {
                    console.log(error)
                    ShowAlert.error_alert("Error", error?.response?.data?.error, "Retry")
                    handleCloseModal()
                    setIsBusy(false)
                })
        }
    }

    return (
        <Dialog open={openModal} handler={handleOpenModal}>
            <DialogHeader>{`${isEditing ? 'Edit' : 'Save New'} Route`}</DialogHeader>
            <DialogBody divider>
                <div>
                    <small className={isError ? 'text-red-500' : ''}>Pickup Location</small>
                    <GooglePlacesAutocomplete
                        apiKey={`${GOOGLE_KEY}`}
                        autocompletionRequest={{
                            componentRestrictions: {
                                country: selectedCountry?.country === "Ghana" ? ["gh"] : ["ng"],
                            },
                        }}
                        selectProps={{
                            placeholder: "Search...",
                            isClearable: true,
                            isSearchable: true,
                            onChange: (selected) => handlePickupSelected(selected),
                            isDisabled: isBusy
                        }}
                    />
                </div>
                <div className="mt-3">
                    <small className={isError ? 'text-red-500' : ''}>Destination</small>
                    <GooglePlacesAutocomplete
                        apiKey={`${GOOGLE_KEY}`}
                        autocompletionRequest={{
                            componentRestrictions: {
                                country: selectedCountry?.country === "Ghana" ? ["gh"] : ["ng"],
                            },
                        }}
                        selectProps={{
                            placeholder: "Search...",
                            isClearable: true,
                            isSearchable: true,
                            onChange: (selected) => handleDestinationSelected(selected),
                            isDisabled: isBusy
                        }}
                    />
                </div>
                <div className="mt-3">
                    <small className={isError ? 'text-red-500' : ''}>Custom Location Name</small>
                    <Input label="Example: My Office" value={customName} onChange={(e) => setCustomName(e.target.value)} disabled={isBusy} />
                </div>
            </DialogBody>
            <DialogFooter>
                <Button
                    variant="text"
                    color="red"
                    onClick={handleCloseModal}
                    className="mr-1"
                >
                    <span>Cancel</span>
                </Button>
                {
                    isEditing ?
                        <Button variant="gradient" color="green" onClick={() => doEdit()}>
                            {
                                isBusy ? <Spinner /> : <span>Update</span>
                            }
                        </Button>
                        :
                        <Button variant="gradient" color="green" onClick={() => doCreate()}>
                            {
                                isBusy ? <Spinner /> : <span>Save</span>
                            }
                        </Button>
                }
            </DialogFooter>
        </Dialog>
    )
}

export default CreateRouteModal
