import { AtSymbolIcon, LockClosedIcon } from "@heroicons/react/24/outline";
import { Input, Spinner, Typography } from "@material-tailwind/react";
import { Fragment, useState } from "react";
import InputPinModal from "./input-pin";
import ForgotPwdModal from "./fogort-pwd-modal";
import { BASE_URL } from "../../../helpers/constants";
import { global_variables } from "../../../helpers/globalVariables";
import axios from "axios";
import ShowToast from "../../../components/alerts/all_toasts";
import logo from "../../../images/muuvLogo.png";
import { useNavigate } from "react-router-dom";
import CountrySelector from "../../../components/general/countrySelector";
import { StorageBox } from "../../../helpers/stroage";

const SignInForm = () => {
  const navigate = useNavigate()
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [inputEmail, setInputEmail] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const [verifyToken, setVerifyToken] = useState("");
  const [selectedCountry, setSelectedCountry] = useState<any>(null)

  // handle on sign in click
  const doSignIn = (e: any) => {
    e.preventDefault();
    if (inputEmail === "" || inputPassword === "" || !selectedCountry) {
      ShowToast.warning_toast("Please fill out all fields!");
    } else {
      setIsLoggingIn(true);
      const data = JSON.stringify({
        email: inputEmail,
        password: inputPassword,
      });
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${BASE_URL}${global_variables.signinRoute}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          if (response?.data?.payload && Object.keys(response.data.payload).length === 0) {
            setVerifyToken(response?.data?.token);
            StorageBox.saveCountry(selectedCountry)
            setIsLoggingIn(false)
            handleOpenModal();
          }
          else {
            StorageBox.saveAccessToken(response?.data?.token)
            StorageBox.saveUserData(response?.data?.payload)
            StorageBox.saveCountry(selectedCountry)
            setIsLoggingIn(false)
            navigate("/dashboard")
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoggingIn(false);
          ShowToast.error_toast(error?.response?.data?.error);
        });
    }
  };

  // otp modal
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);

  // forgot pwd modal
  const [openForgotModal, setOpenForgotModal] = useState(false);
  const handleOpenForgotModal = () => setOpenForgotModal(!openForgotModal);

  return (
    <Fragment>
      <div className="flex flex-col gap-6 w-full lg:mx-10">
        <div className="flex justify-center items-center mb-5">
          <img className="w-[120px]" src={logo} alt="logo" />
        </div>

        <form onSubmit={doSignIn}>
          <Input
            label="Email"
            type="email"
            icon={<AtSymbolIcon />}
            value={inputEmail}
            onChange={(e) => setInputEmail(e.target.value)}
            disabled={isLoggingIn}
          />
          <div className="mt-5">
            <Input
              label="Password"
              type="password"
              icon={<LockClosedIcon />}
              value={inputPassword}
              onChange={(e) => setInputPassword(e.target.value)}
              disabled={isLoggingIn}
            />
          </div>
          {/* CountrySelector */}
          <div className="mt-5">
            <CountrySelector onChange={(selected: any) => setSelectedCountry(selected?.value)} />
          </div>

          <button
            className={`${isLoggingIn
              ? "cursor-not-allowed flex justify-center items-center"
              : ""
              } w-full bg-midGreen text-white rounded-[5px] py-3 mt-6 text-center`}
            type="submit"
            disabled={isLoggingIn}
          >
            {isLoggingIn ? <Spinner /> : "Sign In"}
          </button>
        </form>

        <div className="flex items-center justify-center">
          <button
            className="text-midGreen font-medium"
            onClick={() => handleOpenForgotModal()}
          >
            Forgot your password?
          </button>
        </div>

        <div className="flex items-center justify-center gap-2 mt-0">
          <Typography>Don't have an account?</Typography>
          <button className="text-midGreen font-medium" onClick={() => navigate("/signup")}>Create Account</button>
        </div>
      </div>



      {/* input pin modal */}
      {openModal ? (
        <InputPinModal
          openModal={openModal}
          handleOpenModal={() => handleOpenModal()}
          vToken={verifyToken}
        />
      ) : (
        <></>
      )}

      {/* fogot pwd modal */}
      {openForgotModal ? (
        <ForgotPwdModal
          openPwdModal={openForgotModal}
          handleOpenPwdModal={handleOpenForgotModal}
        />
      ) : (
        <></>
      )}

    </Fragment>
  );
};

export default SignInForm;
