
import { Fragment, useState, useEffect } from "react";
import { GoogleMap, Marker, Polyline, useJsApiLoader } from "@react-google-maps/api";
import { iconPath, tracker } from './iconPath';
import { Spinner } from "@material-tailwind/react";
import socketConnection from "../../../../sockets/connection";
import { StorageBox } from "../../../../helpers/stroage";
import { GOOGLE_KEY } from "../../../../helpers/constants";
import CardOnMap from "./cardOnMap";
import BaseService from "../../../../helpers/baseServices";
import ShowToast from "../../../../components/alerts/all_toasts";
import { global_variables } from "../../../../helpers/globalVariables";


const containerStyle = {
  width: '100%',
  height: '750px',
};

const center = {
  lat: 5.6297604, // Adjust the center to fit your path
  lng: -0.303076, // Adjust the center to fit your path
};

interface trackingProps {
  tripData: any
}




const RiderTracking = ({ tripData }: trackingProps) => {

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: `${GOOGLE_KEY}`
  })

  const [isFetching, setIsFetching] = useState(false)
  const [isBusy, setIsBusy] = useState(false)
  const [total, setTotal] = useState(0)
  const [liveLocations, setLiveLocations] = useState<any>();

  const [location, setLocation] = useState<any>([]);
  const [dataSuccess, setDataSuccess] = useState(false);
  const [logLocation, setLogLocation] = useState<any>([]);
  const [tLogSuccess, setTLogSuccess] = useState(false);

  const [currSpeed, setCurrSpeed] = useState('');
  const [currSpeedUnit, setCurrSpeedUnit] = useState('');
  const [currTimeStamp, setCurrTimeStamp] = useState('');
  const [paths, setPaths] = useState<{ lat: number, lng: number }[]>([]);
  const [tripEventStatus, setTripEventStatus] = useState("Pending")
  const [tripEventTimeStamp, settripEventTimeStamp] = useState(null)
  const [tripEventActiveStep, setTripEventActiveStep] = useState(0)

  const socketMainChannel = StorageBox.getSocketChannel()

  useEffect(() => {

  }, [liveLocations])

  useEffect(() => {
    console.log(' ----- paths', paths);
  }, [paths])

  //listen to dom changes to event
  useEffect(() => {
    switch (tripEventStatus) {
      case 'Pending':
        setTripEventActiveStep(0)
        break;
      case 'Accept':
        setTripEventActiveStep(0)
        break;
      case 'Arrived At PickUp':
        setTripEventActiveStep(1)
        break;
      case 'Cancel':
        setTripEventActiveStep(0)
        break;
      case 'Trip Started':
        setTripEventActiveStep(1)
        break;
      case 'Trip Ended':
        setTripEventActiveStep(2)
        break;
      case 'Payment Complete':
        setTripEventActiveStep(2)
        break;
      case 'Trip Completed':
        setTripEventActiveStep(2)
        break;
    }

  }, [tripEventStatus])

  //onload
  useEffect(() => {
    currentTrip();
    socketConnection.addListener('/core/get-trip-once', { trackingId: tripData?._id }, false, 'tripChannel');
    const driverId = tripData?.DriverData?.[0]?._id
    socketConnection.addListener('/core/get-triplog', { trackingId: tripData?._id }, false, 'tripChannel');
    socketConnection.addListener("/core/get-triplog", null, true, "tripChannel");
    socketConnection.addListener("/tripLocation", null, true, null);

    socketConnection.addListener("/core/get-location", { userid: driverId }, false, "tripChannel")
    socketConnection.addListener("/core/get-location", null, true, "tripChannel");
    socketConnection.addListener(`${driverId}/location`, null, true, null);

    socketConnection.addListener(`${tripData._id}/events`, null, true, null);

    const connectionSubscription = socketConnection.listener$.subscribe((response) => {
      if (response['listener'] === `${tripData?._id}/tripLocation`) {
        const channelResponse = response['data'];
        const newArray = logLocation.concat([channelResponse]);
        setLocation(newArray);
      }

      if (response['listener'] === '/core/get-triplog') {
        const data = response['data'];
        setTLogSuccess(data.success)
        if (data.success) {
          console.log('------ trip logs', data.payload)
          setLogLocation(data.payload)
        }
      }

      if (response['listener'] === "/core/get-location") {
        const locationResponse = response['data'];
        setDataSuccess(locationResponse?.success)
        if (locationResponse?.success) {
          setLiveLocations(locationResponse?.payload?.location)
        }
      }

      if (response['listener'] === `${driverId}/location`) {
        const data = response['data']
        setLiveLocations(data)
      }

      if (response['listener'] === `${tripData._id}/events`) {
        const data = response['data']
        if (data == null) {
          return;
        }

        setTripEventStatus(data?.payload?.current_event?.name ?? tripEventStatus);
        settripEventTimeStamp(data?.payload?.current_event?.timestamp ?? tripEventTimeStamp);
      }

      if (response['listener'] === '/core/get-trip-once') {
        const data = response['data'];
        if (data.success) {
          setTripEventStatus(data?.payload?.currentStatus?.name);
          settripEventTimeStamp(data?.payload?.currentStatus?.timestamp)
          return;
        } else {
          console.log('error getting current event', data?.message)
          ShowToast.error_toast(`Error occurred whilst getting current trip event`)
        }
      }

    });
    getLiveLocations()

    return () => {
      //stop listeners
      connectionSubscription.unsubscribe()
      stopListeners()
    }
  }, [])

  const currentTrip = async () => {
    console.log('trip id -------', tripData);
    try {
      const response = await BaseService.get_api(`${global_variables.get_one_trip}/${tripData?._id}`);

      console.log('----- response trip ', response.data);
      if (response.status == 200) {
        setTripEventStatus((response as any).data?.payload?.currentStatus?.name);
        settripEventTimeStamp((response as any).data?.payload?.currentStatus?.timestamp)
        return;
      }
    } catch (error) {
      console.log('error getting current event', error)
      ShowToast.error_toast(`Error occurred whilst getting current trip event`)
    }

  }

  const stopListeners = () => {
    //remove listeners
    socketConnection.removeListener(`${tripData?._id}/tripLocation`);

    //clear all listeners
    socketConnection.refresh();
  }

  //get live locations
  const getLiveLocations = () => {
    // setIsBusy(true)
    console.log("getting live location...")
  }





  return (
    <Fragment>

      {/* main content */}
      <div className="h-full bg-[#1e7e7108] pr-[20px] overflow-y-auto">
        <div className="relative overflow-hidden w-fulll">
          {
            isLoaded ?
              <GoogleMap mapContainerStyle={containerStyle} center={{ "lat": liveLocations?.latitude, "lng": liveLocations?.longitude }} zoom={18}>

                {liveLocations != null ? <Marker
                  position={{ "lat": liveLocations?.latitude, "lng": liveLocations?.longitude }}
                  icon={{
                    path: iconPath,
                    fillColor: "#1e7e71",
                    fillOpacity: 0.9,
                    scale: 0.005,
                    strokeColor: "black",
                    strokeWeight: 1,
                    rotation: liveLocations?.rotation
                  }}
                /> : <></>}

                {/* trip logs */}
                {
                  logLocation.map((loc: any, index: number) => (
                    <Marker
                      position={{ "lat": loc?.latitude, "lng": loc?.longitude }}
                      // label="..."
                      key={index}
                      icon={{
                        path: tracker,
                        fillColor: "#ff6700",
                        fillOpacity: 0.9,
                        scale: 0.04,
                        strokeColor: "#ff6700",
                        rotation: loc.rotation
                      }}
                    />
                  ))
                }


                {/* the card */}
                <div className="absolute top-2 px-10 w-full">
                  <CardOnMap status={tripEventStatus} timestamp={tripEventTimeStamp} activeStep={tripEventActiveStep} />
                </div>
              </GoogleMap>
              :
              <div className='flex justify-center items-center h-full flex-col'>
                <Spinner className="h-10 w-10" />
                <p className="text-center">Please wait...</p>
              </div>
          }

        </div>

      </div>

    </Fragment>
  );
};

export default RiderTracking;
