export const global_variables = {
  // auth routes
  signinRoute: "/muuvexpress/authentication/signin",
  signupRoute: "/muuvexpress/authentication/sign/partner",
  verifyTokenRoute: "/muuvexpress/authentication/verify_account",
  resetPwdRoute: "/muuvexpress/authentication/password",
  forgotPwd: "/muuvexpress/authentication/forgetpassword",
  get_one_trip: "/muuvexpress/trip/retrieve",

  //post routes
  create_schedule: "/muuvexpress/trip/schedule",
  create_trip: "/muuvexpress/trip",
  create_initial_price: "/muuvexpress/price/initial-price",
  save_rider: "/muuvexpress/driver/save",
  initiate_deposit: "/muuvexpress/payment/transaction",
  check_price: "/muuvexpress/price/new-calculate",

  //delete routes
  delete_trip: "/muuvexpress/trip",
  delete_pending_payment: "/muuvexpress/payment/current-pending",
  delete_pending_approval: "/muuvexpress/payment/current-mark-as-paid",

  // get route
  get_all_countries: "/muuvexpress/country",
  get_user_details: "/muuvexpress/user/details",
  get_trip_status: "/muuvexpress/trip/status",
  get_cancel_reasons: "/muuvexpress/cancel/cancelreason",
  get_all_trips: "/muuvexpress/trips-user",
  get_all_chats: "/muuvexpress/chat/driver",
  get_all_saved_riders: "/muuvexpress/driver/save/all",
  get_driver_locations: "/muuvexpress/driver/locations/all",
  get_wallet_info: "/muuvexpress/wallet/all",
  get_wallet_payments: "/muuvexpress/payment/payment-desc/all",
  get_payment_providers: "/muuvexpress/payment/payment-desc/all",
  get_pending_payments: "/muuvexpress/payment/current-pending",
  get_pending_approval: "/muuvexpress/payment/current-mark-as-paid",

  //put routes
  get_directions: "/muuvexpress/maps/direction",
  apply_initial_price: "/muuvexpress/price/initial-price-apply",
  mark_payment_as_paid: "/muuvexpress/payment/mark-as-paid",

  //general routes
  file_upload: "/muuvexpress/file/upload",
  add_company_details: "/muuvexpress/company",
  addresses_route: "/muuvexpress/address",
  routes: "/muuvexpress/routes",
  send_email: "/muuvexpress/email",
  send_sms: "/muuvexpress/sms",
};
