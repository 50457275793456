import { useState, useEffect } from 'react'
import BaseService from '../../helpers/baseServices'
import { global_variables } from '../../helpers/globalVariables'
import Select from 'react-select';

interface selectProps {
    onChange: any
}

const RiderSelector = ({ onChange }: selectProps) => {

    const [allFeatures, setAllFeatures] = useState([])
    const [isFetching, setIsFetching] = useState(false)

    useEffect(() => {
        const getFeatures = async () => {
            setIsFetching(true)
            try {
                const response = await BaseService.get_api(`${global_variables.get_all_saved_riders}`)
                console.log(response?.data?.payload)
                const arrangedData = response?.data?.payload.map((dd: any) => (
                    { value: dd?.driverData?.[0]?._id, label: dd?.driverData?.[0]?.fullName }
                ))
                setAllFeatures(arrangedData)
                setIsFetching(false)
            } catch (error) {
                console.log(error)
            }
        }

        getFeatures()
    }, [])

    return (
        <>
            <Select
                className="basic-single"
                placeholder="Search rider name"
                isDisabled={isFetching}
                isLoading={isFetching}
                isClearable={true}
                isSearchable={true}
                options={allFeatures}
                onChange={onChange}
            />
        </>
    )
}

export default RiderSelector
