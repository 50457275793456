import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Avatar,
  Typography,
} from "@material-tailwind/react";
import {
  BriefcaseIcon,
  Cog6ToothIcon,
  PowerIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { StorageBox } from "../../helpers/stroage";
import { useEffect, useState } from "react";
import BaseService from "../../helpers/baseServices";
import { global_variables } from "../../helpers/globalVariables";

export default function UserProfile() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState<any>(null)

  //onload
  useEffect(() => {
    fetchUserDeatils()
  }, [])

  //fetch user details
  const fetchUserDeatils = async () => {
    try {
      const response = await BaseService.get_api(`${global_variables.get_user_details}`)
      setUserData(response?.data?.payload?.user_data)
      // console.log(response?.data?.payload?.user_data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleLogout = () => {
    StorageBox.clearStorage();
    navigate("/");
  };

  return (
    <Menu placement="bottom-end">
      <MenuHandler>
        <div className="flex gap-4 items-center bg-midGreen bg-opacity-[10%] rounded-[10px] px-4 py-2 cursor-pointer">
          <Avatar
            variant="circular"
            alt="..."
            className="border-[1px] border-midGreen border-dashed"
            src={userData?.image || 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png'}
          />
          <div className="hidden lg:block">
            <Typography className="font-semibold mb-0">{userData?.fullName}</Typography>
            <Typography>{userData?.phone}</Typography>
          </div>
        </div>
      </MenuHandler>
      <MenuList>
        <MenuItem
          className="flex items-center gap-2"
          onClick={() => navigate("/myprofile")}
        >
          <Cog6ToothIcon strokeWidth={2} className="h-4 w-4" />
          <Typography variant="small" className="font-normal">
            Edit Profile
          </Typography>
        </MenuItem>
        <MenuItem
          className="flex items-center gap-2"
          onClick={() => navigate("/myCompany")}
        >
          <BriefcaseIcon strokeWidth={2} className="h-4 w-4" />
          <Typography variant="small" className="font-normal">
            My Company
          </Typography>
        </MenuItem>
        <hr className="my-2 border-blue-gray-50" />
        <MenuItem
          className="flex items-center gap-2 text-red-500"
          onClick={() => handleLogout()}
        >
          <PowerIcon strokeWidth={2} className="h-4 w-4" />
          <Typography variant="small" className="font-normal">
            Sign Out
          </Typography>
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
