import { useState, useEffect } from 'react'
import { PaperAirplaneIcon } from '@heroicons/react/24/outline'
import BaseService from '../../../../helpers/baseServices'
import { global_variables } from '../../../../helpers/globalVariables'
import { Spinner } from '@material-tailwind/react'
import { dummyChats } from './dummyChats'
import { ChatPayload } from './chat.model'
import { StorageBox } from '../../../../helpers/stroage'
import moment from 'moment'
import { TripModel } from '../../trips.model'
import socketConnection from '../../../../sockets/connection'

interface shippingProps {
    shippingData: TripModel
}

const LiveChat = ({ shippingData }: shippingProps) => {

    const socketChannel = StorageBox.getSocketChannel()
    const adminId = StorageBox.retrieveUserData().user_data.userid;
    const [isLoadingChats, setIsLoadingChats] = useState(false)
    const [previousChats, setPreviousChats] = useState([])
    const [dMessage, setDMessage] = useState("");
    const [typingStatus, setTypingStatus] = useState("");

    const [currentChat, setCurrentChat] = useState([]);

    //onload
    useEffect(() => {
        //getPreviousChats
        const getPreviousChats = async () => {
            setIsLoadingChats(true)
            try {
                const response = await BaseService.get_api(`${global_variables.get_all_chats}/${shippingData?._id}`)
                console.log(response?.data?.payload)
                setPreviousChats(response?.data?.payload)
                setIsLoadingChats(false)
                if (response?.status === 200) {
                    listenToIncomingChats()
                }
            } catch (error) {
                console.log(error)
            }
        }

        getPreviousChats()
    }, [shippingData])



    //Listen to chats channel
    const listenToIncomingChats = () => {
        console.log("listening to chats...")
        // listen for typing status
        socketConnection.socket.on(
            `chat/${shippingData?._id}/${shippingData?.driverId}`,
            (response) => {
                console.log("incomingChatRes:", response);
                setTypingStatus(response.message);
                setTimeout(() => {
                    setTypingStatus("");
                }, 3000);
            }
        );

        //listen to chat channel
        socketConnection.socket.on(
            `chat/${shippingData?._id}/message`,
            (response) => {
                console.log("incomingChatRes2:", response);
                //append to current chat (if id is my id do not append to current chat)
                if (response?.payload?.data?.chatMeta?.userid !== adminId) {
                    var tempArr: any = previousChats;
                    tempArr.push(response?.payload?.data);
                    setPreviousChats(tempArr);
                }
            }
        );
    }




    //handleOnInputChange when typing
    const handleOnInputChange = (e: any) => {
        setDMessage(e.target.value);

        socketConnection.socket.emit(`${socketChannel}/core/typing-broadcast`, {
            specialId: shippingData?._id,
            userid: adminId,
        });
    };

    //handleSendMessage
    const handleSendMessage = (e: any) => {
        e.preventDefault();
        var today = new Date();
        // console.log(adminId);

        var msgData = {
            chatData: {
                message: dMessage,
            },
            chatMeta: {
                userid: adminId,
                specialId: shippingData?._id,
            },
            userid: adminId,
        };

        var msgData2 = {
            chatData: {
                message: dMessage,
            },
            chatMeta: {
                userid: adminId,
                specialId: shippingData?._id,
            },
            userid: adminId,
            createdAt: today.toISOString(),
        };

        let tempArr: any = previousChats;
        tempArr.push(msgData2);
        setPreviousChats(tempArr);
        setDMessage("");
        // console.log(currentChat);

        socketConnection.socket.emit(`${socketChannel}/core/save-chat`, msgData);
    };

    return (
        <>
            {
                isLoadingChats ? <div className='flex justify-center items-center h-full'>
                    <Spinner />
                </div>
                    :
                    <div className='overflow-hidden bg-[#1e7e7108] h-full relative'>
                        {/* header */}
                        <div className='p-3 bg-gray-800 h-[70px] flex justify-between items-center'>
                            <div className='flex gap-4 items-center'>
                                <img src={shippingData?.DriverData?.[0]?.image || "https://www.nicepng.com/png/detail/136-1366211_group-of-10-guys-login-user-icon-png.png"} alt="profile" className='h-[50px] w-[50px] rounded-full object-cover' />
                                <div className='text-white'>
                                    <p className="font-semibold">{shippingData?.DriverData?.[0]?.fullName}</p>
                                    <small className='italic font-normal mt-0'>{typingStatus}</small>
                                </div>
                            </div>
                        </div>

                        {/* chats */}
                        <div className='h-full overflow-y-auto px-3 pb-[150px]'>
                            {
                                previousChats.length === 0 ?
                                    <>
                                        <div>

                                        </div>
                                    </>
                                    :
                                    <>
                                        {previousChats.map((message: ChatPayload, index) => (
                                            <div
                                                key={index}
                                                className={`flex gap-2 items-center justify-${message?.chatMeta?.userid === adminId ? 'end' : 'start'
                                                    }`}
                                            >
                                                <div
                                                    className={`p-3 my-3 ${message?.chatMeta?.userid === adminId
                                                        ? 'bg-gradient-to-r from-lightGreen to-midGreen text-white rounded-t-[20px] rounded-bl-[20px]'
                                                        : 'bg-gradient-to-r from-gray-600 to-gray-800 text-white rounded-t-[20px] rounded-br-[20px]'
                                                        } w-[400px]`}
                                                >
                                                    <p>{message?.chatData?.message}</p>
                                                    <small className='text-[10px] text-gray-400 flex justify-end'>{moment(message?.createdAt).format("hh:mm A")}</small>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                            }
                        </div>


                        {/* footer */}
                        <div className='p-3 bg-gray-800 h-[60px] absolute bottom-0 w-full'>
                            <div>
                                <form onSubmit={handleSendMessage} className='flex justify-between gap-3 items-center'>
                                    <input value={dMessage}
                                        onChange={handleOnInputChange} className='w-full h-[40px] px-3 bg-transparent placeholder:text-gray-500 text-white focus:outline-none' placeholder='Type a message...' autoFocus />
                                    <button type='submit'>
                                        <PaperAirplaneIcon className='h-5 w-5 text-white' />
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default LiveChat