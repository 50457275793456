import { useState, useEffect, useContext } from "react";
import { Collapse } from "@material-tailwind/react";
import UserProfile from "../general/profile";
import { Bars3CenterLeftIcon, MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";
import SideMenuContext from "../../context/GeneralContext";

const CustomNavbar = () => {
  const [openNav, setOpenNav] = useState(false);
  const { triggerMenu, handleMenuClick } = useContext(SideMenuContext);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  return (
    <div className="w-full px-4 py-2 shadow-md">
      <div className="flex items-center justify-between text-blue-gray-900">
        {/* left */}
        <div className="w-[400px] relative hidden md:block">
          <input
            className="h-[40px] px-3 w-full focus:outline-none bg-midGreen bg-opacity-[5%]"
            placeholder="What are you looking for?"
          />
          <MagnifyingGlassIcon className="h-5 w-5 absolute right-3 bottom-[10px] text-midGreen" />
        </div>

        <button className="lg:hidden" onClick={() => handleMenuClick()}>
          {
            triggerMenu ?
              <Bars3CenterLeftIcon
                className="h-8 w-8 cursor-pointer"
              />
              :
              <XMarkIcon
                className="h-8 w-8 cursor-pointer"
              />
          }
        </button>

        {/* middle */}
        <div className="hidden lg:block"></div>

        {/* right */}
        <div className="flex gap-4 items-center">
          {/* <UserNotifications /> */}
          <UserProfile />
        </div>
      </div>

      {/* on mobile */}
      <Collapse open={openNav}>...</Collapse>
    </div>
  );
};

export default CustomNavbar;
