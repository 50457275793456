export function formatPhoneNumber(phoneNumber: string): string {
  if (phoneNumber.startsWith("+233")) {
    // Phone number starts with +233, return as it is
    return phoneNumber;
  } else if (phoneNumber.startsWith("233")) {
    // Phone number starts with 233, add a + before it
    return `+${phoneNumber}`;
  } else if (phoneNumber.startsWith("0")) {
    // Phone number starts with 0, replace it with +233
    return `+233${phoneNumber.substr(1)}`;
  } else {
    // For any other case, return the original number (no formatting)
    return "Invalid phone number";
  }
}

export const toTwoDecimalPlaces = (val: any) => {
  return parseFloat(val).toFixed(2);
};

export const removeBlankSpaces = (string: any) => {
  return string.replace(/\s+/g, "");
};

export const getTodaysDateRange = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const startOfDayISOString = today.toISOString();

  const endOfDay = new Date(today);
  endOfDay.setHours(23, 59, 59, 999); // 999 milliseconds to ensure end of the day

  // Convert to ISOString format
  const endOfDayISOString = endOfDay.toISOString();

  const todayDateRange = {
    start: startOfDayISOString,
    end: endOfDayISOString,
  };

  return todayDateRange;
};

export const getYesterdayDateRange = () => {
  const today = new Date();
  // Calculate the date for yesterday
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  yesterday.setHours(0, 0, 0, 0);

  const startOfDayISOString = yesterday.toISOString();

  const endOfDay = new Date(yesterday);
  endOfDay.setHours(23, 59, 59, 999); // 999 milliseconds to ensure end of the day

  // Convert to ISOString format
  const endOfDayISOString = endOfDay.toISOString();

  const yesterDayDateReange = {
    start: startOfDayISOString,
    end: endOfDayISOString,
  };

  return yesterDayDateReange;
};

export const getThisMonthRange = () => {
  const today = new Date();

  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  firstDayOfMonth.setHours(0, 0, 0, 0);

  const startOfMonthISOString = firstDayOfMonth.toISOString();

  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  lastDayOfMonth.setHours(23, 59, 59, 999);

  const endOfMonthISOString = lastDayOfMonth.toISOString();

  const monthDateRange = {
    start: startOfMonthISOString,
    end: endOfMonthISOString,
  };

  return monthDateRange;
};
