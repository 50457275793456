import { useState } from 'react';
import moment from 'moment'
import { Stepper, Step } from "@material-tailwind/react";
import { HomeIcon, CogIcon, UserIcon, CheckIcon, MapPinIcon } from "@heroicons/react/24/outline";

const CardOnMap = ({status, timestamp,activeStep}:{status:string, timestamp:string|null, activeStep:number}) => {

    //const [activeStep, setActiveStep] = useState(0);
    const [isLastStep, setIsLastStep] = useState(false);
    const [isFirstStep, setIsFirstStep] = useState(false);

   // const [currStage, setCurrStage] = useState(status)

    return (
        <>
            <div className="py-5 px-8 rounded-[20px] bg-gradient-to-r from-lightGreen to-midGreen h-[150px] text-white">
                <div className="w-full">
                    <Stepper
                        activeStep={activeStep}
                        isLastStep={(value) => setIsLastStep(value)}
                        isFirstStep={(value) => setIsFirstStep(value)}
                        lineClassName="bg-white/50"
                        activeLineClassName="bg-white"
                    >
                        <Step className='text-midGreen' activeClassName='bg-white' completedClassName='bg-white'>
                            <HomeIcon className="h-5 w-5" />
                        </Step>
                        <Step className='text-midGreen' activeClassName='bg-white' completedClassName='bg-white'>
                            <MapPinIcon className="h-5 w-5" />
                        </Step>
                        <Step className='text-midGreen' activeClassName='bg-white' completedClassName='bg-white'>
                            <CheckIcon className="h-5 w-5" />
                        </Step>
                    </Stepper>
                </div>

                <div className='mt-5'>
                    <p className='font-semibold text-xl'>{status}</p>
                    {timestamp!=null&& timestamp!=undefined?<small>{moment(timestamp).format("Do MMM YYYY - hh:mm A")}</small>:<div></div>}
                </div>
            </div>
        </>
    )
}

export default CardOnMap
