import { useState, useEffect } from 'react'
import { global_variables } from '../../helpers/globalVariables'
import Select from 'react-select';
import { BASE_URL } from '../../helpers/constants';
import axios from 'axios';

interface selectProps {
    onChange: any
}

const CountrySelector = ({ onChange }: selectProps) => {

    const [allStatus, setAllStatus] = useState([])
    const [isFetching, setIsFetching] = useState(false)

    useEffect(() => {
        const getAllRegions = async () => {
            setIsFetching(true)

            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${BASE_URL}${global_variables.get_all_countries}?limit=1000000`,
                headers: {},
                data: ''
            };

            try {
                const response = await axios.request(config)
                // console.log(response?.data?.payload)
                const arrangedData = response?.data?.payload.map((dd: any) => (
                    { value: dd, label: dd?.country }
                ))
                setAllStatus(arrangedData)
                setIsFetching(false)
            } catch (error) {
                console.log(error)
            }
        }

        getAllRegions()
    }, [])

    return (
        <>
            <Select
                className="basic-single"
                placeholder="Select Country"
                isDisabled={isFetching}
                isLoading={isFetching}
                isClearable={true}
                isSearchable={true}
                options={allStatus}
                onChange={onChange}
            />
        </>
    )
}

export default CountrySelector
