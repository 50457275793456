import { useEffect, useState } from "react";
import { Avatar, Drawer, Input, Option, Select, Spinner, Textarea } from "@material-tailwind/react";
import ImageUploader from "../../components/general/imageUploader";
import PhoneInput from "react-phone-number-input";
import BaseService from "../../helpers/baseServices";
import { global_variables } from "../../helpers/globalVariables";
import { CheckTripPrice, CreateTripFunction, DeleteTripFunction, GetDirectionsFunction } from "./functions/createTrip";
import LoadingComponent from "./loadingComponent";
import TripMapComponent from "./requestMapComponent";
import riderImg from "../../images/motoImg.png"
import socketConnection from "../../sockets/connection";
import { StorageBox } from "../../helpers/stroage";
import noRiderimg from "../../images/noRiderImg.svg"
import { TripAcceptedData } from "./tripAccepted.model";
import RoutesSelector from "../schedules/routesSelector";
import { handleLocationDecode } from "../../functions/decodeLocation";
import LocationSelector from "../../components/general/locationSelector";
import { FaPlus } from "react-icons/fa6";
import { BsTrash } from "react-icons/bs";

interface drawerProps {
  isOpened: boolean
  handleClose: () => void
}

const CreateRequestDrawer = ({ isOpened, handleClose }: drawerProps) => {

  const selectedCountry = StorageBox.getSavedCountry()

  const userData = StorageBox.retrieveUserData()
  const socketChannel = StorageBox.getSocketChannel()
  const [tripStep, setTripStep] = useState(0)
  const [isCreatingRequest, setIsCreatingRequest] = useState(false)
  const [isBusy, setIsBusy] = useState(false)
  const [theQuantity, setTheQuantity] = useState("")
  const [theWeight, setTheWeight] = useState<any>(0)
  const [theUnit, setTheUnit] = useState("")
  const [pkgDescription, setPkgDescription] = useState("")
  const [theNotes, setTheNotes] = useState("")
  const [pickContact, setPickContact] = useState("")
  const [deliveryContact, setDeliveryContact] = useState("")
  const [calculatedPrice, setCalculatedPrice] = useState<any>(null)
  const [directionsData, setDirectionsData] = useState<any>(null)
  const [activeSelection, setActiveSelection] = useState<any>(null)
  const [selectedClass, setselectedClass] = useState<any>(null)
  const [useMySavedLocations, setUseMySavedLocations] = useState(false)



  //pickup states
  const [pickLat, setPickLat] = useState<any>(0)
  const [pickLong, setPickLong] = useState<any>(0)
  const [pickPlaceName, setPickPlaceName] = useState("")
  const [pickRegion, setPickRegion] = useState("")
  const [pickCity, setPickCity] = useState("")
  //drop states
  const [dropLat, setDropLat] = useState<any>(0)
  const [dropLong, setDropLong] = useState<any>(0)
  const [dropPlaceName, setDropPlaceName] = useState("")
  const [dropCity, setDropCity] = useState("")
  const [dropRegion, setDropRegion] = useState("")

  //image states
  const [imageUrl, setImageUrl] = useState("")
  const [uploadedImages, setUploadedImages] = useState([])
  const [isError, setIsError] = useState(false)
  const [theTripId, setTheTripId] = useState("")
  const [findingRider, setFindingRider] = useState(false)

  //found rider
  const [foundRiderData, setFoundRiderData] = useState<TripAcceptedData>()
  const [foundRiderMsg, setFoundRiderMsg] = useState("")
  const [foundRiderStatus, setFoundRiderStatus] = useState<any>(null)

  //states for will be at check
  const [willbeAtPickup, setWillbeAtPickup] = useState(true)
  const [willbeAtDropOff, setWillbeAtDropOff] = useState(true)

  //adding stops
  const [showStops, setShowStops] = useState(false);
  const [theDrops, setTheDrops] = useState<any>([]);

  //onload
  useEffect(() => {

    return () => {
      //stop socket listeners
      stopListeners();
    }

  }, [])

  //listen to listeners that are dependent on tripId when tripId is set
  //this is to prevent listening to an empty tripId
  useEffect(() => {
    if (theTripId.length > 0) {

      socketConnection.socket.on(`tripRequest/${theTripId}`, (findDriverRes) => {
        console.log("findDriverRes:", findDriverRes);
        setFindingRider(false)
        setFoundRiderStatus(findDriverRes?.success)
        //check if rider was found
        if (findDriverRes?.success === false) {
          setFoundRiderData(findDriverRes?.payload)
          setFoundRiderMsg(findDriverRes?.message)
        }
        else {
          setFoundRiderData(findDriverRes?.payload)
          setFoundRiderMsg(findDriverRes?.message)
        }

      });

      //listen to trip events
      socketConnection.socket.on(`${theTripId}/events`, (eventsRes) => {
        console.log("eventsRes:", eventsRes);
        setFoundRiderMsg(eventsRes?.payload?.current_event?.name)
      });


    }

  }, [theTripId])

  const stopListeners = () => {
    //stop trip request listener
    socketConnection.socket.off(`tripRequest/${theTripId}`);
    //stop event listener
    socketConnection.socket.off(`${theTripId}/events`);
    //stop location listener

  }


  // handle pikup slected
  const handlePickUpSlected = (selected: any) => {
    if (selected) {
      console.log(selected.value);
      handleLocationDecode(selected).then((response) => {
        const { latitude, longitude, placeName, region, city } = response;
        setPickLat(latitude);
        setPickLong(longitude);
        setPickCity(city)
        setPickRegion(region)
        setPickPlaceName(placeName);
      }).catch((error) => {
        console.error("An unexpected error occurred:", error);
      });
    } else {
      console.log("No option selected");
      setPickPlaceName("");
    }
  };

  // handle destination slected
  const handleDropOffSlected = (selected: any) => {
    if (selected) {
      console.log(selected.value);
      handleLocationDecode(selected).then((response) => {
        const { latitude, longitude, placeName, region, city } = response;
        setDropLat(latitude);
        setDropLong(longitude);
        setDropCity(city)
        setDropPlaceName(region)
        setDropPlaceName(placeName);
      }).catch((error) => {
        console.error("An unexpected error occurred:", error);
      });
    } else {
      console.log("No option selected");
      setDropPlaceName("");
    }
  };

  // Add a new stop to the stops state
  const handleAddStop = () => {
    setShowStops(true);
    const newDrops = { id: theDrops.length + 1, location: { lat: '', lng: '' } };
    setTheDrops([...theDrops, newDrops]);
  };

  //stop loation select
  const handleStopLocationChange = (index: number, selected: any) => {
    // Update the location for the specified stop
    handleLocationDecode(selected).then((response) => {
      const { latitude, longitude, placeName, city, region } = response;
      const updatedDrops = [...theDrops];
      updatedDrops[index].location.lat = latitude;
      updatedDrops[index].location.lng = longitude;
      updatedDrops[index].location.place = placeName;
      updatedDrops[index].location.city = city;
      updatedDrops[index].location.region = region;
      setTheDrops(updatedDrops);
    }).catch((error) => {
      console.error("An unexpected error occurred:", error);
    });
  };

  const handleDeleteStop = (index: number) => {
    const updatedStops = theDrops.filter((stop: any, i: number) => i !== index);
    setTheDrops(updatedStops);
  };

  //handle on image select
  const onFirstImgSelect = async (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setImageUrl(imageUrl);

      if (uploadedImages.length < 3) {
        setIsBusy(true)
        const uploadResponse: any = await BaseService.file_upload(`${global_variables.file_upload}?imagename=${file?.name}&storage=testfolder`, file)
        console.log(uploadResponse)
        var tempArr: any = uploadedImages
        tempArr.push(uploadResponse?.data?.url)
        setUploadedImages(tempArr)
        setIsBusy(false)
        setImageUrl("")
      }
      else {
        console.log("Upload limit exceeded!")
      }
    }
  }

  //handle create request start
  const handleFirstStep = async () => {

    if (!pickPlaceName || !dropPlaceName || !theQuantity || !theWeight || !theUnit || !theNotes) {
      setIsError(true)
    }
    else {
      setIsCreatingRequest(true)

      //get distance and time
      try {
        let directionsData = JSON.stringify({
          "destinationLat": dropLat,
          "destinationLng": dropLong,
          "originLat": pickLat,
          "originLng": pickLong,
          "type": "direction"
        })

        const directionResponse: any = await GetDirectionsFunction(directionsData);
        console.log(directionResponse?.data?.payload)
        setDirectionsData(directionResponse?.data?.payload?.routes?.[0]?.legs)
        const theDistance = directionResponse?.data?.payload?.routes?.[0]?.legs?.[0]?.distance?.value
        const theTime = directionResponse?.data?.payload?.routes?.[0]?.legs?.[0]?.duration?.value

        console.log("theRoutes:", directionResponse?.data?.payload?.routes)

        //create initial price
        if (directionResponse?.status === 200) {
          try {
            const postData = {
              "city": "N/A",
              "country": selectedCountry?._id,
              "drops": theDrops.length !== 0 ? [
                {
                  "lat": pickLat,
                  "lng": pickLong
                },
                {
                  "lat": dropLat,
                  "lng": dropLong
                },
                ...theDrops.map((drop: any) => (
                  { lat: drop?.location?.lat, lng: drop?.location?.lng }
                ))
              ]
                :
                [
                  {
                    "lat": pickLat,
                    "lng": pickLong
                  },
                  {
                    "lat": dropLat,
                    "lng": dropLong
                  }
                ]
            }

            CheckTripPrice(postData).then((response: any) => {
              console.log("priceResponse:", response)
              if (response?.data?.payload?.amount_classes) {
                setCalculatedPrice(response?.data?.payload)
              }
              setTripStep(1)
              setIsCreatingRequest(false)
            }).catch((error) => {
              console.log("checkPriceError:", error)
              setIsCreatingRequest(false)
            })

          } catch (error) {
            console.log("initalError:", error)
          }

        }

      } catch (error) {
        console.log("directionsError:", error)
      }
    }

  }

  //create trip and appply price
  const handleOnContinue = async () => {
    //create trip body
    let data = {
      "country": selectedCountry?._id,
      "ownerType": "company",
      "pickup": {
        "city": pickCity,
        "region": pickRegion,
        "place": pickPlaceName,
        "latitude": pickLat,
        "longitude": pickLong
      },
      "destination": {
        "region": dropRegion,
        "place": dropPlaceName,
        "city": dropCity,
        "latitude": dropLat,
        "longitude": dropLong
      },
      "drops": theDrops.length !== 0 ? [
        {
          "lat": pickLat,
          "lng": pickLong,
          "place": pickPlaceName,
          "contact": pickContact
        },
        {
          "lat": dropLat,
          "lng": dropLong,
          "place": pickPlaceName,
          "contact": pickContact
        },
        ...theDrops.map((drop: any) => (
          { lat: drop?.location?.lat, lng: drop?.location?.lng, "place": pickPlaceName, "contact": pickContact }
        )),
      ]
        :
        [
          {
            "lat": pickLat,
            "lng": pickLong,
            "place": pickPlaceName,
            "contact": pickContact
          },
          {
            "lat": dropLat,
            "lng": dropLong,
            "place": pickPlaceName,
            "contact": pickContact
          }
        ],
      "quantity": parseInt(theQuantity),
      "weight": parseFloat(theWeight),
      "weightUnit": theUnit,
      "itemDescription": pkgDescription,
      "images": uploadedImages,
      "express": false,
      "notes": theNotes,
      "contactData": {
        "pickUpContact": willbeAtPickup ? userData?.user_data?.phone : pickContact,
        "deliveryContact": willbeAtDropOff ? userData?.user_data?.phone : deliveryContact,
      }
    }

    if (uploadedImages.length === 0) {
      delete (data as any)['images']
    }

    setFindingRider(true)

    try {
      const createResponse: any = await CreateTripFunction(JSON.stringify(data))
      console.log("createResponse:", createResponse?.data)
      setTheTripId(createResponse?.data?.payload?._id)

      if (createResponse?.status === 200) {
        setTripStep(2)

        //find driver if socket is on
        if (socketConnection.socket.active) {
          const findDriverData = {
            "tripId": createResponse?.data?.payload?._id,
            "longitude": pickLong,
            "latitude": pickLat,
            "country": selectedCountry?._id
          };

          socketConnection.socket.emit(`${socketChannel}/core/request`, findDriverData)
          // socketConnection.socket.on(`${socketChannel}/core/request`, (findDriverRes) => {
          //   console.log("findDriverRes:", findDriverRes);
          //   setFindingRider(false)
          //   setFoundRiderStatus(findDriverRes?.success)
          //   //check if rider was found
          //   if (findDriverRes?.success === false) {
          //     setFoundRiderData(findDriverRes?.payload)
          //     setFoundRiderMsg(findDriverRes?.message)
          //   }
          //   else {
          //     setFoundRiderData(findDriverRes?.payload)
          //     setFoundRiderMsg(findDriverRes?.message)
          //   }


          //   if (findDriverRes?.payload?.accepted === true) {
          //     socketConnection.socket.on(`${createResponse?.data?.payload?._id}/events`, (eventsRes) => {
          //       console.log("eventsRes:", eventsRes);
          //       setFoundRiderMsg(eventsRes?.payload?.current_event?.name)
          //     })
          //   }

          // })


        }
      }
    } catch (error) {
      console.log("createTripError:", error)
    }
  }

  //handling try again
  const tryAgainMethod = () => {

    const findDriverData = {
      "tripId": theTripId,
      "longitude": pickLong,
      "latitude": pickLat,
      "country": selectedCountry?._id
    };

    socketConnection.socket.emit(`${socketChannel}/core/request`, findDriverData);

  }

  //handle selectClass
  const handleSelectedClass = (data: any, index: number) => {
    setselectedClass(data)
    setActiveSelection(index)
    console.log(data)
    console.log(index)
  }

  //handle check for will be at pickup
  const handlePickCheck = (e: any) => {
    console.log(e.target.checked)
    setWillbeAtPickup(e.target.checked)
  }
  const handleDropCheck = (e: any) => {
    console.log(e.target.checked)
    setWillbeAtDropOff(e.target.checked)
  }

  //peroformDeleteTrip
  const peroformDeleteTrip = (tripId: any) => {
    let delBody = JSON.stringify({
      "_id": tripId
    })

    DeleteTripFunction(delBody)

    handleClose()

  }


  //handleRouteSelected
  const handleRouteSelected = (selected: any) => {
    if (selected) {
      console.log(selected)

      //for pickup
      setPickLat(selected?.pickup?.latitude);
      setPickLong(selected?.pickup?.longitude);
      setPickCity(selected?.pickup?.city)
      setPickRegion(selected?.pickup?.region)
      setPickPlaceName(selected?.pickup?.place);

      //for destination
      setDropLat(selected?.destination?.latitude);
      setDropLong(selected?.destination?.longitude);
      setDropCity(selected?.destination?.city)
      setDropRegion(selected?.destination?.region)
      setDropPlaceName(selected?.destination?.place);
    }
    else {
      console.log("No option selected");
      setPickPlaceName("");
      setDropPlaceName("")
    }
  }

  return (
    <>
      <Drawer
        size={700}
        placement="right"
        open={isOpened}
        className="py-4"
      >
        <div className="mb-4 flex items-center justify-between px-7">
          <div className="">
            <p className="font-semibold text-xl">Place An Order</p>
          </div>
        </div>

        <hr className="my-3" />

        {
          isCreatingRequest ?
            <div className="flex flex-col justify-center items-center h-full">
              <LoadingComponent />
              <p className="mt-2">Please wait...</p>
            </div>

            : tripStep === 0 ?
              // step 0
              <div className="h-full overflow-y-auto pb-20">
                <div className="mx-5">
                  {/* pick up and drop off */}
                  <div className="flex gap-3 items-center my-3">
                    <input type="checkbox" className="h-5 w-5" defaultChecked={useMySavedLocations} onChange={(e: any) => setUseMySavedLocations(e.target.checked)} />
                    <small>Use my saved locations</small>
                  </div>

                  {useMySavedLocations ?
                    <RoutesSelector onChange={(selected: any) => handleRouteSelected(selected?.value)} />
                    :
                    <div className="p-5 rounded-[20px] shadow-sm bg-white border border-gray-400 mt-3">
                      <div>
                        <small className={`${isError ? 'text-red-500' : ''}`}>Pickup</small>
                        <LocationSelector placeholder="Type to search" onChange={(selected) => handlePickUpSlected(selected)} />
                      </div>

                      {/* add stop */}
                      <div className="flex justify-end my-3">
                        <button className='flex gap-1 items-center' onClick={handleAddStop}>
                          <FaPlus className='h-4 w-4' />
                          <p className='text-xs'>Add Stop</p>
                        </button>
                      </div>

                      {/* Render LocationSelectors for each stop */}
                      {showStops &&
                        theDrops.map((stop: any, index: number) => (
                          <div key={stop.id} className='mt-2 flex gap-2 items-center'>
                            {/* Add your stop visual indicator here */}
                            <div className='h-3 w-3 bg-yellow-500'></div>
                            <div className='w-full'>
                              <LocationSelector
                                onChange={(selected) => handleStopLocationChange(index, selected)}
                                placeholder={`Search Stop ${index + 1} Location`}
                                disabled={isBusy}
                              />
                            </div>
                            {/* Delete Stop button */}
                            <button className='text-red-500' onClick={() => handleDeleteStop(index)}>
                              <BsTrash className='w-5 h-5' />
                            </button>
                          </div>
                        ))}

                      <div className="mt-3">
                        <small className={`${isError ? 'text-red-500' : ''}`}>Destination</small>
                        <LocationSelector placeholder="Type to search" onChange={(selected) => handleDropOffSlected(selected)} />
                      </div>
                    </div>
                  }

                  {/* qty weight and unit */}
                  <div className="mt-5 ">
                    <Input type="number" label="Quantity" value={theQuantity} onChange={(e) => setTheQuantity(e.target.value)} error={isError} />
                    <div className="flex justify-between mt-5 gap-3">
                      <div className="w-full">
                        <Input type="number" label="Weight" value={theWeight} onChange={(e) => setTheWeight(e.target.value)} error={isError} />
                      </div>
                      <div className="w-full">
                        <Select label="Unit" onChange={(e: any) => setTheUnit(e)} error={isError}>
                          <Option value="Kg">Kilogram (s)</Option>
                          <Option value="g">Gram (s)</Option>
                        </Select>
                      </div>
                    </div>
                  </div>

                  {/* description */}
                  <div className="mt-5">
                    <small>Description is required if no weight and quantity is provided</small>
                    <div className="mt-2">
                      <Textarea label="Package description" value={pkgDescription} onChange={(e) => setPkgDescription(e.target.value)} />
                    </div>
                  </div>

                  {/* image upload */}
                  <div className="mt-5">
                    <small>Images <span className="text-midGreen">(Not required)</span></small>
                    <div className="lg:grid lg:grid-cols-3 lg:gap-5">
                      <div className="col-span-2">
                        <ImageUploader id="img1" selectedImgUrl={imageUrl} onChange={onFirstImgSelect} />
                      </div>
                      {
                        isBusy ?
                          <div className="flex justify-center items-center">
                            <Spinner />
                          </div>
                          :
                          <div className="w-full flex flex-col gap-y-2">
                            {
                              uploadedImages.map((theImg: any, i: number) => (
                                <img key={i} src={theImg} alt={'img' + i} className="h-[60px] w-full object-cover" />
                              ))
                            }
                          </div>
                      }
                    </div>
                  </div>

                  {/* delivery option */}
                  {/* <div className="mt-5">
                    <small>Choose Delivery Option</small>
                    <div className="h-auto bg-white shadow-lg flex items-center gap-[30px] w-fit rounded-[30px] p-2 border border-gray-500 mt-3">
                      {
                        ["Standard", "Express"].map((dd, i) => (
                          <div key={i} className={`${activeTab === i ? 'bg-midGreen text-white w-fit' : ''} px-5 py-2 rounded-[30px] w-fit cursor-pointer`} onClick={() => {
                            setActiveTab(i)
                            i === 1 && setIsExpress(true)
                          }}>
                            {dd}
                          </div>
                        ))
                      }
                    </div>
                    {
                      activeTab === 0 ?
                        <div className="p-5 bg-white shadow-lg rounded-[20px] text-midGreen mt-5">
                          <p>Standard delivery provides reliable shipping at an economical rate. Your items will be delivered ithin the estimated timeframe, ensuring safe arrival while keeping costs low.</p>
                        </div>
                        :
                        <div className="p-5 bg-white shadow-lg rounded-[20px] text-midGreen mt-5">
                          <p>Express delivery offers the fastes shipping option for time-sensitive items. Get your order delivered swiftly, often withing hours, to ensure you receive your items promptly and conveniently.</p>
                        </div>
                      // :
                      // <div className="p-5 bg-white shadow-lg rounded-[20px] mt-5">
                      //   <div>
                      //     <Input type="datetime-local" label="Schedule date & time" value={scheduleDate} onChange={(e) => setScheduleDate(e.target.value)} />
                      //   </div>
                      //   <div className="mt-4">
                      //     <Input type="datetime-local" label="Expiry date & time" value={expiryDate} onChange={(e) => setExpiryDate(e.target.value)} />
                      //   </div>
                      // </div>
                    }
                  </div> */}

                  {/* notes for rider */}
                  <div className="mt-5">
                    <Textarea label="Notes for rider" value={theNotes} onChange={(e) => setTheNotes(e.target.value)} error={isError} />
                  </div>

                  {/* check box 1 */}
                  <div className="mt-5">
                    <div className="flex gap-3">
                      <input type="checkbox" className="h-5 w-5" defaultChecked={willbeAtPickup} onChange={(e) => handlePickCheck(e)} />
                      <p>Will be at pick up location</p>
                    </div>
                    {
                      willbeAtPickup ?
                        <></>
                        :
                        <div className="mt-2">
                          <PhoneInput
                            className="rounded-[5px] w-full border-[1px] border-gray-500 h-[40px] px-2 bg-white"
                            defaultCountry={selectedCountry?.country === "Ghana" ? "GH" : "NG"}
                            inputStyle={{ width: "100%" }}
                            placeholder="Enter phone number"
                            value={pickContact}
                            onChange={(phone: any) => setPickContact(phone)}
                            disabled={isBusy}
                          />
                        </div>
                    }
                  </div>

                  {/* check box 2 */}
                  <div className="mt-5">
                    <div className="flex gap-3">
                      <input type="checkbox" className="h-5 w-5" defaultChecked={willbeAtDropOff} onChange={(e) => handleDropCheck(e)} />
                      <p>Will be at destination</p>
                    </div>
                    {
                      willbeAtDropOff ?
                        <></>
                        :
                        <div className="mt-2">
                          <PhoneInput
                            className="rounded-[5px] w-full border-[1px] border-gray-500 h-[40px] px-2 bg-white"
                            defaultCountry={selectedCountry?.country === "Ghana" ? "GH" : "NG"}
                            inputStyle={{ width: "100%" }}
                            placeholder="Enter phone number"
                            value={deliveryContact}
                            onChange={(phone: any) => setDeliveryContact(phone)}
                            disabled={isBusy}
                          />
                        </div>
                    }
                  </div>

                  {/* place order button */}
                  <div className="mt-5 flex gap-4 items-center">
                    <button onClick={handleClose} className="h-[40px] w-full bg-gradient-to-r from-red-300 to-red-500 text-white shadow-lg rounded-[30px]">Cancel</button>
                    <button onClick={() => handleFirstStep()} className="h-[40px] w-full bg-gradient-to-r from-lightGreen to-midGreen text-white shadow-lg rounded-[30px]">Place Order</button>
                  </div>
                </div>
              </div>

              : tripStep === 1 ?
                // step 1
                <div className="h-full overflow-y-auto pb-20">
                  <div className="mx-5">
                    <TripMapComponent locationData={directionsData} />

                    <div className="mt-8">
                      <p className="mb-4 font-semibold">Select Class:</p>
                      {
                        calculatedPrice?.amount_classes
                          .map((item: any, i: number) => (
                            <div key={i} className={`${activeSelection === i ? 'border-midGreen' : 'border-gray-300'} mb-4 flex justify-between items-center h-fit p-5 bg-white shadow-lg rounded-[20px] cursor-pointer border-[2px] hover:border-midGreen`} onClick={() => handleSelectedClass(item, i)}>
                              <div className="flex gap-5 items-center">
                                <img src={riderImg} alt="heyy" className="w-[80px]" />
                                <div>
                                  <p className="font-semibold text-lg">{item?.name}</p>
                                  <p className="font-semibold mt-2">{calculatedPrice?.countryData?.currencyCode} {item?.amount}</p>
                                  <p className="mt-2 italic text-sm">{item?.promoMessage}</p>
                                </div>
                              </div>

                            </div>
                          ))
                      }
                    </div>
                    <div className="mt-8 flex gap-4 items-center">
                      <button onClick={handleClose} className="h-[40px] w-full bg-gradient-to-r from-red-300 to-red-500 text-white shadow-lg rounded-[30px]">Cancel</button>

                      <button className="h-[40px] w-full bg-gradient-to-r from-lightGreen to-midGreen text-white shadow-lg rounded-[30px] disabled:bg-gradient-to-r disabled:from-gray-400 disabled:to-gray-500 disabled:cursor-not-allowed" onClick={() => handleOnContinue()} disabled={!selectedClass}>Continue</button>
                    </div>
                  </div>
                </div>
                :
                <div className="h-full overflow-y-auto pb-20">
                  {/* finding driver */}
                  {
                    findingRider ?
                      <>
                        <div className="flex flex-col justify-center items-center h-full">
                          <LoadingComponent />
                          <p className="mt-2">Finding a rider...</p>
                        </div>
                      </>
                      :
                      // if rider is found or not
                      <>
                        {
                          foundRiderStatus === true ?
                            <>
                              <div className="mx-5 flex justify-center flex-col">
                                <div className="flex justify-center flex-col">
                                  <p className="text-center bg-lightGreen text-white py-1 mb-3">{foundRiderMsg}</p>

                                  <Avatar className="flex mr-auto ml-auto" size="lg" src={foundRiderData?.driver_data?.image || "https://www.nicepng.com/png/detail/136-1366211_group-of-10-guys-login-user-icon-png.png"} />
                                  <p className="text-center">{foundRiderData?.driver_data?.fullName}</p>
                                  <p className="text-center">{foundRiderData?.driver_data?.phone}</p>
                                </div>

                                <hr className="my-4" />

                                <div>
                                  <div>
                                    <p className="font-semibold">Pick Up:</p>
                                    <p>{foundRiderData?.trip_details?.pickup?.place}</p>
                                  </div>
                                  <div className="mt-3">
                                    <p className="font-semibold">Destination:</p>
                                    <p>{foundRiderData?.trip_details?.destination?.place}</p>
                                  </div>
                                </div>

                                <hr className="my-4" />

                                <div>
                                  <Avatar className="flex mr-auto ml-auto" size="lg" src={foundRiderData?.car_data?.vehicleFront || "https://www.nicepng.com/png/detail/136-1366211_group-of-10-guys-login-user-icon-png.png"} />
                                  <p className="text-center">{foundRiderData?.car_data?.registeration}</p>
                                  <p className="text-center">{foundRiderData?.car_data?.brandData?.[0]?.brand}, {foundRiderData?.car_data?.vehicleColor}</p>
                                </div>

                                <div className="mt-8 flex gap-4 items-center">
                                  {foundRiderMsg === "Trip Started" ?
                                    <button onClick={handleClose} className="h-[40px] w-full bg-gradient-to-r from-lightGreen to-midGreen text-white shadow-lg rounded-[30px]">View Order</button>
                                    :
                                    <></>
                                  }
                                  <button onClick={() => peroformDeleteTrip(foundRiderData?.trip_details?._id)} className="h-[40px] w-full bg-gradient-to-r from-red-300 to-red-500 text-white shadow-lg rounded-[30px]">Cancel</button>
                                </div>

                                {/* dummy try again */}
                                {/* <div className="mt-8 flex gap-4 items-center">
                                  <button onClick={() => handleOnContinue()} className="h-[40px] w-full bg-gradient-to-r from-red-300 to-red-500 text-white shadow-lg rounded-[30px]">Try Again</button>
                                </div> */}
                              </div>
                            </>
                            :
                            <>
                              <div className="mx-5 flex flex-col items-center justify-center h-full mb-10">
                                <p className="text-center text-lg bg-red-400 text-white py-1 px-3 mb-4">{foundRiderMsg}</p>
                                <img src={noRiderimg} alt="noRider" className="" />

                                <div className="mt-8 w-full flex gap-3">
                                  <button onClick={() => peroformDeleteTrip(foundRiderData?.trip_details?._id)} className="h-[40px] w-full bg-gradient-to-r from-red-300 to-red-500 text-white shadow-lg rounded-[30px]">Cancel</button>
                                  <button onClick={() => tryAgainMethod()} className="h-[40px] w-full bg-gradient-to-r from-yellow-400 to-yellow-600 text-white shadow-lg rounded-[30px]">Try Again</button>
                                </div>
                              </div>
                            </>
                        }
                      </>
                  }
                </div>

        }

        {/* content */}
      </Drawer>
    </>
  );
};

export default CreateRequestDrawer;
