import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { ArchiveBoxIcon, Bars3CenterLeftIcon, ClockIcon, ListBulletIcon, MapIcon, Squares2X2Icon, UserGroupIcon } from "@heroicons/react/24/outline";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../images/muuvLogo.png"
import SideMenuContext from "../../context/GeneralContext";

interface sideProps {
  dashActive?: string
  walletActive?: string
  orderActive?: string
  riderActive?: string
  schActive?: string
  commActive?: string
  transActive?: string
  historyActive?: string
  opsActive?: string
}

const CustomSideBar = ({ dashActive, orderActive, riderActive, schActive, historyActive, opsActive }: sideProps) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isMobile, setIsMobile] = useState(false)
  const { triggerMenu } = useContext(SideMenuContext);


  //on load
  useEffect(() => {
    const handleDeviceType = () => {
      const width = window.innerWidth;
      if (width < 768) {
        //mobile
        setIsMobile(true);
      } else if (width >= 768 && width < 1024) {
        //tablet
        setIsMobile(true);
      } else {
        //desktop
        setIsMobile(false);
      }
    };

    handleDeviceType();

    window.addEventListener("resize", handleDeviceType);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleDeviceType);
    };
  }, []);


  const handleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };


  return (
    <>
      {
        triggerMenu ?
          <></>
          :
          <Sidebar collapsed={isCollapsed}>
            <Menu className="bg-white h-full">
              {/* logo */}
              <div className="py-4 flex items-center gap-5 w-full">
                <Bars3CenterLeftIcon
                  className="h-8 w-8 cursor-pointer ml-6"
                  onClick={() => handleCollapse()}
                />
                {!isCollapsed && (
                  <div className="">
                    <img className="w-[80px]" src={logo} alt="log" />
                    {/* <Typography className="font-semibold">MuuvExpress</Typography>
              <p className="text-[10px] text-gray-500 absolute top-4">
                Partner
              </p> */}
                  </div>
                )}
              </div>

              <div>
                <MenuItem
                  icon={<Squares2X2Icon className="w-6 h-6" />}
                  component={
                    <Link to="/dashboard" />
                  }
                  className={dashActive}
                >
                  <p className="text-sm">Dashboard</p>
                </MenuItem>

                <MenuItem
                  icon={<ArchiveBoxIcon className="w-6 h-6" />}
                  component={
                    <Link to="/orders" />
                  }
                  className={orderActive}
                >
                  <p className="text-sm">My Orders</p>
                </MenuItem>

                <MenuItem
                  icon={<ClockIcon className="w-6 h-6" />}
                  component={
                    <Link to="/schedules" />
                  }
                  className={schActive}
                >
                  <p className="text-sm">Schedules</p>
                </MenuItem>

                <MenuItem
                  icon={<UserGroupIcon className="w-6 h-6" />}
                  component={
                    <Link to="/riderMgt" />
                  }
                  className={riderActive}
                >
                  <p className="text-sm">Rider Mgt</p>
                </MenuItem>

                <MenuItem
                  icon={<ListBulletIcon className="w-6 h-6" />}
                  component={
                    <Link to="/orderHistory" />
                  }
                  className={historyActive}
                >
                  <p className="text-sm">Delivery History</p>
                </MenuItem>

                {/* <MenuItem
            icon={<BanknotesIcon className="w-6 h-6" />}
            component={
              <Link to="/transactions" />
            }
            className=""
          >
            <p className="text-sm">Transactions</p>
          </MenuItem> */}

                <MenuItem
                  icon={<MapIcon className="w-6 h-6" />}
                  component={
                    <Link to="/operations" />
                  }
                  className={opsActive}
                >
                  <p className="text-sm">Routes Mgt</p>
                </MenuItem>

                {/* <MenuItem
            icon={<WalletIcon className="w-6 h-6" />}
            component={
              <Link to="/wallet" />
            }
            className={walletActive}
          >
            <p className="text-sm">My Wallet</p>
          </MenuItem> */}
              </div>
            </Menu>
          </Sidebar>
      }
    </>
  );
};

export default CustomSideBar;
